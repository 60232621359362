import { Component, Input, OnInit } from '@angular/core';
import { BootstrappedAdmin } from '../../../models/admins.model';
import { TicketCategory } from '../../../models/ticket.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzMessageService, NzModalRef } from 'ng-zorro-antd';
import { TicketService } from '../../../shared/services/ticket.service';
import { tap } from 'rxjs/internal/operators/tap';
import { Router } from '@angular/router';

@Component({
  selector: 'app-create-new-ticket',
  templateUrl: './create-new-ticket.component.html',
  styleUrls: ['./create-new-ticket.component.scss']
})
export class CreateNewTicketComponent implements OnInit {
    @Input() public assignees: BootstrappedAdmin[];

    public categories: TicketCategory[];
    public ticketCreating: boolean;
    public validateForm: FormGroup;
    constructor(
        private ticketService: TicketService,
        private message: NzMessageService,
        private service: TicketService,
        private modal: NzModalRef,
        private router: Router,
        private _fb: FormBuilder
    ) {}

    ngOnInit() {
        // @todo create service selection
        this.ticketService.getTicketCategories({ service_id: 6 }).pipe(
            tap(categories => this.categories = categories)
        ).subscribe();

        this.validateForm = this._fb.group({
            type: ['merchant'],
            service_id: [6],
            communicate: ['right_away'],
            subject: [null, [Validators.required]],
            category_id: [null, Validators.required],
            merchant_email: [null, [Validators.required, Validators.email]],
            messages: [null, [Validators.required]],
        });
    }

    async createTicket() {
        for (const i in this.validateForm.controls) {
            if (this.validateForm.controls.hasOwnProperty(i)) {
                this.validateForm.controls[i].markAsDirty();
                this.validateForm.controls[i].updateValueAndValidity();
            }
        }

        if (this.validateForm.invalid) {
            return false;
        }

        this.ticketCreating = true;
        await this.service.createTicket({
            ...this.validateForm.value,
            delayed: this.isCommunicateLater ? 1 : 0
        }).pipe(
            tap(ticket => {
                let callback;
                if (this.isCommunicateLater) {
                    // Create ticket admin note
                    callback = this.ticketService.addTicketNote({
                        ticket_id: ticket.ticket_id,
                        message: this.validateForm.get('messages').value
                    });
                } else {
                    // Otherwise create ticket message immediately
                    callback = this.ticketService.sendMessage({
                        ticket_id: ticket.ticket_id,
                        sender_type: 'admin',
                        message: this.validateForm.get('messages').value
                    });
                }

                callback.subscribe(() => {
                    this.modal.close();
                    this.message.success('Ticket has been created successfully.');
                    if (this.isCommunicateLater) {
                        this.message.success('A new admin note has been added successfully.');
                    }
                    return this.router.navigate([`/ticket/${ticket.ticket_id}`]);
                }, () => {
                    this.ticketCreating = false;
                    this.message.error('Failed to create a new task. Please try again.');
                });
            })
        ).subscribe();
    }

    get isCommunicateLater() {
        return this.validateForm.get('communicate').value === 'later';
    }
}
