import { OfficesModel } from 'src/app/models/offices.model';

export class GetPeopleByIdResponse {

    serializePerson(data: any, dataType: any) {
        return data.find(item => {
            return item.id === dataType;
        });
    }

    serializeOffice(data: OfficesModel, dataType: any) {
        return data.offices.find(item => {
            return item.id === dataType;
        });
    }

}
