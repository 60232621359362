import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { PeopleService } from 'src/app/shared/services/people.service';
import { Page } from 'src/app/models/datatable/page';
import * as moment from 'moment';
import { PEOPLE_URL } from 'src/app/shared/const/constants.const';
import { MatSelectChange, MatAutocompleteTrigger } from '@angular/material';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { CompanyService } from 'src/app/shared/services/company.service';
import { switchMap, tap, takeUntil, debounceTime, mergeMap } from 'rxjs/operators';
import { CompanyTypeItem, SourceItem, ReferenceModel, OfficeItem, CandidateSubtypeItem } from 'src/app/models/reference.model';
import { ReplaySubject, Subject, Observable } from 'rxjs';
import { AdminItem, AdminsModel } from 'src/app/models/admins.model';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-people',
  templateUrl: './people.component.html',
  styleUrls: ['./people.component.scss']
})
export class PeopleComponent implements OnInit, OnDestroy {

  @ViewChild(MatAutocompleteTrigger, { static: false }) autocomplete: MatAutocompleteTrigger;
  @ViewChild('dataTable', { static: false }) table: any;

  active: boolean;

  messages = {
    totalMessage: 'total',
    emptyMessage: 'No data to display',
  };

  isLoading = false;
  isShowFilters = false;
  isLoadingSpinner = false;
  isValuesExist: boolean;
  isDisplayAdditionalFilters = false;
  isDisplayAdditionalCols = false;

  sortEvent: any;

  oldPageUrl = PEOPLE_URL;

  page = new Page();
  rows = [];

  perPages = [20, 30, 50];

  form: FormGroup;

  types: CompanyTypeItem[] = [];
  sources: SourceItem[] = [];
  offices: OfficeItem[] = [];
  candidateSubtypes: CandidateSubtypeItem[] = [];
  admins: AdminsModel;
  filteredAdmin: any[];

  findedCompanies: any[] = [];

  filtersCounter = 0;

  filteredSource: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  sourceFilterCtrl: FormControl = new FormControl();

  params: any;

  protected _onDestroy = new Subject<void>();

  constructor(
    private peopleService: PeopleService,
    private companyService: CompanyService,
    private _fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router

  ) {
    this.page.pageNumber = 0;
    this.page.size = 20;
  }

  ngOnInit() {

    this.sourceFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterSources();
      });

    this.form = this._fb.group({
      name: new FormControl(''),
      last_name: new FormControl(''),
      title: new FormControl(''),
      type: new FormControl(''),
      source: new FormControl(''),
      email: new FormControl(''),
      company_id: new FormControl(''),
      orphan_normal: new FormControl(''),
      created_by: new FormControl(''),
      office: new FormControl(''),
      subtype: new FormControl(''),
      date_from: new FormControl(''),
      date_to: new FormControl(''),
    });


    this.form.controls.company_id.valueChanges.pipe(
      debounceTime(1000),
      tap(value => {
        if (value && typeof value !== 'number' && value !== 'No company') {
          this.isLoadingSpinner = true;
          this.companyService.searchByCompany(value)
            .subscribe((res: any) => {
              if (res.length !== 0) {
                this.findedCompanies = [];
                res.companies.forEach(item => {
                  this.findedCompanies.push(item);
                  this.isLoadingSpinner = false;
                });
              } else {
                this.findedCompanies = [];
                this.isLoadingSpinner = false;
              }
            });
        } else if (value === '') {
          this.findedCompanies = [];
          this.autocomplete.closePanel();
        }
      })
    ).subscribe();

    this.getReferenceData();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  setPage(pageInfo?) {

    this.isValuesExist = Object.values(this.form.value).some(value => {
      return value;
    });

    if (this.isValuesExist) {
      this.onFilter(pageInfo, this.sortEvent);
    } else {
      this.onGetAllPeople(pageInfo, this.sortEvent);
    }

  }

  getReferenceData() {
    this.isLoading = true;
    this.companyService.getReference()
      .pipe(
        tap((res: ReferenceModel) => {
          this.types = res.company_types;
          this.sources = res.sources;
          this.offices = res.offices;
          this.candidateSubtypes = res.candidate_subtypes;

          this.filteredSource.next(this.sources);

          this.setPage({ offset: 0 });

        }),
        mergeMap(() => this.companyService.getAdmins()
          .pipe(
            tap((res: AdminsModel) => {
              this.admins = res;
              this.filteringAdmin();
            })
          )
        )).subscribe();
  }

  getRowPerPageValue(event: MatSelectChange) {
    this.page.size = event.value;
    this.setPage({ offset: this.page.pageNumber });
  }

  showFiltersWindow() {
    this.isShowFilters = !this.isShowFilters;
  }

  activeBurger(event) {
    this.active = event;
  }

  filteringAdmin(query?: string) {
    this.filteredAdmin = query
      ? this.admins.admins.filter(admin => admin.full_name.toLowerCase().includes(query.toLowerCase()))
      : this.admins.admins;
  }

  displayFnForAdmin = (value?: number) => {
    return value ? this.filteredAdmin.find(item => item.id === value).full_name : undefined;
  }

  displayFnCompanies = (value?: string | number) => {
    if (value && typeof value !== 'string') {
      return this.findedCompanies.find(item => item.company.id === value).company.name;
    } else {
      return value;
    }
  }

  displayOfficeAndStatus(event: MatSelectChange) {
    if (event.value === 6) {
      this.isDisplayAdditionalFilters = true;
    } else {
      this.isDisplayAdditionalFilters = false;
      this.form.controls.office.reset();
      this.form.controls.subtype.reset();
    }
  }

  onGetAllPeople(pageInfo?, event?: any) {

    this.page.pageNumber = pageInfo.offset;
    this.isLoading = true;

    // const defaultParams = {
    //   page: this.page.pageNumber + 1,
    //   per_page: this.page.size,
    //   order: !event ? 'desc' : event.newValue,
    //   field: !event ? 'dt_created' : event.column.prop,
    // };

    // this.router.navigate([], { queryParams: defaultParams });

    this.peopleService.getPeople(this.page, event)
      .subscribe(pagedData => {
        this._parseResponse(pagedData);
        this.isLoading = false;
        this.page.totalElements = pagedData.people.total_count;
      });

    // this.route.queryParamMap
    //   .pipe(
    //     switchMap((params: any) => {
    //       return this.peopleService.getPeople(this.page, event, params.params);
    //     })
    //   ).subscribe(pagedData => {
    //     this._parseResponse(pagedData);
    //     this.isLoading = false;
    //     this.page.totalElements = pagedData.people.total_count;
    //   });

    // .subscribe((params: any) => {
    //   this.isLoading = true;
    //   this.page.pageNumber = params.params.page - 1;
    //   this.peopleService.getPeople(this.page, event, params.params)
    //     .subscribe((pagedData) => {
    //       this._parseResponse(pagedData);
    //       this.isLoading = false;
    //       this.page.totalElements = pagedData.people.total_count;
    //     });
    // });

  }

  onFilter(pageInfo?, event?: any) {

    pageInfo ? this.page.pageNumber = pageInfo.offset : this.page.pageNumber = this.table.offset = 0;

    this.form.controls.type.value === 6 ? this.isDisplayAdditionalCols = true : this.isDisplayAdditionalCols = false;

    this.filtersCounter = 0;

    Object.values(this.form.controls).forEach(item => {
      if (item.value) { this.filtersCounter += 1; }
    });

    this.isLoading = true;
    this.isShowFilters = false;

    this.peopleService.filterByPeople(this.page, this.form.value, event)
      .subscribe(pagedData => {
        this._parseResponse(pagedData);
        this.isLoading = false;
        pagedData.people.list.length === 0 ? this.messages.totalMessage = '' : this.messages.totalMessage = 'total';
        this.page.totalElements = pagedData.people.total_count;
      });
  }

  onSort(event) {
    this.table.offset = this.page.pageNumber;
    this.sortEvent = event;
    this.onFilter(undefined, this.sortEvent);
  }

  clearFilter(event) {
    console.log(event.target.parentNode.textContent);
    switch (event.target.parentNode.textContent) {
      case 'Created byclear':
        this.form.controls.created_by.setValue('');
        break;
      case 'Sourcesclear':
        this.form.controls.source.setValue('');
        break;
      case 'Typeclear':
        this.form.controls.type.setValue('');
        this.isDisplayAdditionalFilters = false;
        break;
      case ' Office clear':
        this.form.controls.office.setValue('');
        break;
      case ' Status clear':
        this.form.controls.subtype.setValue('');
        break;
      case ' Date to clear':
        this.form.controls.date_to.setValue('');
        break;
      case ' Date from clear':
        this.form.controls.date_from.setValue('');
        break;
      default:
        this.form.reset();
        break;
    }
  }


  private _parseResponse(pagedData) {

    this._serializeData(pagedData.people.list);

    this.rows = pagedData.people.list.map(item => {
      return {
        id: item.id,
        company_name: item.company_name,
        company_id: item.company_id,
        company_icon: item.company_icon,
        name: item.name,
        lastName: item.last_name,
        title: item.title,
        email: item.email,
        icon: item.icon,
        type: item.typeName,
        office: item.officeName,
        subtype: item.subtypeName,
        dt_created: moment.unix(item.dt_created).format('L')
      };
    });
  }

  private _serializeData(pagedData: any[]) {

    this.types.forEach(type => {
      pagedData.forEach(row => {
        if (type.id === row.type) {
          row.typeName = type.title;
        }
      });
    });

    this.candidateSubtypes.forEach(subtype => {
      pagedData.forEach(row => {
        if (subtype.id === row.subtype) {
          row.subtypeName = subtype.name;
        }
      });
    });

    this.offices.forEach(office => {
      pagedData.forEach(row => {
        if (office.id === row.office_id) {
          row.officeName = office.name;
        }
      });
    });

  }

  private _setQueryParamsForAllPeople(page?, event?: any) {

  }

  protected filterSources() {
    if (!this.sources) {
      return;
    }
    let search = this.sourceFilterCtrl.value;
    if (!search) {
      this.filteredSource.next(this.sources.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredSource.next(
      this.sources.filter(source => source.name.toLowerCase().indexOf(search) > -1)
    );
  }

}
