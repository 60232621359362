import { NgxSpinnerService } from 'ngx-spinner';
import { CompanyService } from 'src/app/shared/services/company.service';
import { Store, select } from '@ngrx/store';
import { AssignedAdmins } from './../../../../models/company.model';
import { Component, OnInit, Inject } from '@angular/core';
import { AppState } from 'src/app/redux/app.state';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { FormGroup, FormControl } from '@angular/forms';
import { debounceTime, tap } from 'rxjs/operators';

@Component({
    selector: 'app-add-task',
    templateUrl: './add-task.component.html',
    styleUrls: ['./add-task.component.scss']
})
export class AddTaskComponent implements OnInit {

    taskTypes = [
        { value: 'call', name: 'Call', icon: 'call' },
        { value: 'meeting', name: 'Meeting', icon: 'meeting' },
        { value: 'task', name: 'Task', icon: 'task' },
    ];

    filteredAdmins: any[] = [];

    isLoading = false;

    config = {
        toolbar: ['bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote'],
    }

    editor = ClassicEditor;

    form: FormGroup;

    findedCompanies: any[] = [];

    constructor(
        private companyService: CompanyService,
        public dialogRef: MatDialogRef<AddTaskComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
    }

    ngOnInit() {
        this.form = new FormGroup({
            assign: new FormControl(''),
            company: new FormControl(''),
            task_type: new FormControl(''),
            date: new FormControl(''),
            description: new FormControl(''),
        });

        this.filterTeam();

        this.form.controls['company'].valueChanges.pipe(
            debounceTime(1000),
            tap(value => {
                if (typeof value !== 'number') {
                    this.isLoading = true;
                    this.companyService.searchByCompany(value)
                        .subscribe(res => {
                            this.findedCompanies = [];
                            res.companies.forEach((item: any) => {
                                this.findedCompanies.push(item.company);
                            });
                            this.isLoading = false;
                        });
                }
            })
        ).subscribe();
    }

    displayFn = (value?: number) => {
        return value ? this.filteredAdmins.find(item => item.id === value).full_name : undefined;
    }

    displayFnCompany = (value?: number) => {
        return value ? this.findedCompanies.find(item => item.id === value).name : undefined;
    }

    onSubmit(value) {
        console.log(value);
    }

    filterTeam(query?: string) {
        this.filteredAdmins = query ?
            this.data.admins.filter(team => team.full_name
                .toLowerCase()
                .includes(
                    query.toLowerCase())) :
            this.data.admins;
    }
}
