import { CompanyService } from 'src/app/shared/services/company.service';
import { DeletionModalComponent } from './deletion-modal/deletion-modal.component';
import {
    Component,
    ElementRef,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { COMMA, SPACE } from '@angular/cdk/keycodes';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { MatAutocomplete, MatChipInputEvent, MatDialog, MatSelect } from '@angular/material';
import { tap, switchMap, takeUntil } from 'rxjs/operators';
import { MerchantIds } from 'src/app/models/company.model';
import { AssignedTeamModel } from 'src/app/models/assigned-team.model';
import * as moment from 'moment';
import { GetReferenceResponse } from 'src/app/shared/responses/get-reference.response';
import { GetCompanyByIdResponse } from 'src/app/shared/responses/get-company-by-id.response';
import { AppState } from 'src/app/redux/app.state';
import { Store } from '@ngrx/store';
import { PermissionsService } from 'src/app/shared/services/permissions.service';
import { COMPANY_STAGE_REQUIRE_CONTRACTUAL_INFO } from "src/app/shared/const/constants.const";
import { NzNotificationService } from "ng-zorro-antd";
@Component({
    selector: 'app-info-widget',
    templateUrl: './info-widget.component.html',
    styleUrls: ['./info-widget.component.scss']
})
export class InfoWidgetComponent implements OnInit, OnChanges, OnDestroy {
    @Input() company: any;
    @Input() public isLoading: boolean;

    @Input() public type: any;
    @Input() public types: any[];

    @Input() public subtype: any;
    @Input() public subtypes: any[];

    @Input() public reputation: any;
    @Input() public reputations: any[];

    @Input() public stage: any;
    @Input() public stages: any[];

    @Input() public sources: any[];
    @Input() public source: any;

    @Input() public merchantIds: MerchantIds[];

    @Input() public paymentWallMIds: MerchantIds[];
    @Input() public fasterPayMIds: MerchantIds[];
    @Input() public terminal3MIds: MerchantIds[];

    @Input() public assignedTeam: AssignedTeamModel[];

    @Input() public oldPage: string;
    @Input() public id: any;

    @Input() public allAdmins: any;

    @ViewChild('paymentMidInput', { static: false }) paymentMidInput: ElementRef<HTMLInputElement>;
    @ViewChild('fasterPayMidInput', { static: false }) fasterPayMidInput: ElementRef<HTMLInputElement>;
    @ViewChild('terminal3MidInput', { static: false }) terminal3MidInput: ElementRef<HTMLInputElement>;

    @ViewChild('auto', { static: false }) matAutocomplete: MatAutocomplete;
    @ViewChild('sourceSelect', { static: false }) sourceSelect: MatSelect;
    @ViewChild('query', { static: false }) query: ElementRef;

    public removable = true;
    public addOnBlur = true;
    public separatorKeysCodes: number[] = [SPACE, COMMA];

    public paymentMidCtrl = new FormControl();
    public fasterPayMidCtrl = new FormControl();
    public terminal3MidCtrl = new FormControl();

    public filteredPaymentMids: Observable<string[]>;
    public filteredFasterPayMids: Observable<string[]>;
    public filteredT3Mids: Observable<string[]>;

    public filteredSource: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

    public sourceFilterCtrl: FormControl = new FormControl();

    public data: any = {};

    // public allMids: string[] = ['1', '123', '222', '2333', '6666'];

    public lastContactedAdmin: any;

    public formCompany: FormGroup;
    public fb = new FormBuilder();

    public isShowCompanyName = false;
    public isShowStage = false;
    public isShowReputation = false;
    public isShowSubtype = false;
    public isShowSource = false;
    public isShowType = false;
    public isShowEnglishName = false;
    public isShowLocalName = false;
    public isShowMids = false;
    public isShowEditIcon = false;

    public isShow: boolean;

    public lastContactCurrentDay = false;
    public lastContact30DayAgo = false;
    public lastContactSixMonthsAgo = false;

    protected _onDestroy = new Subject<void>();

    public sourceId: string;
    public isShowSpinner = false;

    public isDisableSaveButton = true;
    public filteredSubtypes: any[] = [];

    constructor(
        public dialog: MatDialog,
        public store: Store<AppState>,
        public ps: PermissionsService,
        private companyService: CompanyService,
        private notification: NzNotificationService
    ) {
        // this.filteredPaymentMids = this.filtered(this.paymentMidCtrl);
        // this.filteredFasterPayMids = this.filtered(this.fasterPayMidCtrl);
        // this.filteredT3Mids = this.filtered(this.terminal3MidCtrl);
    }

    ngOnInit() {
        this.formCompany = this.fb.group({
            company_name: new FormControl(''),
            type: new FormControl(''),
            subtype: new FormControl(''),
            stage: new FormControl(''),
            source: new FormControl(''),
            reputation: new FormControl(''),
            // 'nickname': new FormControl(''),
            english_name: new FormControl(''),
            local_name: new FormControl(''),
            paymentMids: this.fb.array([]),
            fasterPayMids: this.fb.array([]),
            terminal3Mids: this.fb.array([])
        });
    }

    ngOnChanges(changes: SimpleChanges): void {

        this.changeSubtype(this.type);

        this.filteredSource.next(this.sources);

        this.sourceFilterCtrl.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => {
                this.filterSources();
            });

        if (this.company) {

            const date = moment(new Date(this.company.last_contact_date));

            this.lastContact30DayAgo = false;
            this.lastContactSixMonthsAgo = false;
            this.lastContactCurrentDay = false;

            if (moment().diff(date, 'months') >= 1) {
                this.lastContact30DayAgo = true;
            } else if (moment().diff(date, 'months') >= 6) {
                this.lastContactSixMonthsAgo = true;
            } else if (moment().diff(date, 'months') < 1) {
                this.lastContactCurrentDay = true;
            }

            this.allAdmins.forEach(admin => {
                if (admin.id === this.company.last_contact_admin_id) {
                    this.lastContactedAdmin = admin.full_name;
                }
                if (admin.id === this.company.admin_id_created) {
                    this.company.admin_created_name = admin.full_name;
                }
            });

            if (this.formCompany) { this.setControls(); }

        }
    }

    setControls() {
        this.formCompany.patchValue({
            type: this.type ? this.type.id : null,
            subtype: this.subtype ? this.subtype.id : null,
            stage: this.stage ? this.stage.id : null,
            source: this.source ? this.source.id : null,
            reputation: this.reputation ? this.reputation.id : null,
            english_name: this.company.english_name,
            local_name: this.company.local_name,
            company_name: this.company.name,
        });

        this.formCompany.setControl('fasterPayMids', this.fb.array(this.fasterPayMIds ? this.fasterPayMIds : []));
        this.formCompany.setControl('terminal3Mids', this.fb.array(this.terminal3MIds ? this.terminal3MIds : []));
    }

    ngOnDestroy() {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    get paymentMids(): FormArray {
        return this.formCompany.get('paymentMids') as FormArray;
    }

    get fasterPayMids(): FormArray {
        return this.formCompany.get('fasterPayMids') as FormArray;
    }

    get terminal3Mids(): FormArray {
        return this.formCompany.get('terminal3Mids') as FormArray;
    }

    add(event: MatChipInputEvent, typeOfMid, midCtrl): void {
        const input = event.input;
        const value = event.value;

        if ((value || '').trim()) {
            typeOfMid.value.push({
                id: value.trim()
            });
        }

        if (input) {
            input.value = '';
        }
        midCtrl.setValue(null);
    }

    remove(mid: string, typeOfMid: any): void {
        this.dialog.open(DeletionModalComponent, {
            width: '447px',
            height: 'auto',
            autoFocus: false,
            data: { mid, typeOfMid },
            panelClass: 'mid-modal'
        }).afterClosed().subscribe((res: { remove: boolean }) => {
            res.remove ? this.isDisableSaveButton = false : this.isDisableSaveButton = true;
        });
    }

    // filtered(midCtrl) {
    //     return midCtrl.valueChanges.pipe(
    //         startWith('null'),
    //         map((mid: string | null) => (mid ? this._filter(mid) : this.allMids.slice()))
    //     );
    // }

    onSubmit(data) {
        // Validate company has Contractual Information when updating merchant to live
        if (data.type === 1 && COMPANY_STAGE_REQUIRE_CONTRACTUAL_INFO.includes(data.stage)
            && !this.validContractualInformation) {
            const state = this.stages.find(item => item.id == data.stage)
            this.notification.warning(`Missing Contractual Information`,
                'Please complete Contractual Information before updating company to ' + state.name)
            return
        }
        this._editCompany(data);
        this._hideForm();
    }

    onAddSource(name: string) {
        this.isShowSpinner = true;
        this.companyService.addSource(name).pipe(
            tap((res) => {
                this.sourceId = res.source_id;
            }),
            switchMap(() => this.companyService.getReference())
        ).subscribe(
            res => {
                this.sources = new GetReferenceResponse(res).getSources();
                this.sources.forEach(source => {
                    if (source.id === this.sourceId) {
                        this.source = source;
                    }
                });
                this.formCompany.patchValue({ source: this.source.id });
                this.isShowSpinner = false;
                this.sourceSelect.close();
            });
    }

    onCancel(event?) {
        if (event && event.target.innerText === 'Cancel') {
            this.isDisableSaveButton = true;
            this.setControls();
            this._hideForm();
        }
    }

    changeStateOfEdit() {
        this.isDisableSaveButton = false;
    }

    changeSubtype(type: any) {
        if (this.type) {
            this.filteredSubtypes = this.subtypes.filter(subtype => {
                return type.id === subtype.type_id;
            });
        }
    }

    protected filterSources() {
        if (!this.sources) {
            return;
        }
        let search = this.sourceFilterCtrl.value;
        if (!search) {
            this.filteredSource.next(this.sources.slice());
            return;
        } else {
            search = search.toLowerCase();
        }
        this.filteredSource.next(
            this.sources.filter(source => source.name.toLowerCase().indexOf(search) > -1)
        );
    }

    private _editCompany(data) {
        this.isLoading = true;
        this.companyService.editCompany(this.id, data)
            .pipe(
                switchMap(() => this.companyService.getCompanyById(this.id)),
                tap(
                    (res) => {

                        this.company.english_name = res.company.english_name;
                        this.company.local_name = res.company.local_name;
                        this.company.name = res.company.name;
                        this.company.reputation = res.company.reputation;
                        this.company.stage = res.company.stage;
                        this.company.source = res.company.source;
                        this.company.type = res.company.type;
                        this.company.subtype = res.company.subtype;

                        this.terminal3MIds = new GetCompanyByIdResponse(res).getMerchantIds('terminal3');
                        this.fasterPayMIds = new GetCompanyByIdResponse(res).getMerchantIds('fasterpay');

                        this.formCompany.setControl('fasterPayMids', this.fb.array(this.fasterPayMIds ? this.fasterPayMIds : []));
                        this.formCompany.setControl('terminal3Mids', this.fb.array(this.terminal3MIds ? this.terminal3MIds : []));

                        this.reputation = new GetReferenceResponse(this.reputations, this.company).getReputation();
                        this.stage = new GetReferenceResponse(this.stages, this.company).getStage();
                        this.source = new GetReferenceResponse(this.sources, this.company).getSource();
                        this.type = new GetReferenceResponse(this.types, this.company).getType();
                        this.subtype = new GetReferenceResponse(this.subtypes, this.company).getSubtype();

                    }),
            )
            .subscribe(
                () => {
                    this.isLoading = false;
                    this.isDisableSaveButton = true;
                }
            );
    }

    private _hideForm() {
        this.isShowCompanyName = false;
        this.isShowStage = false;
        this.isShowReputation = false;
        this.isShowSubtype = false;
        this.isShowType = false;
        this.isShowEnglishName = false;
        this.isShowLocalName = false;
        this.isShowMids = false;
        this.isShow = false;
        this.isShowEditIcon = false;
        this.isShowSource = false;
    }

    get validContractualInformation() {
        return this.company.contractual_info
            && ['percentage_of_rolling_reserve_amount',
                'days_for_releasing_the_rolling_reserve',
                'commercials_offered_to_the_merchant',
                'settlement_timeline',
                'agreement_type'].every(field => !!this.company.contractual_info[field])
    }
}
