import { omit, get, toNumber } from 'lodash';
import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BootstrappedAdmin} from '../../../../../../models/admins.model';
import {NzMessageService, NzModalRef} from 'ng-zorro-antd';
import {TicketService} from '../../../../../../shared/services/ticket.service';
import {Ticket} from '../../../../../../models/ticket.model';
@Component({
    selector: 'app-create-task-dialog',
    templateUrl: './create-task-dialog.component.html',
    styleUrls: ['./create-task-dialog.component.scss']
})
export class CreateTaskDialogComponent implements OnInit {
    @Input() public assignees: BootstrappedAdmin[];
    @Input() public ticketId: any;
    @Input() public ticket?: object | Ticket = {};

    public taskCreating: boolean;
    public validateForm: FormGroup;
    constructor(
        private message: NzMessageService,
        private service: TicketService,
        private modal: NzModalRef,
        private _fb: FormBuilder
    ) {
        //
    }

    get assigneeId() {
        return get(this.ticket, 'assignee_id', '');
    }

    get taskId() {
        return get(this.ticket, 'ticket_id');
    }

    ngOnInit() {
        this.validateForm = this._fb.group({
            assignee_id: [null, Validators.required],
            subject: [null, [Validators.required]],
            task_due_date: [null],
            type: ['task'],
            description: [''],
        });

        if (get(this.ticket, 'ticket_id')) {
            // On Editing Task
            this.validateForm.get('assignee_id').setValue(get(this.ticket, 'assignee_id', ''));
            this.validateForm.get('subject').setValue(get(this.ticket, 'subject'));
            this.validateForm.get('description').setValue(get(this.ticket, 'description'));
            const dueDate = toNumber(get(this.ticket, 'metadata.task_due_date', 0));
            if (dueDate) {
                this.validateForm.get('task_due_date').setValue(new Date(dueDate * 1000));
            }
        }
    }

    async createTask() {
        for (const i in this.validateForm.controls) {
            if (this.validateForm.controls.hasOwnProperty(i)) {
                this.validateForm.controls[i].markAsDirty();
                this.validateForm.controls[i].updateValueAndValidity();
            }
        }

        if (this.validateForm.invalid) {
            return false;
        }

        this.taskCreating = true;
        const payload = omit(this.validateForm.value, ['task_due_date']);
        const dueDate = new Date(this.validateForm.value.task_due_date).getTime();
        payload['metadata[task_due_date]'] = Math.floor(dueDate / 1000);

        if (get(this.ticket, 'ticket_id')) {
            return this.updateTicketTask(payload);
        }
        await this.service.createTicket({
            ...payload,
            ticket_id: this.ticketId
        }).subscribe(response => {
                this.message.success('Task has been created successfully.');
                this.modal.close(response);
            },
            () => {
                this.taskCreating = false;
                this.message.error('Failed to create a new task. Please try again.');
            },
            () => {
                this.taskCreating = false;
                this.modal.close();
            }
        );
    }

    updateTicketTask(payload: object) {
        this.service.updateTicket({
            ticket_id: get(this.ticket, 'ticket_id'),
            data: payload
        }).subscribe(response => {
                this.message.success('Task has been updated successfully.');
                this.modal.close(response);
            },
            () => {
                this.taskCreating = false;
                this.message.error('Failed to update task. Please try again.');
            });
    }
}
