import { BaseApi } from './../../helpers/base-api/base-api';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Methods } from 'src/app/helpers/methods/methods';
import { PeopleModel } from 'src/app/models/people.model';
import { Page } from 'src/app/models/datatable/page';
import * as moment from 'moment';
import { Router, ActivatedRoute } from '@angular/router';
import { OfficesModel } from 'src/app/models/offices.model';
import { ValueConverter } from '@angular/compiler/src/render3/view/template';
import { PersonModel } from 'src/app/models/person.model';

@Injectable({
    providedIn: 'root'
})
export class PeopleService extends BaseApi {

    dataForEditMultiplyPhone: any[] = [];
    dataForEditMultiplyEmail: any[] = [];
    dataForEditMultiplyFax: any[] = [];
    dataForEditMultiplySkype: any[] = [];
    dataForEditMultiplyWechat: any[] = [];
    dataForEditMultiplyWebsite: any[] = [];

    parseName: any[] = [];

    increment: number;

    constructor(
        public http: HttpClient,
        private router: Router,
        private route: ActivatedRoute,
    ) {
        super(http);
    }

    getPeople(page: Page, event): Observable<PeopleModel> {
        this.increment = page.pageNumber + 1;
        return this.get(`/people?page=${this.increment}&per_page=${page.size}&sort=${Methods.encodeURL({
            field: !event ? 'dt_created' : event.column.prop,
            order: !event ? 'desc' : event.newValue,
        })}`);
    }

    filterByPeople(page: Page, value, event?): Observable<PeopleModel> {
        this.increment = page.pageNumber + 1;
        return this.get(`/people?search=${Methods.encodeURL({
            name: value.name ? value.name : null,
            lastname: value.last_name ? value.last_name : null,
            email: value.email ? value.email : null,
            title: value.title ? value.title : null,
            company_id: value.company_id === 'No company' ? '' : value.company_id === '' ? null : value.company_id,
            source: value.source ? value.source : null,
            type: value.type ? value.type : null,
            office_id: value.office ? value.office : null,
            subtype: value.subtype ? value.subtype : null,
            admin_id_created: value.created_by ? value.created_by : null,
            date_from: value.date_from ? moment(value.date_from).format('L') : null,
            date_to: value.date_to ? moment(value.date_to).format('L') : null,
        })}&page=${this.increment}&per_page=${page.size}&sort=${Methods.encodeURL({
            field: !event ? 'dt_created' : event.column.prop,
            order: !event ? 'desc' : event.newValue,
        })}`);
    }

    searchPeopleByName(value): Observable<PeopleModel> {
        return this.get(`/people?search=${Methods.encodeURL({
            name: value
        })}`);
    }

    getPeopleById(id: number): Observable<any> {
        return this.get(`/people/${id}`);
    }

    getNotesByPerson(id, limit?, type?: any, admin?: any): Observable<any> {
        return this.get(`/notes?&search=${Methods.encodeURL({
            person_id: id,
            type: type ? type : '',
            admin_id: admin && admin === 'clear' ? null : admin,
        })}&page=1&per_page=${limit}&with=attachments`);
    }

    getContactsByPerson(id): Observable<any> {
        return this.get(
            `/contacts/?search=${Methods.encodeURL({
                ref_id: id,
                ref_type: 'person'
            })}`
        );
    }

    getOffices(): Observable<OfficesModel> {
        return this.get('/offices?with=jobs');
    }

    deleteContacts(id): Observable<any> {
        return this.delete(`/contacts/${id}`);
    }

    editAssignedAdmins(id: any, data: any[]): Observable<PersonModel> {

        let assignedAdmins = [];

        assignedAdmins = data.map(item => {
            return {
                admin_id: item.admin_id,
                role_id: item.role_id,
            };
        });

        return this.put(`/people/${id}`, {
            data: {
                assigned_admins: assignedAdmins
            }
        });
    }

    editContactsByPerson(id: number, value): Observable<any> {

        this.dataForEditMultiplyPhone = [];
        this.dataForEditMultiplyEmail = [];
        this.dataForEditMultiplyWebsite = [];
        this.dataForEditMultiplyFax = [];
        this.dataForEditMultiplySkype = [];
        this.dataForEditMultiplyWechat = [];

        this.getPointsOfContacts(value, 'phone_items', this.dataForEditMultiplyPhone, id, 'phone');
        this.getPointsOfContacts(value, 'email_items', this.dataForEditMultiplyEmail, id, 'email');
        this.getPointsOfContacts(value, 'website_items', this.dataForEditMultiplyWebsite, id, 'website');
        this.getPointsOfContacts(value, 'fax_items', this.dataForEditMultiplyFax, id, 'fax');
        this.getPointsOfContacts(value, 'skype_items', this.dataForEditMultiplySkype, id, 'skype');
        this.getPointsOfContacts(value, 'wechat_items', this.dataForEditMultiplyWechat, id, 'wechat');

        return this.put(`/contacts/${id}`,
            {
                data: {
                    phone: this.dataForEditMultiplyPhone,
                    email: this.dataForEditMultiplyEmail,
                    wechat: this.dataForEditMultiplyWechat,
                    skype: this.dataForEditMultiplySkype,
                    facebook: [
                        {
                            id: value.facebook_items[0].id,
                            value: value.facebook_items[0].point,
                            red_id: id,
                            ref_type: 'person',
                            type: 'facebook',
                        }
                    ],
                    linkedin: [
                        {
                            id: value.linkedin_items[0].id,
                            value: value.linkedin_items[0].point,
                            red_id: id,
                            ref_type: 'person',
                            type: 'linkedin',
                        }
                    ],
                    website: this.dataForEditMultiplyWebsite,
                    fax: this.dataForEditMultiplyFax,
                    address: [
                        {
                            id: value.address_items[0].id,
                            value: value.address_items[0].point,
                            red_id: id,
                            ref_type: 'person',
                            type: 'address',
                        }
                    ],
                    country: [
                        {
                            id: value.country_items[0].id,
                            value: value.country_items[0].point,
                            red_id: id,
                            ref_type: 'person',
                            type: 'country',
                        }
                    ],
                    city: [
                        {
                            id: value.city_items[0].id,
                            value: value.city_items[0].point,
                            red_id: id,
                            ref_type: 'person',
                            type: 'city',
                        }
                    ],
                },
                ref_id: id,
                ref_type: 'person',
            });
    }

    editPerson(id, data: any, icon?: any, companyId?: any, assigned?: any): Observable<any> {

        if (data.name) {
            this.parseName = [];
            this.parseName = data.name.split(' ');
        }

        return this.put(`/people/${id}`,
            {
                data: {
                    name: this.parseName[0],
                    last_name: this.parseName[1],
                    title: data.job_title,
                    company_id: data.company,
                    summary: data.summary,
                    type: data.type,
                    subtype: data.type !== 6 ? null : data.subtype,
                    source: data.source,
                    referral_admin_id: data.referral,
                    office_id: data.office,
                    desire_compensation: data.compensation,
                    desire_compensation_currency_id: data.compensation_currency,
                    datetime_interviewed: data.datetime,
                    relocation_term_options: {
                        insurance: {
                            on: data.insurance_term ? 1 : 0,
                            text: data.insurance_term
                        },
                        accommodation: {
                            on: data.accommodation_term ? 1 : 0,
                            text: data.accommodation_term
                        },
                        allowance: {
                            on: data.allowance_term ? 1 : 0,
                            text: data.allowance_term
                        },
                        fly: {
                            on: data.fly_term ? 1 : 0,
                            text: data.fly_term
                        }
                    },
                    desire_positions: data.positions,
                    icon
                }

            });
    }


    addNoteByPerson(id, value): Observable<any> {
        return this.post('/notes', {
            data: {
                ref_type: 'person',
                ref_id: id,
                type: value.note_type,
                subject: value.note_subject,
                body: value.note_body,
                candidate_hire: value.hire,
                candidate_score: value.score,
            },
            extra_data: {
                files: value.files
            }
        });
    }

    private getPointsOfContacts(value, items, array, id, type) {
        return value[items].forEach(item => {
            return array.push({
                id: item.id,
                ref_id: id,
                ref_type: 'person',
                type,
                value: item.point
            });
        });
    }
}
