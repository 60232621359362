import {
    ReferenceModel,
    CompanyTypeItem,
    CompanySubtypeItem,
    RoleItem, StageItem
} from "src/app/models/reference.model";
import { AppState } from "src/app/redux/app.state";
import { Store } from "@ngrx/store";
import { ErrorService } from "src/app/shared/services/error.service";
import { OnDestroy } from "@angular/core";
import { Validators, AbstractControl } from "@angular/forms";
import {
    switchMap,
    tap,
    mergeMap,
    debounceTime,
    takeUntil
} from "rxjs/operators";
import { CompanyService } from "src/app/shared/services/company.service";
import { GetReferenceResponse } from "src/app/shared/responses/get-reference.response";
import {
    Component,
    OnInit,
    ViewChild,
    ElementRef
} from "@angular/core";
import { FormGroup, FormBuilder, FormArray, FormControl } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { of, ReplaySubject, Subject } from "rxjs";
import { SourceItem, CountryItem } from "src/app/models/reference.model";
import { MatSelect } from "@angular/material";
import { ThemeService } from "src/app/theme/theme.service";
import { AclModel } from "src/app/models/acl.model";
import { PermissionsService } from "src/app/shared/services/permissions.service";
import {COMPANY_STAGE_REQUIRE_CONTRACTUAL_INFO} from "src/app/shared/const/constants.const";

@Component({
    selector: "app-create-company",
    templateUrl: "./create-company.component.html",
    styleUrls: ["./create-company.component.scss"]
})
export class CreateCompanyComponent implements OnInit, OnDestroy {
    @ViewChild("sourceSelect", { static: false }) sourceSelect: MatSelect;
    @ViewChild("querySource", { static: false }) querySource: ElementRef;

    types: CompanyTypeItem[] = [];
    stages: StageItem[] = [];
    subtypes: CompanySubtypeItem[] = [];
    roles: RoleItem[] = [];
    admins: any[] = [];
    sources: SourceItem[] = [];
    countries: CountryItem[] = [];

    imgUrlCompany: any;
    imgUrlPerson: any;

    companyIcon: any;
    personIcon: any;

    isShowSpinner = false;
    isShowSpinnerForCompanySearch = false;

    sourceId: string;
    source: any;

    filteredTeam: any[];
    // public filteredSource: any[] = [];

    isShowCompanyContacts = false;
    isShowPersonContacts = false;

    loggedAdminId: number;

    isLoading = false;

    isExistCompany: any;

    filteredSubtypes: any[] = [];

    createdCompanyId: any;

    commercials_offered_to_the_merchant: any[];

    agreement_types: any[];

    config = {
        toolbar: [
            "bold",
            "italic",
            "link",
            "bulletedList",
            "numberedList",
            "blockQuote"
        ]
    };

    editor = ClassicEditor;

    form: FormGroup;

    filteredSource: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

    sourceFilterCtrl: FormControl = new FormControl();

    active: boolean;

    protected _onDestroy = new Subject<void>();

    constructor(
        private companyService: CompanyService,
        private _fb: FormBuilder,
        private _router: Router,
        private route: ActivatedRoute,
        private errorService: ErrorService,
        private store: Store<AppState>,
        private themeService: ThemeService,
        private ps: PermissionsService
    ) {
        this.themeService.getThemeFromLocalStorage();
    }

    ngOnInit() {
        const validateFieldByMerchantType = (control: AbstractControl) => {
            if (!control.value && this.form && this.form.value.type === 1 && COMPANY_STAGE_REQUIRE_CONTRACTUAL_INFO.includes(this.form.controls.stage.value)) {
                return { invalidMerchantField: true }
            }
            return null
        }
        const validateFieldByPaymentType = (control: AbstractControl) => {
            if (!control.value && this.form && this.form.value.type === 4) {
                return { invalidPaymentField: true }
            }
            return null
        }
        this.sourceFilterCtrl.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => {
                this.filterSources();
            });

        this.form = this._fb.group({
            /*Company*/
            name: new FormControl("", [Validators.required]),
            type: new FormControl("", [Validators.required]),
            stage: new FormControl(""),
            industry: new FormControl(""),
            website: new FormControl(""),
            source: new FormControl(""),
            needs: new FormControl(""),
            goals: new FormControl(""),
            email: new FormControl(""),
            skype: new FormControl(""),
            wechat: new FormControl(""),
            facebook: new FormControl(""),
            linkedin: new FormControl(""),
            country: new FormControl(""),
            city: new FormControl(""),
            address: new FormControl(""),
            zip_code: new FormControl(""),
            fax: new FormControl(""),
            summary: new FormControl(""),
            bounty: new FormControl(""),
            nameRows: this._fb.array([this.initNameRows("")]),
            roleRows: this._fb.array([this.initRoleRows("")]),
            phoneCompanyRows: this._fb.array([this.initPhoneCompanyRows("")]),
            percentage_of_rolling_reserve_amount: new FormControl("", [validateFieldByMerchantType, Validators.pattern(`\\d+(\\.\\d{1,})?`)]),
            days_for_releasing_the_rolling_reserve: new FormControl("", [validateFieldByMerchantType, Validators.pattern(`\\d+(\\.\\d{1,})?`)]),
            commercials_offered_to_the_merchant: new FormControl("", [validateFieldByMerchantType]),
            settlement_timeline: new FormControl(null, [validateFieldByMerchantType, Validators.pattern(`\\d+(\\.\\d{1,})?`)]),
            agreement_type: new FormControl(null, [validateFieldByMerchantType]),
            notes: new FormControl(""),
            security_deposit_amount: new FormControl("", [validateFieldByPaymentType]),
            days_on_hold: new FormControl("", [validateFieldByPaymentType]),
            limitation_of_liability_amount: new FormControl(""),
            /*Person*/
            name_person: new FormControl(""),
            last_name_person: new FormControl(""),
            title_person: new FormControl(""),
            email_person: new FormControl(""),
            website_person: new FormControl(""),
            skype_person: new FormControl(""),
            wechat_person: new FormControl(""),
            facebook_person: new FormControl(""),
            linkedin_person: new FormControl(""),
            summary_person: new FormControl(""),
            country_person: new FormControl(""),
            city_person: new FormControl(""),
            address_person: new FormControl(""),
            zip_code_person: new FormControl(""),
            fax_person: new FormControl(""),
            phonePersonRows: this._fb.array([this.initPhonePersonRows("")]),

            /*Note*/
            note_body: new FormControl("")
        });

        this.form.controls.name.setValue(
            this.route.snapshot.paramMap.get("query")
        );

        this.companyService
            .getReference()
            .pipe(
                tap((res: ReferenceModel) => {
                    this.loggedAdminId = res.logged_in_admin.id;
                    this.types = res.company_types;
                    this.subtypes = res.company_subtypes;
                    this.stages = res.stages
                    this.roles = res.admin_roles;
                    this.countries = res.countries;
                    this.sources = res.sources;
                    this.filteredSource.next(this.sources);
                    this.commercials_offered_to_the_merchant =
                        res.company_contractual_reference.commercials_offered_to_the_merchant;
                    this.agreement_types =
                        res.company_contractual_reference.agreement_type;
                }),
                mergeMap(() =>
                    this.ps.getCompanyPermissions(this.loggedAdminId, 0).pipe(
                        tap((res: AclModel) => {
                            this.ps.checkCreateCompanyPagePermissions(res);
                        })
                    )
                ),
                mergeMap(() => this.companyService.getAdmins())
            )
            .subscribe(res => {
                this.admins = res.admins;
                this.filterTeam();
            });

        this.form.controls.name.valueChanges
            .pipe(
                debounceTime(1000),
                tap(value => {
                    if (value) {
                        this.isShowSpinnerForCompanySearch = true;
                        this.companyService
                            .searchByCompany(value)
                            .subscribe(res => {
                                if (res.companies) {
                                    this.isExistCompany = res.companies.find(
                                        (item: any) => {
                                            return item.company.name === value;
                                        }
                                    );
                                    this.isShowSpinnerForCompanySearch = false;
                                } else {
                                    this.isExistCompany = false;
                                    this.isShowSpinnerForCompanySearch = false;
                                }
                            });
                    } else {
                        this.isExistCompany = false;
                    }
                })
            )
            .subscribe();
        this.form.controls.stage.valueChanges.pipe(tap(() => {
            this.form.controls['percentage_of_rolling_reserve_amount'].updateValueAndValidity()
            this.form.controls['days_for_releasing_the_rolling_reserve'].updateValueAndValidity()
            this.form.controls['commercials_offered_to_the_merchant'].updateValueAndValidity()
            this.form.controls['settlement_timeline'].updateValueAndValidity()
            this.form.controls['agreement_type'].updateValueAndValidity()
        })).subscribe()
    }

    ngOnDestroy() {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    filterTeam(query?: string) {
        this.filteredTeam = query
            ? this.admins.filter(team =>
                team.full_name.toLowerCase().includes(query.toLowerCase())
            )
            : this.admins;
    }

    // filterSource(query?: string, event?) {
    //     event && event.keyCode === 32 ? event.stopPropagation() : null;
    //     this.filteredSource = query && query != ''
    //         ? this.sources.filter(source => source.name.toLowerCase().includes(query.toLowerCase()))
    //         : this.sources;
    // }

    initNameRows(admin: any) {
        return this._fb.group({
            admin_id: [admin]
        });
    }

    initRoleRows(role: any) {
        return this._fb.group({
            role_id: [role]
        });
    }

    initPhoneCompanyRows(phone: any) {
        return this._fb.group({
            phone: [phone]
        });
    }

    initPhonePersonRows(phone: any) {
        return this._fb.group({
            phone: [phone]
        });
    }

    get formNameArr() {
        return this.form.get("nameRows") as FormArray;
    }

    get formRoleArr() {
        return this.form.get("roleRows") as FormArray;
    }

    get formPhoneArr() {
        return this.form.get("phoneCompanyRows") as FormArray;
    }

    get formPhonePersonArr() {
        return this.form.get("phonePersonRows") as FormArray;
    }

    addNewRow() {
        this.formNameArr.push(this.initNameRows(""));
        this.formRoleArr.push(this.initRoleRows(""));
    }

    addNewPhoneRow() {
        this.formPhoneArr.push(this.initPhoneCompanyRows(""));
    }

    addNewPhonePersonRow() {
        this.formPhonePersonArr.push(this.initPhonePersonRows(""));
    }

    showCompanyContacts() {
        this.isShowCompanyContacts = !this.isShowCompanyContacts;
    }

    showPersonContacts() {
        this.isShowPersonContacts = !this.isShowPersonContacts;
    }

    changeSubtype(typeId: number) {
        this.form.controls['stage'].setValue(null)
        if (this.form.value.type === 1) {
            this.form.controls['security_deposit_amount'].updateValueAndValidity()
            this.form.controls['days_on_hold'].updateValueAndValidity()
            if (this.stages.length) {
                this.form.controls['stage'].setValue(this.stages[0].id)
            }
        }
        if (this.form.value.type === 4) {
            this.form.controls['percentage_of_rolling_reserve_amount'].updateValueAndValidity()
            this.form.controls['days_for_releasing_the_rolling_reserve'].updateValueAndValidity()
            this.form.controls['commercials_offered_to_the_merchant'].updateValueAndValidity()
            this.form.controls['settlement_timeline'].updateValueAndValidity()
            this.form.controls['agreement_type'].updateValueAndValidity()
        }
        this.filteredSubtypes = this.subtypes.filter(subtype => {
            return typeId === subtype.type_id;
        });
    }

    onSubmit(value) {
        if (this.form.invalid) {
            window.scrollTo(0, 0);
        } else {
            this.isLoading = true;
            this.companyService
                .addCompany(value, this.companyIcon)
                .pipe(
                    tap(res => {
                        this.createdCompanyId = res.company.id;
                    }),
                    mergeMap(() =>
                        value.name_person && value.last_name_person !== ""
                            ? this.companyService.addPeople(
                                value,
                                this.createdCompanyId,
                                this.personIcon
                            )
                            : of(null)
                    ),
                    mergeMap(() =>
                        value.note_body !== ""
                            ? this.companyService.addNote(
                                this.createdCompanyId,
                                value
                            )
                            : of(null)
                    ),
                    mergeMap(() => {
                        if (this.form.value.type === 1 || this.form.value.type === 4) {
                            const {
                                type,
                                percentage_of_rolling_reserve_amount,
                                days_for_releasing_the_rolling_reserve,
                                commercials_offered_to_the_merchant,
                                settlement_timeline,
                                agreement_type,
                                notes,
                                security_deposit_amount,
                                days_on_hold,
                                limitation_of_liability_amount
                            } = this.form.getRawValue()
                            let contractualInfoPayload
                            if (type == 1) {
                                contractualInfoPayload = {
                                    percentage_of_rolling_reserve_amount,
                                    days_for_releasing_the_rolling_reserve,
                                    commercials_offered_to_the_merchant,
                                    settlement_timeline,
                                    agreement_type,
                                    notes,
                                }
                            }
                            if (type == 4) {
                                contractualInfoPayload = {
                                    security_deposit_amount,
                                    days_on_hold,
                                    limitation_of_liability_amount
                                }
                            }
                            return this.companyService.addContractualInfo({ company_id: this.createdCompanyId, data: contractualInfoPayload })
                        }
                        return of(null)
                    })
                )
                .subscribe(
                    () => {
                        this.isLoading = false;
                        this._router.navigate([
                            `/company/${this.createdCompanyId}`
                        ]);
                    },
                    err => console.error(err)
                );
        }
    }

    displayFn = (value?: number) => {
        return value
            ? this.filteredTeam.find(item => item.id === value).full_name
            : undefined;
    };

    // displayFnSource = (value?: number) => {
    //     return value ? this.filteredSource.find(item => item.id === value).name : undefined;
    // }

    uploadCompanyIcon(event: any) {
        this.onFileChange(event, "company");
    }

    uploadPersonIcon(event: any) {
        this.onFileChange(event, "employee");
    }

    onClear() {
        this.isExistCompany = false;
    }

    onAddSource(name: string) {
        this.isShowSpinner = true;
        this.companyService
            .addSource(name)
            .pipe(
                tap(res => {
                    this.sourceId = res.source_id;
                }),
                switchMap(() => this.companyService.getReference())
            )
            .subscribe(res => {
                this.sources = new GetReferenceResponse(res).getSources();
                this.form.patchValue({ source: parseInt(this.sourceId, 10) });
                this.isShowSpinner = false;
                this.sourceSelect.close();
            });
    }

    activeBurger(event) {
        this.active = event;
    }

    protected filterSources() {
        if (!this.sources) {
            return;
        }
        let search = this.sourceFilterCtrl.value;
        if (!search) {
            this.filteredSource.next(this.sources.slice());
            return;
        } else {
            search = search.toLowerCase();
        }
        this.filteredSource.next(
            this.sources.filter(
                source => source.name.toLowerCase().indexOf(search) > -1
            )
        );
    }

    private onFileChange(event: any, type: string) {
        const reader = new FileReader();

        if (event.target.files && event.target.files.length) {
            const fileName = event.target.files[0].name;

            const [file] = event.target.files;

            reader.readAsDataURL(file);

            reader.onload = () => {
                if (typeof reader.result === "string") {
                    const result = reader.result.split(",")[1];

                    if (type === "company") {
                        this.imgUrlCompany = "";
                        this.imgUrlCompany = reader.result;

                        this.companyIcon = {
                            name: fileName,
                            content: result
                        };
                    } else if (type === "employee") {
                        this.imgUrlPerson = "";
                        this.imgUrlPerson = reader.result;

                        this.personIcon = {
                            name: fileName,
                            content: result
                        };
                    }
                }
            };
        }
    }

    onSelect(value: any, field: string) {
        this.form.controls[field].setValue(value);
    }
}
