import { Router } from '@angular/router';
import { Methods } from 'src/app/helpers/methods/methods';
import { AclModel } from 'src/app/models/acl.model';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { BaseApi } from 'src/app/helpers/base-api/base-api';
import { HttpClient } from '@angular/common/http';

const ACCESS = {
    ALLOWED: 1,
    NOT_ALLOWED: 0
};

@Injectable({
    providedIn: 'root'
})
export class PermissionsService extends BaseApi {

    canEditGeneralSection: boolean;
    canEditGoalsAndNeedsSection: boolean;
    canEditBounty: boolean;
    canEditAssignedTeam: boolean;

    canEditPersonInformation: boolean;

    accessPersonState = false;
    accessCompanyState = false;

    response: AclModel = {
        access_list: {
            limited_access: 1,
            extended_access: 0,
            edit_bounty_allowed: 0,
            assign_allowed: 0,
            performance_allowed: 0,
        }
    };

    private limitedAccessCompanySections = [
        'company_information',
        'activity',
        'assigned_team',
        'employee',
        'contacts',
    ];

    constructor(
        private permissionsService: NgxPermissionsService,
        private router: Router,
        public http: HttpClient
    ) {
        super(http);
    }

    getCompanyPermissions(id: number, companyId: any): Observable<AclModel> {
        return this.get(`/admins/${id}?action=acl&parameters=${Methods.encodeURL({
            company_id: companyId
        })}`);
    }

    getPersonPermissions(id: number, personId: any): Observable<AclModel> {
        return this.get(`/admins/${id}?action=acl&parameters=${Methods.encodeURL({
            person_id: personId
        })}`);
    }

    checkCreateCompanyPagePermissions(res: AclModel) {
        if (res.access_list.edit_bounty_allowed === ACCESS.ALLOWED) {
            this.permissionsService.addPermission('bounty');
        }
    }

    checkCompanyPagePermissions(res: AclModel) {
        if (res.access_list.limited_access === ACCESS.ALLOWED) {
            this.permissionsService.loadPermissions(this.limitedAccessCompanySections);
            this.canEditGeneralSection = false;
            this.canEditAssignedTeam = false;
            this.canEditBounty = false;
            this.accessCompanyState = false;
        }

        if (res.access_list.extended_access === ACCESS.ALLOWED) {

            this.permissionsService.loadPermissions(['all']);

            this.accessCompanyState = true;
            this.canEditGeneralSection = true;
            this.canEditGoalsAndNeedsSection = true;
            this.canEditBounty = false;

            (res.access_list.edit_bounty_allowed === ACCESS.ALLOWED)
                ? this.canEditBounty = true :
                this.canEditBounty = false;

            if (res.access_list.performance_allowed === ACCESS.ALLOWED) {
                this.permissionsService.addPermission(['performance']);
            }

            if (res.access_list.assign_allowed === ACCESS.ALLOWED) {
                this.canEditAssignedTeam = true;
            } else {
                this.canEditAssignedTeam = false;
            }
        }
    }

    checkPersonPagePermissions(res: AclModel, companyId: number) {
        if (res.access_list.limited_access === ACCESS.ALLOWED &&
            res.access_list.extended_access === ACCESS.ALLOWED
        ) {
            this.permissionsService.loadPermissions(['all']);
            this.canEditPersonInformation = true;
            this.accessPersonState = true;
        } else if (
            res.access_list.limited_access === ACCESS.ALLOWED &&
            res.access_list.extended_access === ACCESS.NOT_ALLOWED
        ) {
            this.permissionsService.loadPermissions(['person_information']);

            this.canEditPersonInformation = false;
            this.accessPersonState = false;

        } else if (
            res.access_list.limited_access === ACCESS.NOT_ALLOWED &&
            res.access_list.extended_access === ACCESS.NOT_ALLOWED
        ) {
            this.router.navigate(['/company', companyId]);
        }

        if (
            res.access_list.extra_accesses.relocation === ACCESS.ALLOWED
        ) {
            this.permissionsService.addPermission(['relocation_terms']);
        }
    }
}
