import { ChartModel, DataPoint } from '../../models/chart.model';

export class GetChartsDataResponse {
    readonly res: ChartModel[];

    constructor(res?: ChartModel[]) {
        this.res = res;
    }

    getTotalVolume() {
        return this.getTotal(this.res, 0);
    }

    getTotalRevenue() {
        return this.getTotal(this.res, 1);
    }

    getTotalUsers() {
        return this.getTotal(this.res, 2);
    }

    getVolumePoints() {
        return this.serializeDataPoints(this.res, 0);
    }

    getRevenuePoints() {
        return this.serializeDataPoints(this.res, 1);
    }

    getUsersPoints() {
        return this.serializeDataPoints(this.res, 2);
    }

    getLabels() {
        return this.serializeLabels(this.res, 0);
    }

    private serializeDataPoints(res: ChartModel[], index: number) {
        return res[index].data.data_points.map((item: DataPoint) => {
            return item.value;
        });
    }

    private serializeLabels(res: ChartModel[], index: number) {
        return res[index].data.data_points.map((item: DataPoint) => {
            return item.label;
        });
    }

    private getTotal(res: ChartModel[], index: number) {
        return res[index].data.total;
    }
}
