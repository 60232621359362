import { ReferenceModel } from '../../models/reference.model';

export class GetReferenceResponse {
    readonly res: any;
    readonly company: any;

    constructor(res?: ReferenceModel[], company?) {
        this.res = res;
        this.company = company;
    }

    getSubtypes() {
        return this.res.company_subtypes;
    }

    getSubtype() {
        return this.res.find(item => {
            return this.company.type === item.type_id && this.company.subtype === item.id;
        });
    }

    getTypes() {
        return this.res.company_types.map(item => {
            return {
                id: item.id,
                title: item.title
            };
        });
    }

    getType() {
        return this.res.find(item => {
            return this.company.type === item.id;
        });
    }

    getRoles() {
        this.res.admin_roles.push({ id: 0, name: 'No Role' });
        return this.res.admin_roles;
    }

    getReputations() {
        return this.res.reputations;
    }

    getReputation() {
        return this.res.find(item => {
            return this.company.reputation === item.id;
        });
    }

    getStages() {
        return this.res.stages;
    }

    getStage() {
        return this.res.find(item => {
            return this.company.stage === item.id;
        });
    }

    getCountries() {
        return this.res.countries;
    }

    getSources() {
        return this.res.sources;
    }

    getSource() {
        return this.res.find(item => {
            return this.company.source === item.id;
        });
    }

    getNoteTypes() {
        return this.res.note_types;
    }

    getAgreementFields(field) {
        return this.res.agreement_fields[field].options;
    }
}
