import { BASE_URL } from 'src/app/shared/const/constants.const';
import { FullAccessRequestModalComponent } from './../../shared/dialogs/full-access-request-modal/full-access-request-modal.component';
import { ThemeService } from './../../theme/theme.service';
import { ErrorService } from './../../shared/services/error.service';
import { ReferenceModel, SourceItem, CountryItem } from './../../models/reference.model';
import { ProjectsModel, ApplicationItem } from '../../models/projects.model';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { List } from '../../models/people.model';
import { tap } from 'rxjs/internal/operators/tap';
import { AssignedAdmins, CompanyModel, MerchantIds } from '../../models/company.model';
import { switchMap } from 'rxjs/internal/operators/switchMap';
import { CompanyService } from '../../shared/services/company.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ContactsModel } from '../../models/contacts.model';
import { AdminsModel } from '../../models/admins.model';
import { Subscription, forkJoin, of, Observable, zip } from 'rxjs';
import { mergeMap } from 'rxjs/internal/operators/mergeMap';
import { AssignedTeamModel } from '../../models/assigned-team.model';
import { GetNotesResponse } from '../../shared/responses/get-notes.response';
import { GetCompanyByIdResponse } from '../../shared/responses/get-company-by-id.response';
import { GetReferenceResponse } from '../../shared/responses/get-reference.response';
import { GetPeopleResponse } from '../../shared/responses/get-people.response';
import { GetContactsResponse } from '../../shared/responses/get-contacts.response';
import { GetChartsDataResponse } from '../../shared/responses/get-charts-data.response';
import { OLD_PAGE } from '../../shared/const/constants.const';
import { MatDialog, MatTabGroup } from '@angular/material';
import { GetProjectsResponse } from '../../shared/responses/get-projects.response';
import { Location } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { catchError } from 'rxjs/operators';
import { AclModel } from 'src/app/models/acl.model';
import { AppState } from 'src/app/redux/app.state';
import { Store, select } from '@ngrx/store';
import { PermissionsService } from 'src/app/shared/services/permissions.service';
import { AttachmentItem, AttachmentsModel } from 'src/app/models/attachments.model';
import * as moment from 'moment';
import { GetAttachmentsRepsponse } from 'src/app/shared/responses/get-attachments.response';

@Component({
    selector: 'app-company-page-layout',
    templateUrl: './company-page-layout.component.html',
    styleUrls: ['./company-page-layout.component.scss']
})
export class CompanyPageLayoutComponent implements OnInit, OnDestroy {

    company: any;
    contacts: any;

    adminIds: any[] = [];
    allAdmins: AdminsModel[] = [];
    assignedTeam: AssignedTeamModel[] = [];
    rootAvatarAdmin: string;

    revenue: any[] = [];
    users: any[] = [];
    volume: any[] = [];

    totalRevenue: number;
    totalVolume: number;
    totalUsers: number;

    labels: any[] = [];

    notes: any[] = [];

    people: List[] = [];

    subtype: any;
    subtypes: any[] = [];

    types: any[] = [];
    type: any;

    countries: CountryItem[] = [];

    roles: ReferenceModel[] = [];

    reputations: ReferenceModel[] = [];
    reputation: any[] = [];

    stages: ReferenceModel[] = [];
    stage: any[] = [];

    projectsList: ApplicationItem[];
    projectsDropDown: any;

    sources: ReferenceModel[];
    source: any[] = [];

    attachments: AttachmentsModel;

    tasks: any[] = [];

    noteTypes: any[];

    paymentWallMIds: any[] = [];
    fasterPayMIds: any[] = [];
    terminal3MIds: any[] = [];

    formDataCompany: any;
    formDataContacts: any;

    id: any;
    counterLimit = 5;

    oldPageUrl = OLD_PAGE;

    sub1: Subscription;
    sub2: Subscription;

    active: boolean;

    loggedUser: number;

    documentTypes: any;
    documentStatuses: any;
    documentSignatureStatuses: any;
    entities: any;
    company_contractual_reference: any

    constructor(
        public dialog: MatDialog,
        public errorService: ErrorService,
        public location: Location,
        private companyService: CompanyService,
        private route: ActivatedRoute,
        private titleService: Title,
        private themeService: ThemeService,
        private ps: PermissionsService,
        private store: Store<AppState>,
    ) {
        this.themeService.getThemeFromLocalStorage();
    }

    ngOnInit() {

        this.sub1 = this.route.params.subscribe((params: Params) => {

            this.id = params.id;

            this._showLoaders();

            this.adminIds.length = 0;
            this.assignedTeam.length = 0;
            this.sub2 =
                this.companyService.getReference()
                    .pipe(
                        tap((res: any) => {
                            this.types = new GetReferenceResponse(res).getTypes();
                            this.subtypes = new GetReferenceResponse(res).getSubtypes();
                            this.roles = new GetReferenceResponse(res).getRoles();
                            this.reputations = new GetReferenceResponse(res).getReputations();
                            this.stages = new GetReferenceResponse(res).getStages();
                            this.sources = new GetReferenceResponse(res).getSources();
                            this.noteTypes = new GetReferenceResponse(res).getNoteTypes();
                            this.countries = new GetReferenceResponse(res).getCountries();
                            this.documentTypes = new GetReferenceResponse(res).getAgreementFields('doc_type_id');
                            this.documentStatuses = new GetReferenceResponse(res).getAgreementFields('doc_status');
                            this.documentSignatureStatuses = new GetReferenceResponse(res).getAgreementFields('doc_signature_status');
                            this.entities = res.business_entities;
                            this.loggedUser = res.logged_in_admin.id;
                            this.company_contractual_reference = res.company_contractual_reference

                        }),
                        switchMap(()=>forkJoin([
                            this.ps.getCompanyPermissions(this.loggedUser, this.id)
                                .pipe(
                                    tap((res: AclModel) => {
                                        this.ps.checkCompanyPagePermissions(res);
                                    }),
                                    catchError((err) => of(err))
                                ),
                            this.companyService.getCompanyById(this.id)
                                .pipe(
                                    tap((data: CompanyModel) => {

                                        data.assigned_admins.forEach((item: AssignedAdmins) => {
                                            this.adminIds.push({
                                                adminId: item.admin_id,
                                                roleId: item.role_id
                                            });
                                        });

                                        this.company = new GetCompanyByIdResponse(data).getCompanyData();

                                        this.location.go(`/company/${params.id}/${this.company.name
                                            .replace(/ /g, '-')
                                            .replace(/[()/]/g, '')}`);

                                        this.titleService.setTitle(`${this.company.name} - Armstrong`);

                                        this.paymentWallMIds = new GetCompanyByIdResponse(data).getMerchantIds('paymentwall');
                                        this.fasterPayMIds = new GetCompanyByIdResponse(data).getMerchantIds('fasterpay');
                                        this.terminal3MIds = new GetCompanyByIdResponse(data).getMerchantIds('terminal3');

                                        this.type = new GetReferenceResponse(this.types, this.company).getType();
                                        this.subtype = new GetReferenceResponse(this.subtypes, this.company).getSubtype();
                                        this.reputation = new GetReferenceResponse(this.reputations, this.company).getReputation();
                                        this.stage = new GetReferenceResponse(this.stages, this.company).getStage();
                                        this.source = new GetReferenceResponse(this.sources, this.company).getSource();

                                        this.errorService.isLoadingGoals = false;
                                        this.errorService.isLoadingCompany = false;


                                    }),
                                    catchError((err) => of(err))
                                ),
                            this.companyService.getAdmins()
                                .pipe(
                                    tap((res: any) => {

                                        this.allAdmins = res.admins;
                                        this.rootAvatarAdmin = res.extra_data.root_avatar_url;

                                        this._getAssignTeam(this.allAdmins, this.adminIds);

                                        this.errorService.isLoadingTeam = false;
                                    }),
                                    catchError((err) => of(err))
                                ),
                            this.companyService.getProjects(this.id)
                                .pipe(
                                    tap((res) => {
                                        this.projectsList = new GetProjectsResponse(res, this.paymentWallMIds).getProjectsList();
                                        this.projectsDropDown = new GetProjectsResponse(res).getProjectsListForDropDown();

                                        this.errorService.isLoadingProjects = false;
                                    }),
                                    catchError((err) => of(err))
                                ),
                            this.companyService.getNotes(this.id, this.counterLimit)
                                .pipe(
                                    tap((res) => {
                                        this.notes = new GetNotesResponse(res, this.allAdmins, this.noteTypes).getNotes();
                                        this.errorService.isLoadingNotes = false;
                                    }),
                                    catchError((err) => of(err))
                                ),
                            this.companyService.getContactsByCompany(this.id)
                                .pipe(
                                    tap((res) => {
                                        this.contacts = new GetContactsResponse(res).getContacts();
                                        this.errorService.isLoadingContacts = false;
                                    }),
                                    catchError((err) => of(err))
                                ),
                            this.companyService.getPeopleByCompany(this.id)
                                .pipe(
                                    tap((res) => {
                                        this.people = new GetPeopleResponse(res).getPeople();
                                    }),
                                    catchError((err) => of(err))
                                ),
                            this.ps.accessCompanyState ? this.companyService.getQueue(this.id)
                                .pipe(
                                    tap((res) => {
                                        this.tasks = res.tickets;
                                        this.errorService.isLoadingTasks = false;
                                    }),
                                    catchError((err) => of(err))
                                ) : of(null, this.errorService.isLoadingTasks = false),
                            this.companyService.getAttachments(this.id)
                                .pipe(
                                    tap((res: AttachmentsModel) => {
                                        this.attachments = res;
                                        new GetAttachmentsRepsponse(this.attachments).serializeAttachments();
                                        this.errorService.isLoadingAttachments = false;
                                    }),
                                    catchError((err) => of(err))
                                )
                        ])),
                        catchError((err) => of(err))
                    ).subscribe()
        });
    }

    activeBurger(event) {
        this.active = event;
    }

    onOpenAccessModal() {

        const COMPANY_URL = `${BASE_URL}/#/company/${this.id}/${this.company.name
            .replace(/ /g, '-')
            .replace(/[()/]/g, '')}`;

        this.dialog.open(FullAccessRequestModalComponent, {
            data: {
                url: COMPANY_URL,
            },
            width: '496px',
            panelClass: 'full-access-dialog',
            autoFocus: false
        });
    }

    private _getAssignTeam(allAdmins: any[], adminsIds: any[]) {
        allAdmins.forEach(admin => {
            adminsIds.forEach(id => {
                if (id.adminId === admin.id) {
                    this.roles.forEach((role: any) => {
                        if (id.roleId === role.id) {
                            this.assignedTeam.push({
                                full_name: admin.full_name,
                                role: role.name,
                                company_id: this.id,
                                admin_id: admin.id,
                                role_id: role.id,
                                avatar: admin.avatar,
                                root_avatar: this.rootAvatarAdmin
                            });
                        }
                    });
                }
            });
        });
    }

    private _showLoaders() {
        this.errorService.isLoadingCompany = true;
        this.errorService.isLoadingTeam = true;
        this.errorService.isLoadingContacts = true;
        this.errorService.isLoadingNotes = true;
        this.errorService.isLoadingGoals = true;
        this.errorService.isLoadingProjects = true;
        this.errorService.isLoadingCharts = true;
        this.errorService.isLoadingTasks = true;
        this.errorService.isLoadingAttachments = true;
    }

    ngOnDestroy(): void {
        this.sub1.unsubscribe();
        this.sub2.unsubscribe();
    }
}
