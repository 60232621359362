import { ActivatedRoute, Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { BASE_ADMIN_LOGIN_URL, BASE_URL, CRM_OAUTH_URL, CRM_API_URL } from 'src/app/shared/const/constants.const';
import { NzMessageService } from 'ng-zorro-antd';
import { isString } from 'lodash';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private readonly message: NzMessageService
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            const url = this.activatedRoute.snapshot['_routerState'].url;
            const error = err.error.message || err.statusText;

            if (err.status === 401) {
                localStorage.removeItem('access_token');
                const query = new URLSearchParams({
                    domain: 'armstrong',
                    referer: `${BASE_URL}/#${url}`,
                    refererRedirect: CRM_OAUTH_URL
                }).toString();
                window.location.href = `${BASE_ADMIN_LOGIN_URL}/?${query}`;

            } else if (this.activatedRoute.snapshot['_routerState'].url.indexOf('/ticket') === -1) {
                const rawError = isString(err.error) ? err.error : '';
                if (err.status === 404) {
                    this.router.navigate(['/all-companies']);
                } else if (rawError.includes('No access')) {
                    this.router.navigate(['/latest-correspondence']);
                }
            } else {
                this.message.warning(error);
            }

            return throwError(error);
        }));
    }
}
