import { Component, OnInit, ViewChild } from '@angular/core';
import { CompanyService } from 'src/app/shared/services/company.service';
import { Page } from 'src/app/models/datatable/page';
import { NotesModel } from 'src/app/models/notes.model';
import { GetNotesResponse } from 'src/app/shared/responses/get-notes.response';
import { AdminsModel } from 'src/app/models/admins.model';
import { ReferenceModel } from 'src/app/models/reference.model';
import { tap, mergeMap, switchMap, debounceTime } from 'rxjs/operators';
import { ACTIVITY_URL } from 'src/app/shared/const/constants.const';
import { MatSelectChange, MatDialog, MatAutocompleteTrigger } from '@angular/material';
import { SingleNoteComponent } from './single-note/single-note.component';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { PeopleService } from 'src/app/shared/services/people.service';
import { PeopleModel } from 'src/app/models/people.model';
import * as moment from 'moment';

@Component({
  selector: 'app-latest-correspondence',
  templateUrl: './latest-correspondence.component.html',
  styleUrls: ['./latest-correspondence.component.scss']
})
export class LatestCorrespondenceComponent implements OnInit {

  @ViewChild('dataTable', { static: false }) table: any;
  @ViewChild(MatAutocompleteTrigger, { static: false }) autocomplete: MatAutocompleteTrigger;

  active: boolean;

  messages = {
    totalMessage: 'total',
    emptyMessage: '',
  };

  oldPageUrl = ACTIVITY_URL;

  page = new Page();
  rows = [];

  findedCompanies: any[] = [];
  findedPeople: any[] = [];
  filteredAdmin: any[] = [];

  perPages = [10, 15, 25];

  admins: AdminsModel;
  noteTypes: any[];

  isLoading = false;

  filtersCounter: 0;

  form: FormGroup;

  sortEvent: any;

  isShowFilters = false;
  isLoadingSpinner = false;
  isLoadingPersonSpinner = false;
  isValuesExist = false;

  constructor(
    private companyService: CompanyService,
    private peopleService: PeopleService,
    private dialog: MatDialog,
    private _fb: FormBuilder,
  ) {
    this.page.pageNumber = 0;
    this.page.size = 15;
  }

  ngOnInit() {

    this.form = this._fb.group({
      company: new FormControl(''),
      person: new FormControl(''),
      type: new FormControl(''),
      admin: new FormControl(''),
      date_from: new FormControl(moment().format()),
      date_to: new FormControl(moment().format()),
    });

    this.companyService.getReference()
      .pipe(
        tap((res: ReferenceModel) => {
          this.noteTypes = res.note_types;
          this.setPage({ offset: 0 });
        }),
        switchMap(() => this.companyService.getAdmins()
          .pipe(
            tap((res: AdminsModel) => {
              this.admins = res;
              this.filteringAdmin();
            })
          )
        ),
      ).subscribe();

    this._searchCompany();
    this._searchPeople();

  }

  setPage(pageInfo?) {

    this.isValuesExist = Object.values(this.form.value).some(value => {
      return value;
    });

    if (this.isValuesExist) {
      this.onFilter(pageInfo, this.sortEvent);
    } else {
      this.onGetAllNotes(pageInfo);
    }
  }

  activeBurger(event) {
    this.active = event;
  }

  showFiltersWindow() {
    this.isShowFilters = !this.isShowFilters;
  }

  getRowPerPageValue(event: MatSelectChange) {
    this.page.size = event.value;
    this.setPage({ offset: this.page.pageNumber });
  }

  onSort(event) {
    this.table.offset = this.page.pageNumber;
    this.sortEvent = event;
  }

  onGetAllNotes(pageInfo) {

    this.page.pageNumber = pageInfo.offset;
    this.isLoading = true;

    this.companyService.getAllNotes(this.page)
      .subscribe((res: NotesModel) => {
        this.rows = new GetNotesResponse(res, null, this.noteTypes).getNotes();
        this.isLoading = false;
        this.page.totalElements = res.notes.total_count;
      });

  }

  onFilter(pageInfo?, event?: any) {

    pageInfo ? this.page.pageNumber = pageInfo.offset : this.page.pageNumber = this.table.offset = 0;

    this.filtersCounter = 0;

    Object.values(this.form.controls).forEach(item => {
      if (item.value) { this.filtersCounter += 1; }
    });

    this.isLoading = true;
    this.isShowFilters = false;

    this.companyService.getAllNotes(this.page, this.form.value, event)
      .subscribe(res => {
        this.rows = new GetNotesResponse(res, null, this.noteTypes).getNotes();
        this.isLoading = false;
        this.page.totalElements = res.notes.total_count;
        res.notes.list.length === 0 ? this.messages.totalMessage = '' : this.messages.totalMessage = 'total';
        res.notes.list.length === 0 ? this.messages.emptyMessage = 'No data to display' : this.messages.emptyMessage = '';
      });
  }

  filteringAdmin(query?: string) {
    this.filteredAdmin = query
      ? this.admins.admins.filter(admin => admin.full_name.toLowerCase().includes(query.toLowerCase()))
      : this.admins.admins;
  }

  displayFnCompanies = (value?: string | number) => {
    if (value && typeof value !== 'string') {
      this.form.controls.person.reset();
      return this.findedCompanies.find(item => item.company.id === value).company.name;
    } else {
      return value;
    }
  }

  displayFnPeople = (value?: string | number) => {
    if (value && typeof value !== 'string') {
      this.form.controls.company.reset();
      return this.findedPeople.find(item => item.id === value).name;
    } else {
      return value;
    }
  }

  displayFnForAdmin = (value?: number) => {
    return value ? this.filteredAdmin.find(item => item.id === value).full_name : undefined;
  }

  clearFilter(event) {
    console.log(event.target.parentNode.textContent);
    switch (event.target.parentNode.textContent) {
      case ' Type clear':
        this.form.controls.type.setValue('');
        break;
      case 'Admin nameclear':
        this.form.controls.admin.setValue('');
        break;
      case ' Date to clear':
        this.form.controls.date_to.setValue('');
        break;
      case ' Date from clear':
        this.form.controls.date_from.setValue('');
        break;
      default:
        this.form.reset();
        break;
    }
  }

  openNote(id: number) {

    const singleNote = this.rows.find(item => {
      return item.id === id;
    });

    this.dialog.open(SingleNoteComponent, {
      data: singleNote,
      width: '600px',
      panelClass: 'single-note-dialog'
    });

  }

  private _searchCompany() {
    this.form.controls.company.valueChanges.pipe(
      debounceTime(1000),
      tap(value => {
        if (value && typeof value !== 'number') {
          this.isLoadingSpinner = true;
          this.companyService.searchByCompany(value)
            .subscribe((res: any) => {
              if (res.length !== 0) {
                this.findedCompanies = [];
                res.companies.forEach(item => {
                  this.findedCompanies.push(item);
                  this.isLoadingSpinner = false;
                });
              } else {
                this.findedCompanies = [];
                this.isLoadingSpinner = false;
              }
            });
        } else if (value === '') {
          this.findedCompanies = [];
          this.autocomplete.closePanel();
        }
      })
    ).subscribe();
  }

  private _searchPeople() {
    this.form.controls.person.valueChanges.pipe(
      debounceTime(1000),
      tap(value => {
        if (value && typeof value !== 'number') {
          this.isLoadingPersonSpinner = true;
          this.peopleService.searchPeopleByName(value)
            .subscribe((res: PeopleModel) => {
              if (res.people.list.length !== 0) {
                this.findedPeople = [];

                res.people.list.forEach(item => {
                  this.findedPeople.push(item);
                  this.isLoadingPersonSpinner = false;
                });
              } else {
                this.findedPeople = [];
                this.isLoadingPersonSpinner = false;
              }
            });
        } else if (value === '') {
          this.findedPeople = [];
          this.autocomplete.closePanel();
        }
      })
    ).subscribe();
  }
}
