import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';

@Component({
    selector: 'app-related-companies-widget',
    templateUrl: './related-companies-widget.component.html',
    styleUrls: ['./related-companies-widget.component.scss']
})
export class RelatedCompaniesWidgetComponent implements OnInit {
    public relatedCompanies: any[] = [{ id: 1, title: 'Bandai Namco Europe' }, { id: 2, title: 'Bandai Namco Japan' }];

    public isShowDrop: boolean = false;
    public filteredCompanies: Observable<string[]>;

    public relatedControl = new FormControl();

    constructor() {}

    ngOnInit() {
        this.filteredCompanies = this.relatedControl.valueChanges.pipe(
            startWith(''),
            map(value => this._filter(value))
        );
    }

    private _filter(value: string) {
        const filterValue = value.toLowerCase();
        return this.relatedCompanies.filter(rel => rel.title.toLowerCase().includes(filterValue));
    }

    showDrop() {
        this.isShowDrop = !this.isShowDrop;
    }
}
