import { Component, OnInit, Inject, EventEmitter, Output, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDatepickerInputEvent } from '@angular/material';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { FileValidator } from 'src/app/shared/validators/file.validator';

@Component({
  selector: 'app-add-attachment',
  templateUrl: './add-attachment.component.html',
  styleUrls: ['./add-attachment.component.scss']
})
export class AddAttachmentComponent implements OnInit, OnDestroy {

  @Output() dateChange: EventEmitter<MatDatepickerInputEvent<any>>;

  fileName: any;
  imgUrl: any;
  file: any;

  form: FormGroup;
  formData: FormData = new FormData();

  isDefaultBlock = true;

  constructor(
    public dialogRef: MatDialogRef<AddAttachmentComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
  }

  ngOnInit() {

    this.initDefaultForm();
    this.setValuesFromLocalStorage();

    if (this.data.document) {
      this.changeDefaultBlockView(this.data.document.document_type);
    }

  }

  ngOnDestroy() {
    localStorage.setItem('attachments', JSON.stringify({ value: this.form.value }));
  }

  setDiapason(event: MatDatepickerInputEvent<any>) {
    if (event.value) {
      const currentDate = new Date(this.form.get('effective_date').value);
      currentDate.setFullYear(currentDate.getFullYear() + 1);
      this.form.get('expiration_date').setValue(currentDate);
    }
  }

  onSubmit() {
    this.dialogRef.close({ value: this.form.value });
  }

  onClose() {
    this.dialogRef.close();
  }

  onFileChange(event) {
    if (event.target.files && event.target.files[0]) {
      this.fileName = event.target.files[0].name;
      const reader = new FileReader();
      reader.onload = () => {
        this.form.get('file_name').setValue(event.target.files[0], { emitModelToViewChange: false });
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  changeDefaultBlockView(title: string) {
    if (title === '2' || title === '1') {
      this.isDefaultBlock = false;
    } else if (title === '3') {
      this.isDefaultBlock = true;
    } else {
      this.isDefaultBlock = true;
    }
  }

  validateFn(): boolean {
    if (
      this.isDefaultBlock &&
      this.form.get('document_type').valid &&
      this.form.get('file_name').valid
    ) {
      return false;
    } else if (
      !this.isDefaultBlock &&
      this.form.get('document_type').valid &&
      this.form.get('document_status').valid &&
      this.form.get('signature_status').valid &&
      this.form.get('responsible').valid &&
      this.form.get('file_name').valid &&
      this.form.get('entity').valid
    ) {
      return false;
    } else {
      return true;
    }
  }

  private setValuesFromLocalStorage() {

    const LOCALSTORAGE_DATA = JSON.parse(localStorage.getItem('attachments'));

    if (LOCALSTORAGE_DATA) {

      this.changeDefaultBlockView(LOCALSTORAGE_DATA.value.document_type);

      this.form.patchValue({
        document_type: LOCALSTORAGE_DATA.value.document_type,
        document_status: LOCALSTORAGE_DATA.value.document_status,
        signature_status: LOCALSTORAGE_DATA.value.signature_status,
        responsible: LOCALSTORAGE_DATA.value.responsible,
        effective_date: LOCALSTORAGE_DATA.value.effective_date,
        expiration_date: LOCALSTORAGE_DATA.value.expiration_date,
        entity: LOCALSTORAGE_DATA.value.entity,
        first_name: LOCALSTORAGE_DATA.value.first_name,
        last_name: LOCALSTORAGE_DATA.value.last_name,
        signer_title: LOCALSTORAGE_DATA.value.signer_title,
        company: LOCALSTORAGE_DATA.value.company,
        country: LOCALSTORAGE_DATA.value.country,
        state: LOCALSTORAGE_DATA.value.state,
        city: LOCALSTORAGE_DATA.value.city,
        street: LOCALSTORAGE_DATA.value.street,
        zip_code: LOCALSTORAGE_DATA.value.zip_code,
        note: LOCALSTORAGE_DATA.value.note,
      });
    }
  }

  private initDefaultForm() {
    this.form = this.fb.group({
      document_type: new FormControl('', [Validators.required]),
      document_status: new FormControl('1', [Validators.required]),
      signature_status: new FormControl('', [Validators.required]),
      responsible: new FormControl('', [Validators.required]),
      file_name: new FormControl('', [FileValidator.validate]),
      effective_date: new FormControl('', [Validators.required]),
      expiration_date: new FormControl('', [Validators.required]),
      entity: new FormControl('', [Validators.required]),
      first_name: new FormControl(''),
      last_name: new FormControl(''),
      signer_title: new FormControl(''),
      company: new FormControl(''),
      country: new FormControl(1),
      state: new FormControl(''),
      city: new FormControl(''),
      street: new FormControl(''),
      zip_code: new FormControl(''),
      note: new FormControl(''),
    });
  }
}
