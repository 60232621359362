import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
    selector: 'app-lost-modal',
    templateUrl: './lost-modal.component.html',
    styleUrls: ['./lost-modal.component.scss']
})
export class LostModalComponent implements OnInit {

    value: string;

    constructor(
        public dialogRef: MatDialogRef<LostModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) { }

    ngOnInit() {
    }

    onCancelClick() {
        this.dialogRef.close({ isCancel: true });
    }

    onSubmit(value) {

        const note_subject = `
        Changed ${this.data.companyName} stage from
        ${this.data.previousStage} to ${this.data.currentStage}`

        this.dialogRef.close({
            note_body: value,
            note_subject,
            isCancel: false
        });
    }
}
