import { COMPANIES_URL } from 'src/app/shared/const/constants.const';
import { ThemeService } from './../../theme/theme.service';
import { ErrorService } from './../../shared/services/error.service';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { CompanyService } from 'src/app/shared/services/company.service';
import { Component, ViewChild, AfterViewInit, OnInit, ElementRef, DoCheck, OnChanges, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/redux/app.state';
import { DatatableComponent, id } from '@swimlane/ngx-datatable';
import { tap, switchMap, takeUntil } from 'rxjs/operators';
import { SuccessLoadReference } from 'src/app/redux/actions/reference.action';
import { SuccessLoadAdmins } from 'src/app/redux/actions/admins.action';
import { ReplaySubject, Subject } from 'rxjs';
import { MatDialog, MatSelectChange } from '@angular/material';

@Component({
    selector: 'app-all-companies-page-layout',
    templateUrl: './all-companies-page-layout.component.html',
    styleUrls: ['./all-companies-page-layout.component.scss'],
})
export class AllCompaniesPageLayoutComponent implements OnInit, OnChanges, OnDestroy {

    @ViewChild('table', { static: false }) table: DatatableComponent;

    readonly headerHeight = 50;
    readonly rowHeight = 63;

    perPages = [20, 50, 100, 500];
    perPageValue = 20;
    priorities = [1, 2, 3, 4, 5];

    projectStatuses = ['Live', 'Dead', 'Submit for review'];

    defaultValues = [
        { name: 'VIP', id: 1 },
        { name: 'Regular', id: 0 }
    ];

    form: FormGroup;

    counter = 0;
    filtersCounter = 0;

    rows: any[] = [];

    filteredAssignedMember: any[] = [];
    filteredCreatedMember: any[] = [];

    filteredSubtypes: any[] = [];

    referenceState: any;
    adminsState: any;

    reputations: any[];

    isTyping: any;
    isFilterValue: any;
    isNoMoreCompanies: boolean;

    isShowFilters = false;

    isLoading = false;

    oldPageUrl = COMPANIES_URL;

    isClearReputationFilter = false;

    sourceFilterCtrl: FormControl = new FormControl();

    filteredSource: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

    selected: string[] = [];

    selectError = false;

    active: boolean;

    protected _onDestroy = new Subject<void>();

    constructor(
        private companyService: CompanyService,
        private el: ElementRef,
        private store: Store<AppState>,
        private _fb: FormBuilder,
        private errorService: ErrorService,
        private themeService: ThemeService,
    ) {
        this.themeService.getThemeFromLocalStorage();
    }

    ngOnInit() {
        this.form = this._fb.group({
            name: new FormControl(''),
            reputation: new FormControl(['']),
            stage: new FormControl(''),
            assigned_to: new FormControl(''),
            project_statuses: new FormControl(''),
            created_by: new FormControl(''),
            type: new FormControl(''),
            subtype: new FormControl(''),
            country: new FormControl(''),
            city: new FormControl(''),
            // list: new FormControl(''),
            source: new FormControl(''),
            priority: new FormControl(''),
            email: new FormControl(''),
            website: new FormControl(''),
            dateFrom: new FormControl(''),
            dateTo: new FormControl(''),
        });

        this.setDefault();

        this.isLoading = true;

        this.companyService.getReference().pipe(
            tap(ref => {
                this.referenceState = ref;
                this.changeSubtype();
            }),
            switchMap(() => this.companyService.getAdmins())
        ).subscribe(res => {
            this.adminsState = res;

            this.filteredSource.next(this.referenceState.sources);

            this.sourceFilterCtrl.valueChanges
                .pipe(takeUntil(this._onDestroy))
                .subscribe(() => {
                    this.filterSources();
                });

            this.isLoading = false;

            this.filterAssignedMember();
            this.filterCreatedMember();

            this.onScroll(0);
        });
    }

    setDefault() {
        this.form.controls.reputation.setValue(
            [this.defaultValues[0].id, this.defaultValues[1].id]
        );
    }

    getPageValue(event: MatSelectChange) {
        this.perPageValue = event.value;
        this.onFilter(this.form.value);
    }

    ngOnChanges() {
    }

    ngOnDestroy() {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    onScroll(offsetY: number) {

        const viewHeight = this.el.nativeElement.getBoundingClientRect().height - this.headerHeight;

        if (!this.isLoading
            && !this.isTyping
            && !this.isNoMoreCompanies
            && offsetY + viewHeight >= this.rows.length * this.rowHeight
        ) {
            this.loadPage();
        }
    }

    onFilter(value) {

        this.counter = 1;
        this.filtersCounter = 0;

        this.isFilterValue = value;
        this.isLoading = true;
        this.isNoMoreCompanies = false;
        this.isShowFilters = false;

        Object.values(value).forEach((item: any) => {
            if (item && !item.per_page) { this.filtersCounter += 1; }
        });

        this.companyService.filterByCompanies(this.counter, value, this.perPageValue)
            .subscribe(data => {
                if (data.companies) {
                    this._serializeDataForTable(data);
                    this.rows = [...data.companies];
                } else {
                    this.rows = [];
                }
                this.isLoading = false;
            });
    }

    clearFilter(event) {
        switch (event.target.parentNode.textContent) {
            case 'Reputationclear':
                this.form.controls.reputation.setValue('');
                break;
            case ' Stage clear':
                this.form.controls.stage.reset();
                break;
            case ' Type clear':
                this.form.controls.type.reset();
                this.form.controls.subtype.reset();
                this.filteredSubtypes = this.referenceState.company_subtypes;
                break;
            case ' Category clear':
                this.form.controls.type.reset();
                this.form.controls.subtype.reset();
                break;
            case ' Country clear':
                this.form.controls.country.reset();
                break;
            case ' Sources clear':
                this.form.controls.source.reset();
                break;
            case ' Priority clear':
                this.form.controls.priority.reset();
                break;
            case ' Per page clear':
                this.form.controls.per_page.reset();
                break;
            case ' Created from clear':
                this.form.controls.dateFrom.reset();
                break;
            case ' Created to clear':
                this.form.controls.dateTo.reset();
                break;
            case ' Project status clear':
                this.form.controls.project_statuses.reset();
                break;
        }
    }

    filterAssignedMember(query?: string) {
        this.filteredAssignedMember = query
            ? this.adminsState.admins.filter(admin => admin.full_name.toLowerCase().includes(query.toLowerCase()))
            : this.adminsState.admins;
    }

    filterCreatedMember(query?: string) {
        this.filteredCreatedMember = query
            ? this.adminsState.admins.filter(admin => admin.full_name.toLowerCase().includes(query.toLowerCase()))
            : this.adminsState.admins;
    }

    displayFnForAssigned = (value?: number) => {
        return value ? this.filteredAssignedMember.find(item => item.id === value).full_name : undefined;
    }

    displayFnForCreated = (value?: number) => {
        return value ? this.filteredCreatedMember.find(item => item.id === value).full_name : undefined;
    }

    showFiltersWindow() {
        this.isShowFilters = !this.isShowFilters;
    }

    changeSubtype(typeId?: any) {
        this.filteredSubtypes =
            typeId ? this.referenceState.company_subtypes
                .filter(item => {
                    return item.type_id === typeId;
                }) : this.referenceState.company_subtypes;
    }

    patchSelect(typeId?: any) {
        this.form.patchValue({ type: typeId });
    }

    protected filterSources() {
        if (!this.referenceState.sources) {
            return;
        }
        let search = this.sourceFilterCtrl.value;
        if (!search) {
            this.filteredSource.next(this.referenceState.sources.slice());
            return;
        } else {
            search = search.toLowerCase();
        }
        this.filteredSource.next(
            this.referenceState.sources.filter(source => source.name.toLowerCase().indexOf(search) > -1)
        );
    }

    activeBurger(event) {
        this.active = event;
    }

    private loadPage() {
        this.isLoading = true;
        this.counter += 1;
        this.companyService.filterByCompanies(this.counter, this.isFilterValue, this.perPageValue)
            .subscribe(data => {
                if (data.companies) {
                    this._serializeDataForTable(data);
                    this.rows = [...this.rows, ...data.companies];
                } else {
                    this.isNoMoreCompanies = true;
                }
                this.isLoading = false;
            });
    }

    private _serializeDataForTable(data) {
        data.companies.forEach(item => {

            item.name = item.company.name;

            item.assigned_to.forEach(assign => {
                this.adminsState.admins.forEach(admin => {
                    if (assign.admin_id === admin.id) {
                        assign.admin_name = admin.full_name;
                    }
                });
            });

            this.adminsState.admins.forEach(admin => {
                if (admin.id === item.last_contact_admin_id) {
                    item.last_contact_admin_name = admin.full_name;
                }
            });

            this.referenceState.countries.forEach(country => {
                if (country.id === item.co_id) {
                    item.country_name = country.name;
                }
            });

            this.referenceState.stages.forEach(stage => {
                if (stage.id === item.stage) {
                    item.stage = stage.name;
                    item.stage_classname = stage.classname;
                }
            });

            this.referenceState.reputations.forEach(reputation => {
                if (reputation.id === item.reputation) {
                    item.reputation = reputation.name;
                    item.reputation_classname = reputation.classname;
                }
            });

            this.referenceState.company_types.forEach(type => {
                if (type.id === item.type) {
                    item.type = type.title;
                }
            });
        });
    }
}
