import { ErrorService } from './../../shared/services/error.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { tap, switchMap, mergeMap } from 'rxjs/operators';
import { CompanyItem, CompaniesModel } from 'src/app/models/companies.model';
import { CompanyService } from 'src/app/shared/services/company.service';
import { COMPANIES_URL, ACTIVITY_URL, PEOPLE_URL, PORTFOLIO_URL, CUSTOM_LISTS, SOCIAL_WALL } from 'src/app/shared/const/constants.const';
import { of } from 'rxjs';
import { ThemeService } from 'src/app/theme/theme.service';

@Component({
    selector: 'app-search-page-layout',
    templateUrl: './search-page-layout.component.html',
    styleUrls: ['./search-page-layout.component.scss']
})
export class SearchPageLayoutComponent implements OnInit {

    public query: string;
    public companies: CompanyItem[];
    public isNoCompanies: boolean;
    public isNoMoreCompanies: boolean;
    public counter: number;

    public isLoadingSearch = true;

    public companiesUrl = COMPANIES_URL;
    public activityUrl = ACTIVITY_URL;
    public peopleUrl = PEOPLE_URL;
    public portfolioUrl = PORTFOLIO_URL;
    public customLists = CUSTOM_LISTS;
    public socialwallUrl = SOCIAL_WALL;

    public active: any;

    constructor(
        private route: ActivatedRoute,
        private companyService: CompanyService,
        private errorService: ErrorService,
        private themeService: ThemeService
    ) {
        this.themeService.getThemeFromLocalStorage();
    }

    ngOnInit() {
        this.route.params.subscribe(params => {

            this.query = params.query;
            this.counter = 1;

            this.isLoadingSearch = true;
            this.isNoMoreCompanies = false;

            this.companyService.searchByCompany(this.query, this.counter)
                .pipe(
                    tap((res) => {
                        this.isNoCompanies = false;
                        if (res.companies !== undefined) {
                            this.companies = this._getSearchDataByCompanies(res);
                        } else {
                            this.isNoCompanies = true;
                            this.isLoadingSearch = false;
                        }
                    }),
                    mergeMap(() => this.companyService.getReference()
                        .pipe(
                            tap(res => {
                                this.isLoadingSearch = false;
                                this._serializeDataForCompanyTypes(this.companies, res);
                                this._serializeDataForCompanies(this.companies, res.stages, 'status', 'statusIcon');
                                this._serializeDataForCompanies(this.companies, res.reputations, 'reputation', 'reputationIcon');
                            })
                        )
                    ),
                    mergeMap(() => this.companyService.getAdmins()
                        .pipe(
                            tap(res => {
                                res.admins.forEach(admin => {
                                    if (this.companies) {
                                        this.companies.forEach(company => {
                                            if (admin.id === company.last_contact_admin_id) {
                                                company.last_contact_admin_name = admin.full_name;
                                            }
                                        });
                                    }
                                });
                            })
                        )
                    )
                )
                .subscribe(
                    () => of(null));
        });
    }

    showMoreCompanies() {
        this.isLoadingSearch = true;
        this.counter += 1;
        this.companyService.searchByCompany(this.query, this.counter)
            .pipe(
                tap((res) => {
                    this._pushMoreCompanies(res);
                }),
                switchMap(() => this.companyService.getReference()),
                tap((res) => {
                    this._serializeDataForCompanyTypes(this.companies, res);
                    this._serializeDataForCompanies(this.companies, res.stages, 'status', 'statusIcon');
                    this._serializeDataForCompanies(this.companies, res.reputations, 'reputation', 'reputationIcon');
                })
            )
            .subscribe(() => {
                this.isLoadingSearch = false;
            });
    }

    activeBurger(event) {
        this.active = event;
    }

    private _pushMoreCompanies(data: any) {
        if (data.companies) {
            data.companies.forEach((item: any) => {
                this.companies.push({
                    id: item.company.id,
                    name: item.company.name,
                    website: item.company.website ? item.company.website : null,
                    type: item.type,
                    status: item.stage,
                    co_id: item.co_id,
                    reputation: item.reputation,
                    last_contact_admin_id: item.last_contact_admin_id,
                    last_contact_date: item.last_contact_date
                });
            });
        } else {
            this.isNoMoreCompanies = true;
        }
    }

    private _getSearchDataByCompanies(data: any) {
        return data.companies.map(item => {
            return {
                id: item.company.id,
                name: item.company.name,
                website: item.company.website ? item.company.website : null,
                type: item.type,
                status: item.stage,
                co_id: item.co_id,
                reputation: item.reputation,
                last_contact_admin_id: item.last_contact_admin_id,
                last_contact_date: item.last_contact_date
            };
        });
    }

    private _serializeDataForCompanies(companies: CompanyItem[], data: any, itemType: string, itemIcon: string) {
        if (companies) {
            companies.forEach(item => {
                data.forEach((data: any) => {
                    if (item[itemType] === data.id) {
                        item[itemType] = data.name;
                        item[itemIcon] = data.classname;
                    }
                });
            });
        }
    }

    private _serializeDataForCompanyTypes(companies: CompanyItem[], res: any) {
        if (companies) {
            companies.forEach(item => {
                res.company_types.forEach(type => {
                    if (item.type === type.id) {
                        item.type = type.title;
                    }
                });
            });
        }
    }
}
