import { AdminsActions, ADMINS_ACTION } from '../actions/admins.action';

const initialState = {
    admins: []
};

export function adminsReducer(state = initialState, action: AdminsActions) {
    switch (action.type) {
        case ADMINS_ACTION.SUCCESS_LOAD_ADMINS:
            return {
                ...state,
                admins: action.payload.admins,
                root_avatar: action.payload.extra_data
            };
        default:
            return state;
    }
}
