import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';

@Component({
    selector: 'app-full-access-request-modal',
    templateUrl: './full-access-request-modal.component.html',
    styleUrls: ['./full-access-request-modal.component.scss']
})
export class FullAccessRequestModalComponent implements OnInit {

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<FullAccessRequestModalComponent>,
        private _snackBar: MatSnackBar
    ) { }

    ngOnInit() {
    }

    copyText(val: string) {

        let selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = val;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);

        this.showSnackBar();
        this.dialogRef.close();
    }

    showSnackBar() {
        this._snackBar.open('Copied to clipboard', null, {
            duration: 1500,
        });
    }

    onCancelClick() {
        this.dialogRef.close();
    }
}
