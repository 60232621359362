import { Theme } from "./symbols";

export const lightTheme: Theme = {
    name: "light",
    properties: {
        "--background": "#ffffff",
        "--background-checkbox": "#1c1d22",
        "--color": "#1F2125",
        "--filter": "none",
        "--main-background": "none",
        "--tab-background": "#000000",
        "--placeholder": "rgba(0,0,0,.42)",
        "--box-shadow": "0 2px 8px 0 rgba(191, 197, 227, 0.75)",
        "--attachments-background": "#e5f1ff",
        "--disable-button": "#e0e0e0",
        "--last-contact-green": "none",
        "--last-contact-orange": "none",
        "--last-contact-red": "none",
        "--ck-editor-on": "#dedede",
        "--ck-editor-hover": "#dedede",
        "--filter-button-background": "#E5F1FF",
        "--filters-background": "#f9fafc",
        "--boards-background": "#f9fafc",
        "--armstrong-primary": "#0064FF",
        "--armstrong-new": "#FC6565",
        "--armstrong-bg-new": "#FDE2E2",
        "--armstrong-in-progress": "#5966E0",
        "--armstrong-bg-in-progress": "#E5E8FF",
        "--armstrong-waiting": "#F28927",
        "--armstrong-bg-waiting": "#FFE8D4",
        "--armstrong-under-investig": "#EAB000",
        "--armstrong-bg-under-investig": "#FFF4D4",
        "--armstrong-on-hold": "#29ACF5",
        "--armstrong-bg-on-hold": "#D4EFFF",
        "--armstrong-resolved": "#00B900",
        "--armstrong-bg-resolved": "#DAFFD4",
        "--armstrong-closed": "#7E909A",
        "--armstrong-bg-closed": "#E5EAED",
        "--armstrong-bubble-1": "#EEF4FB",
        "--armstrong-bubble-2": "#0064FF30",
        "--armstrong-positive": "#0FBD05"
    }
};
