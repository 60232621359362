import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BootstrappedAdmin } from 'src/app/models/admins.model';
import {Ticket} from '../../../../models/ticket.model';

@Component({
    selector: 'app-ticket-merchant',
    templateUrl: './ticket-merchant.component.html',
    styleUrls: ['./ticket-merchant.component.scss']
})
export class TicketMerchantComponent implements OnInit {
    @Input() public ticket: Ticket;
    @Input() public assignees: BootstrappedAdmin[];
    @Input() public ticketId: string;
    @Input() public subject: string;
    @Input() public ticketIsNotLocked: boolean;
    @Output() reloadAttachments: EventEmitter<any> = new EventEmitter();

    // Default selected first tab
    public selectedTab = 0;

    constructor(private route: ActivatedRoute) {
        //
    }

    ngOnInit() {
        this.route.fragment.subscribe((fragment: string) => {
            if (new RegExp('\^task\-\+\\d\+').test(fragment)) {
                // If we have anchor to link to the task.
                // Will auto switch to task tab
                this.selectedTab = 2;
            }
        });
    }

    emitReloadAttachments() {
        this.reloadAttachments.emit(null);
    }
}

