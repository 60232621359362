import { HttpClient } from '@angular/common/http';
import { BASE_API_URL } from '../../shared/const/constants.const';
import { Observable } from 'rxjs';

export class BaseApi {
    constructor(public http: HttpClient) { }

    private getUrl(url: string = ''): string {
        return `${BASE_API_URL}${url}`;
    }

    public get(url: string = ''): Observable<any> {
        return this.http.get(this.getUrl(url), {
            withCredentials: true,
            headers: {
                Accept: 'application/json'
            }
        });
    }

    public put(url: string = '', body: {}): Observable<any> {
        return this.http.put(this.getUrl(url), body, { withCredentials: true });
    }

    public delete(url: string = ''): Observable<any> {
        return this.http.delete(this.getUrl(url), { withCredentials: true });
    }

    public post(url: string = '', body: {}): Observable<any> {
        return this.http.post(this.getUrl(url), body, { withCredentials: true });
    }
}
