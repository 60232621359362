import { FormControl } from '@angular/forms';
import { AdminsModel } from './../../models/admins.model';
import { ReferenceModel } from './../../models/reference.model';
import { switchMap, tap, mergeMap } from 'rxjs/operators';
import { LostModalComponent } from './lost-modal/lost-modal.component';
import { PORTFOLIO_URL } from './../../shared/const/constants.const';
import { CompanyItem } from 'src/app/models/companies.model';
import { CompanyService } from 'src/app/shared/services/company.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem, CdkDrag, CdkDragEnter, CdkDragExit } from '@angular/cdk/drag-drop';
import { Board } from 'src/app/models/board/board.model';
import { Column } from 'src/app/models/board/column.model';
import { MatDialog } from '@angular/material';
import { ThemeService } from 'src/app/theme/theme.service';

@Component({
    selector: 'app-my-deals-page-layout',
    templateUrl: './my-deals-page-layout.component.html',
    styleUrls: ['./my-deals-page-layout.component.scss']
})
export class MyDealsPageLayoutComponent implements OnInit {

    previousColumn: Column;
    column: Column;

    previousIcon: string;
    currentIcon: string;

    item: any;
    total: number;

    rootAvatar: string;

    currentUserLoggedIn: number;

    isLoading = false;

    admins: AdminsModel;

    filteredAssignedMember: any[] = [];

    oldDealsPageUrl = PORTFOLIO_URL;

    active: boolean;

    isShowManageColumns = false;

    counter: number = 14;

    constructor(
        private companyService: CompanyService,
        private themeService: ThemeService,
        private dialog: MatDialog
    ) {
        this.themeService.getThemeFromLocalStorage();
    }

    board: Board = new Board([]);

    ngOnInit() {
        this._setDataForColumns();
        this.checkedColumns();
    }

    selectColumn(name: string) {
        this.board.columns.forEach(column => {
            if (column.name === name && column.isShow) {
                column.isShow = false;
                this.counter -= 1;
            } else if (column.name === name && !column.isShow) {
                column.isShow = true;
                this.counter += 1;
            }
        });
    }

    filterColumns(items: Column[]) {
        return items.filter(item => {
            return item.isShow;
        });
    }

    showManageColumns() {
        this.isShowManageColumns = !this.isShowManageColumns;
    }

    drop(event: CdkDragDrop<string[]>) {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            transferArrayItem(event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex);

            if (this.column.id !== 16) {
                this.companyService
                    .editStage(this.item.company.id, this.column.id)
                    .subscribe();
            } else {
                this.dialog.open(LostModalComponent, {
                    panelClass: 'lost-modal',
                    width: '496px',
                    autoFocus: false,
                    data: {
                        previousStage: this.previousColumn.name,
                        currentStage: this.column.name,
                        previousIcon: this.previousColumn.classname,
                        currentIcon: this.column.classname,
                        companyName: this.item.company.name,
                    }
                }).afterClosed().subscribe(data => {
                    if (data.isCancel) {
                        transferArrayItem(event.container.data,
                            event.previousContainer.data,
                            event.currentIndex,
                            event.previousIndex,
                        );
                    } else {
                        this.companyService.addNote(this.item.company.id, data)
                            .pipe(
                                switchMap(() => this.companyService.editStage(this.item.company.id, this.column.id))
                            )
                            .subscribe();
                    }
                });
            }
        }
    }

    exit(item: any, previousColumn: Column) {
        this.item = item;
        this.previousColumn = previousColumn;
    }

    enter(column: Column) {
        this.column = column;
    }

    activeBurger(event) {
        this.active = event;
    }

    private _setDataForColumns() {

        this.isLoading = true;

        this.companyService.getReference()
            .pipe(
                tap((res: ReferenceModel) => {

                    let localStorageColumns = JSON.parse(localStorage.getItem('board'));

                    this.currentUserLoggedIn = res.logged_in_admin.id;

                    res.stages.forEach((stage, i) => {
                        this.board.columns.push({
                            name: stage.name,
                            id: stage.id,
                            classname: stage.classname,
                            isShow: true,
                            deals: []
                        });
                    });

                    if (localStorageColumns) {
                        this.board.columns.forEach((column) => {
                            localStorageColumns.forEach((local, i) => {
                                if (column.name === local.name) {
                                    column.isShow = local.isShow;
                                }
                            });
                        });
                    }
                }),
                mergeMap(() => this.companyService.getAdmins()
                    .pipe(
                        tap((res: AdminsModel) => {
                            this.admins = res;
                            this.rootAvatar = res.extra_data.root_avatar_url;
                            this.filterAssignedMember();
                        })
                    )
                ),
                mergeMap(() => this.companyService.searchByCompany({ assigned_to: this.currentUserLoggedIn })
                )
            )
            .subscribe(res => {
                this._sortByColumns(res.companies);
                this.isLoading = false;
            });
    }

    checkedColumns(event?) {

        if (event) {
            localStorage.setItem('board', JSON.stringify(this.board.columns));
        }

        let localData = JSON.parse(localStorage.getItem('board'));

        if (localData) {
            let filteredDeals = localData.filter(item => {
                return item.isShow;
            });
            this.counter = filteredDeals.length;
        }

        this.isShowManageColumns = false;

    }

    filterAssignedMember(query?: string) {
        this.filteredAssignedMember = query
            ? this.admins.admins.filter(admin => admin.full_name.toLowerCase().includes(query.toLowerCase()))
            : this.admins.admins;
    }

    displayFnForAssigned = (value?: number) => {
        return value ? this.filteredAssignedMember.find(item => item.id === value).full_name : undefined;
    }

    filter(value) {
        this.isLoading = true;
        this.companyService.searchByCompany({ assigned_to: value })
            .subscribe(res => {
                this.board.columns.forEach(col => col.deals = []);
                this._sortByColumns(res.companies);
                this.isLoading = false;
            });
    }

    private _getSumOfVolume(deals: any[]) {
        return deals.reduce((a, b) => {
            return a + b.company.volume;
        }, 0)
    }

    private _sortByColumns(companies: any[]) {
        if (companies) {
            companies.forEach(company => {
                this.board.columns.forEach((column, i) => {
                    switch (company.stage) {
                        case column.id:
                            this.board.columns[i].deals.push(company);
                            this.board.columns[i].total_volume = this._getSumOfVolume(this.board.columns[i].deals);
                            break;
                    }
                });
            });
        } else {
            this.isLoading = false;
        }
    }
}
