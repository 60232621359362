import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CrmApi } from '../../helpers/crm-api/crm-api';
import * as moment from 'moment';
import { AuthTicketService } from './auth-ticket.service';
import {
    AddTicketNoteBody,
    Ticket,
    TicketCategory,
    TicketMessage,
    TicketNote,
    TicketUpdatePayload
} from 'src/app/models/ticket.model';

@Injectable({
    providedIn: 'root'
})
export class TicketService extends CrmApi {
    constructor(
        public http: HttpClient,
        private authTicketService: AuthTicketService
    ) {
        super(http);
    }

    getTickets(params: object = {}): Observable<any> {
        return this.get(`/ticket`, {
            inquiry: 1,
            ...params
        });
    }

    getTicketById(id, params = {}): Observable<Ticket> {
        return this.get(`/ticket/${id}`, params).pipe(
            map(response => response.ticket)
        );
    }

    filterByTickets(counter?: number, filterValue?: any, perPage?: number, include?: string): Observable<any> {
        const allowedSortColumns = ['ticket_id', 'status_changed_date'];
        const filterObj = {
            with: include,
            inquiry: 1,
            ticket_id: filterValue ? (filterValue.ticket_id) : undefined,
            transaction_id: filterValue ? (filterValue.transaction_id) : undefined,
            // status: filterValue && filterValue.status ? (filterValue.status) : undefined,
            merchant_email: filterValue ? filterValue.user_email : undefined,
            category_id: filterValue ? (filterValue.category_id) : undefined,
            problem_id: filterValue ? (filterValue.problem_id) : undefined,
            assignee_id: filterValue ? (filterValue.assignee_id) : undefined,
            author_id: filterValue ? (filterValue.author_id) : undefined,
            is_headless: filterValue ? (filterValue.is_headless) : undefined,
            priority: filterValue ? (filterValue.priority) : undefined,
            status_decision: filterValue ? (filterValue.status_decision) : undefined,
            per_page: perPage,
            page: counter,
            include: 'author',
            service_id: filterValue ? filterValue.service_id : undefined,
            created_after: filterValue && filterValue.created_after && filterValue.created_before
                ? (moment(filterValue.created_after).format('YYYY-MM-DD'))
                : undefined,
            created_before: filterValue && filterValue.created_before && filterValue.created_after
                ? (moment(filterValue.created_before).format('YYYY-MM-DD'))
                : undefined,
            'sort[field]': filterValue && allowedSortColumns.includes(filterValue['sort[field]']) ? filterValue['sort[field]'] : null,
            'sort[direction]': filterValue ? filterValue['sort[direction]'] : null,
        };

        if (filterValue && filterValue.status && filterValue.status.length) {
            filterValue.status.forEach((status, statusIdx) => {
                filterObj[`status[${statusIdx}]`] = status;
            });
        }

        if (filterValue && filterValue.type && filterValue.type.length) {
            filterValue.type.forEach((type, index) => {
                filterObj[`type[${index}]`] = type;
            });
        }

        const merchantProfileFilterMaps = {
            merchant_platform: 'platform',
            merchant_source: 'source',
            merchant_status: 'status',
            merchant_kyc_status: 'kyc_status',
            merchant_bm_status: 'business_model_status',
            merchant_country: 'country'
        };
        Object.keys(merchantProfileFilterMaps).map(item => {
            if (filterValue && filterValue.hasOwnProperty(item)) {
                filterObj[`merchant_profile[${merchantProfileFilterMaps[item]}]`] = filterValue[item];
            }
        });

        for (const key in filterObj) {
            if (!filterObj[key]) {
                delete filterObj[key];
            }
        }

        const filterData = new URLSearchParams(Object.entries(filterObj)).toString();

        return this.get(`/ticket?${filterData}`);
    }

    getTicketAttachments(ticketId) {
        return this.get(`/ticket-attachment?ticket_id=${ticketId}`);
    }

    getTicketConversation(ticketId, type = 'all') {
        const defaultPath = `/ticket-message/all?with=attachments&ticket_id=${ticketId}`;
        let requestPath = defaultPath;
        if (type !== 'all') {
            requestPath = `${defaultPath}&conversation_type=${type}`;
        }

        return this.get(requestPath);
    }

    extendUrlWithToken(url) {
        return `${url}?token=${this.authTicketService.accessToken}`;
    }

    sendMessage(data: {
        ticket_id: string | number,
        sender_type: string,
        message: string,
        attachments?: File[],
        conversation_type?: string,
    }) {
        const formData = new FormData();
        for (const key in data) {
            if (key !== 'attachments') {
                formData.append(key, data[key]);
            } else {
                data[key].forEach((file, fileIdx) => {
                    formData.append(`${key}[${fileIdx}][file]`, file);
                });
            }
        }
        return this.postFormData('/ticket-message', formData);
    }

    createTicket(payload: object): Observable<Ticket> {
        const formData = new FormData();
        for (const [key, value] of Object.entries(payload)) {
            if (!['', null].includes(value)) {
                formData.append(key, value);
            }
        }
        return this.postFormData('/ticket', formData).pipe(
            map(response => response.ticket)
        );
    }

    updateTicketV2(id: number, payload: Partial<TicketUpdatePayload>): Observable<Ticket> {
        return this.put(`/ticket/${id}`, payload).pipe(
            map(response => response.ticket)
        );
    }

    updateTicket({ ticket_id, data }: {
        ticket_id: number, data: {
            status?: string,
            type?: string,
            user_email?: string,
            subject?: string,
            user_id?: number,
            transaction_id?: number,
            transaction_date?: string,
            assignee_id?: number,
            category_id?: number,
            problem_id?: number,
            merchant_email?: string,
            merchant_id?: number,
            description?: string,
            lock_admin_id?: number | string
        }
    }) {
        return this.putFormUrlEncoded(`/ticket/${ticket_id}`, new URLSearchParams(Object.entries(data) as [string, string][]));
    }

    assignTicket({ ticket_id, assignee_id }: { ticket_id: number, assignee_id: number }) {
        return this.updateTicket({
            ticket_id, data: {
                assignee_id
            }
        });
    }

    deleteTicketMessage(id: number): Observable<TicketMessage> {
        return this.delete(`/ticket-message/${id}`).pipe(
            map(response => response.ticket_message)
        );
    }

    updateTicketMessage(id: number, payload: Partial<TicketMessage>): Observable<TicketMessage> {
        const data = new URLSearchParams(Object.entries(payload) as [string, string][]);
        return this.putFormUrlEncoded(`/ticket-message/${id}/update`, data)
            .pipe(
                map(response => response.ticket_message)
            );
    }

    getTicketNotes(id: number) {
        return this.get<{ ticket_admin_notes: TicketNote[] }>('/ticket-admin-note/all', {
            ticket_id: id
        });
    }

    getTicketCategories(params = {}) {
        return this.get<TicketCategory[]>('/category', params);
    }

    addTicketNote(data: AddTicketNoteBody) {
        const body = new FormData();
        for (const key in data) {
            body.append(key, data[key]);
        }
        return this.postFormData('/ticket-admin-note', body);
    }
}
