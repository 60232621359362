import { GetNotesResponse } from 'src/app/shared/responses/get-notes.response';
import { PeopleService } from './../../../shared/services/people.service';
import { Component, OnInit, Input, OnChanges, ViewChild, EventEmitter, Output } from '@angular/core';
import { Observable, Observer, of } from 'rxjs';
import { DialogComponent } from '../../company-page-content/activity-widget/dialog/dialog.component';
import { MatDialog, MatTabChangeEvent, MatTabGroup } from '@angular/material';
import { mergeMap, switchMap, tap } from 'rxjs/operators';
import { NotesModel } from 'src/app/models/notes.model';
import { PermissionsService } from 'src/app/shared/services/permissions.service';
import { PersonModel } from 'src/app/models/person.model';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/redux/app.state';
import { SuccessLoadPerson } from 'src/app/redux/actions/person.action';

@Component({
    selector: 'app-activity-person-widget',
    templateUrl: './activity-person-widget.component.html',
    styleUrls: ['./activity-person-widget.component.scss']
})
export class ActivityPersonWidgetComponent implements OnChanges {

    @ViewChild(MatTabGroup, { static: false }) tabGroup: MatTabGroup;

    @Input() notes: any[];
    @Input() counterLimit: number;
    @Input() id: number;
    @Input() allAdmins: any[];
    @Input() rootAvatar: string;
    @Input() noteTypes: any[];
    @Input() isLoading: boolean;

    asyncTabs: Observable<any[]>;

    filterTypes: string[];
    type: any;
    totalCount: number;
    filterAdmin: number;
    admin: any;

    initialLoadNotes = false;

    constructor(
        public dialog: MatDialog,
        public ps: PermissionsService,
        private peopleService: PeopleService,
        private store: Store<AppState>,
    ) {
        this.asyncTabs = new Observable((observer: Observer<any[]>) => {
            setTimeout(() => {
                observer.next([
                    { label: 'All Activity', buttonName: 'Log Activity', isShowFilter: true },
                    { label: 'Notes', buttonName: 'Log Note', isShowFilter: false },
                    { label: 'Emails', buttonName: 'Log Email', isShowFilter: false },
                    { label: 'Calls', buttonName: 'Log Call', isShowFilter: false },
                    { label: 'Meetings', buttonName: 'Log Meeting', isShowFilter: false },
                ]);
            }, 1000);
        });
    }

    ngOnChanges() {
        this.filterTypes = [];
        if (this.tabGroup) this.tabGroup.selectedIndex = 0;
    }

    openDialog(buttonName: string): void {
        this.dialog.open(DialogComponent, {
            width: '520px',
            height: 'auto',
            data: {
                note_types: this.noteTypes,
                buttonName,
            },
            panelClass: 'activity-dialog'
        }).afterClosed()
            .subscribe(data => {
                if (data) {
                    this._addNote(data);
                }
            });
    }

    filterByTab(event: MatTabChangeEvent) {
        switch (event.tab.textLabel) {
            case 'All Activity':
                this.type = this.filterTypes;
                break;
            case 'Notes':
                this.type = 'note';
                this.initialLoadNotes = true;
                break;
            case 'Emails':
                this.type = 'email';
                this.initialLoadNotes = true;
                break;
            case 'Calls':
                this.type = 'call';
                this.initialLoadNotes = true;
                break;
            case 'Meetings':
                this.type = 'meeting';
                this.initialLoadNotes = true;
                break;
        }
        if (this.initialLoadNotes) this._getNotes();
    }


    getFilterTypes(data) {
        if (data.admin_id) {
            this.admin = data.admin_id;
            this.filterAdmin = data.admin_id;
        } else {
            this.type = data.types;
            this.filterTypes = data.types;
        }

        if (data.types && data.types.length === 0) {
            this.type = [];
        }

        this._getNotes();
    }

    showMoreNotes() {
        this._getNotes(5);
    }

    private _addNote(data) {
        this.isLoading = true;
        this.peopleService.addNoteByPerson(this.id, data)
            .pipe(
                mergeMap(() => data.note_type === 'interview_feedback' ? this.peopleService.getPeopleById(this.id)
                    .pipe(
                        tap((res: PersonModel) => {
                            this.store.dispatch(new SuccessLoadPerson(res));
                        })
                    ) : of(null)
                ),
                mergeMap(() => this.peopleService.getNotesByPerson(this.id, this.counterLimit)))
            .subscribe(res => {
                localStorage.clear();
                this.notes = new GetNotesResponse(res, this.allAdmins, this.noteTypes).getNotes();
                this.isLoading = false;
            });
    }

    private _getNotes(counter?: number) {
        this.isLoading = true;
        counter ? this.counterLimit += counter : this.counterLimit;
        this.peopleService.getNotesByPerson(this.id, this.counterLimit, this.type, this.admin)
            .subscribe((res: NotesModel) => {
                if (this.noteTypes) {
                    this.notes = [];
                    this.notes = new GetNotesResponse(res, this.allAdmins, this.noteTypes).getNotes();
                    this.totalCount = res.notes.total_count;
                    this.isLoading = false;
                }
            });
    }
}
