import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { MatDialog } from '@angular/material';

@Component({
    selector: 'app-filters',
    templateUrl: './filters.component.html',
    styleUrls: ['./filters.component.scss']
})
export class FiltersComponent implements OnInit, OnChanges {

    @Input() public noteTypes: any[];
    @Input() public allAdmins: any[];
    @Input() public rootAvatar: any;
    @Input() public filterTypes: any[];

    @Output() public submit: EventEmitter<any> = new EventEmitter<any>();
    @Output() public filter: EventEmitter<any> = new EventEmitter<any>();

    public isShowFilterActivity = false;
    public isShowUsersFilter = false;

    public types: string[] = [];
    public userCounter = 0;

    filteredAdmin: any[] = [];

    adminInput = '';

    constructor(
        public dialog: MatDialog,
    ) { }

    ngOnChanges() {
        this.filteringAdmin();
        this.types = this.filterTypes;
        // if (this.noteTypes) {
        //     this.noteTypes.forEach(note => {
        //         note.checked = false;
        //     });
        // }
    }

    ngOnInit() {
    }

    filterNotesByTypes() {
        this.filter.emit({ types: this.types });
        this.isShowFilterActivity = false;
    }

    filterNotesByUser(adminId: number) {
        this.userCounter = 0;
        this.userCounter += 1;
        this.adminInput = this.filteredAdmin.find(admin => admin.id === adminId).full_name;
        this.filteringAdmin(this.adminInput);
        this.filter.emit({ admin_id: adminId });
        this.isShowUsersFilter = false;
    }

    filteringAdmin(query?: string) {
        this.filteredAdmin = query
            ? this.allAdmins.filter(admin => admin.full_name.toLowerCase().includes(query.toLowerCase()))
            : this.allAdmins;
    }

    showFilter() {
        this.isShowFilterActivity = !this.isShowFilterActivity;
        this.isShowUsersFilter = false;
    }

    showUserFilter() {
        this.isShowUsersFilter = !this.isShowUsersFilter;
        this.isShowFilterActivity = false;
    }

    getTypes(value: string, event) {

        const eventChecked = event.checked;
        const index = this.types.indexOf(value);

        this.noteTypes.forEach(note => {
            if (note.id === value) {
                note.checked = eventChecked;
            } else if (note.id === value && note.checked && !eventChecked) {
                note.checked = eventChecked;
            }
        });

        if (this.types.length === 0) {
            this.types.push(value);
        } else if (this.types.length !== 0 && index >= 0) {
            this.types.splice(index, 1);
        } else {
            this.types.push(value);
        }
    }

    trackByFn(index: number, el: any) {
        return el.id;
    }

    displayFnForAdmin = (value?: number) => {
        return value ? this.filteredAdmin.find(item => item.id === value).full_name : undefined;
    }

    clearFilters() {
        this.noteTypes.forEach(note => note.checked = false);
        this.types = [];
        this.userCounter = 0;
        this.adminInput = '';
        this.filteringAdmin();
        this.filter.emit({ types: this.types, admin_id: 'clear' });
    }
}
