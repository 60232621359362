import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, FormArray, Validators } from '@angular/forms';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CompanyService } from 'src/app/shared/services/company.service';
import { ReferenceModel, CountryItem, CompanyTypeItem, CompanySubtypeItem, RoleItem, CurrencyItem } from 'src/app/models/reference.model';
import { Router } from '@angular/router';
import { tap, switchMap, mergeMap } from 'rxjs/operators';
import { AdminsModel, AdminItem } from 'src/app/models/admins.model';
import { OfficesModel, OfficeItem } from 'src/app/models/offices.model';
import { PeopleService } from 'src/app/shared/services/people.service';

@Component({
  selector: 'app-create-person-page-layout',
  templateUrl: './create-person-page-layout.component.html',
  styleUrls: ['./create-person-page-layout.component.scss'],
})
export class CreatePersonPageLayoutComponent implements OnInit {

  isLoading = false;
  isShowPersonContacts = false;

  imgUrlPerson: any;
  personIcon: any;

  active: boolean;

  countries: CountryItem[];
  types: CompanyTypeItem[];
  subtypes: CompanySubtypeItem[];
  offices: OfficesModel;
  admins: AdminItem[];
  roles: RoleItem[];
  currencies: CurrencyItem[];

  filteredPositions: OfficeItem;
  filteredSubtypes: CompanySubtypeItem[] = [];
  filteredTeam: any[];

  form: FormGroup;

  isShowRefferal = false;
  isShowOffice = false;
  isShowCompensation = false;
  isShowCurrency = false;
  isShowDatetimeInterviewed = false;
  isShowRelocationTerms = false;
  isShowPositions = false;

  config = {
    toolbar: ['bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote']
  };

  editor = ClassicEditor;

  constructor(
    private _fb: FormBuilder,
    private _router: Router,
    private companyService: CompanyService,
    private peopleService: PeopleService,
  ) { }

  ngOnInit() {
    this.form = this._fb.group({
      name_person: new FormControl('', [Validators.required]),
      last_name_person: new FormControl('', [Validators.required]),
      title_person: new FormControl('', [Validators.required]),
      email_person: new FormControl('', [Validators.email]),
      type_person: new FormControl(''),
      website_person: new FormControl(''),
      skype_person: new FormControl(''),
      wechat_person: new FormControl(''),
      facebook_person: new FormControl(''),
      linkedin_person: new FormControl(''),
      summary_person: new FormControl(''),
      country_person: new FormControl(''),
      city_person: new FormControl(''),
      address_person: new FormControl(''),
      zip_code_person: new FormControl(''),
      fax_person: new FormControl(''),
      status_person: new FormControl(''),
      refferal_person: new FormControl(''),
      office_person: new FormControl(''),
      compensation: new FormControl('', Validators.pattern(/^-?(0|[1-9]\d*)?$/)),
      currency: new FormControl(''),
      datetime: new FormControl(''),
      fly_terms: new FormControl(''),
      allowance_terms: new FormControl(''),
      accommodation_terms: new FormControl(''),
      insurance_terms: new FormControl(''),
      positions_person: new FormControl([]),
      phonePersonRows: this._fb.array([this.initPhonePersonRows('')]),
      nameRows: this._fb.array([this.initNameRows('')]),
      roleRows: this._fb.array([this.initRoleRows('')]),
    });

    this.companyService.getReference()
      .pipe(
        tap((res: ReferenceModel) => {
          this.countries = res.countries;
          this.types = res.company_types;
          this.subtypes = res.company_subtypes;
          this.roles = res.admin_roles;
          this.currencies = res.job_currencies;
        }),
        mergeMap(() => this.peopleService.getOffices()
          .pipe(
            tap((res: OfficesModel) => {
              this.offices = res;
            })
          )
        ),
        mergeMap(() => this.companyService.getAdmins())
      ).subscribe((res: AdminsModel) => {
        this.admins = res.admins;
        this.filterTeam();
      });
  }

  get formPhonePersonArr() {
    return this.form.get('phonePersonRows') as FormArray;
  }

  get formNameArr() {
    return this.form.get('nameRows') as FormArray;
  }

  get formRoleArr() {
    return this.form.get('roleRows') as FormArray;
  }

  initNameRows(admin: any) {
    return this._fb.group({
      admin_id: [admin]
    });
  }

  initRoleRows(role: any) {
    return this._fb.group({
      role_id: [role]
    });
  }

  initPhonePersonRows(phone: any) {
    return this._fb.group({
      phone: [phone]
    });
  }

  addNewRow() {
    this.formNameArr.push(this.initNameRows(''));
    this.formRoleArr.push(this.initRoleRows(''));
  }

  addNewPhonePersonRow() {
    this.formPhonePersonArr.push(this.initPhonePersonRows(''));
  }

  filterTeam(query?: string) {
    this.filteredTeam = query
      ? this.admins.filter(team => team.full_name.toLowerCase().includes(query.toLowerCase()))
      : this.admins;
  }

  showPersonContacts() {
    this.isShowPersonContacts = !this.isShowPersonContacts;
  }

  uploadPersonIcon(event: any) {
    this.onFileChange(event, 'employee');
  }

  onSubmit(value) {
    this.isLoading = true;
    this.companyService.addPeople(value, null, this.personIcon)
      .subscribe(res => {
        this.isLoading = false;
        this._router.navigate([`/person/${res.person.id}`]);
      });
  }

  activeBurger(event) {
    this.active = event;
  }

  filterPositionsByOffice(officeId: number) {
    this.filteredPositions = this.offices.offices
      .find((item: OfficeItem) => {
        return item.id === officeId;
      });
  }

  displayFn = (value?: number) => {
    return value ? this.filteredTeam.find(item => item.id === value).full_name : undefined;
  }

  changeSubtype(typeId: number) {

    if (typeId === 6) {
      this.isShowRefferal = true;
      this.isShowOffice = true;
      this.isShowCompensation = true;
      this.isShowCurrency = true;
      this.isShowDatetimeInterviewed = true;
      this.isShowRelocationTerms = true;
      this.isShowPositions = true;
    } else {
      this.isShowRefferal = false;
      this.isShowOffice = false;
      this.isShowCompensation = false;
      this.isShowCurrency = false;
      this.isShowDatetimeInterviewed = false;
      this.isShowRelocationTerms = false;
      this.isShowPositions = false;
    }

    this.filteredSubtypes = this.subtypes.filter(subtype => {
      return typeId === subtype.type_id;
    });
  }

  private onFileChange(event: any, type: string) {

    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {

      const fileName = event.target.files[0].name;

      const [file] = event.target.files;

      reader.readAsDataURL(file);

      reader.onload = () => {

        if (typeof reader.result === 'string') {

          const result = reader.result.split(',')[1];

          if (type === 'employee') {
            this.imgUrlPerson = '';
            this.imgUrlPerson = reader.result;

            this.personIcon = {
              name: fileName,
              content: result
            };
          }
        }
      };
    }
  }
}
