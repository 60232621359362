import {ARMSTRONG_ADMIN_AUTHENTICATED, TicketActions} from '../actions/ticket.action';

const initialState = {
  armstrongAdmin: {}
};

export function ticketReducer(state = initialState, action: TicketActions) {
  switch (action.type) {
    case ARMSTRONG_ADMIN_AUTHENTICATED:
      return {
        ...state,
        armstrongAdmin: action.payload
      };

    default:
      return state;
  }
}
