import { Component, Input, OnInit } from '@angular/core';
import { Ticket } from '../../../../models/ticket.model';
import { get } from 'lodash';

@Component({
    selector: 'app-ticket-summary',
    templateUrl: './ticket-summary.component.html',
    styleUrls: ['./ticket-summary.component.scss']
})
export class TicketSummaryComponent implements OnInit {
    @Input() public ticket: Ticket;

    ngOnInit() {}

    get statusDecision() {
        return get(this.ticket, 'params.decision') || 'N/A';
    }
}
