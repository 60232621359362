import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { CompanyItem } from '../../../models/companies.model';

@Component({
    selector: 'app-companies',
    templateUrl: './companies.component.html',
    styleUrls: ['./companies.component.scss']
})
export class CompaniesComponent implements OnInit, OnChanges {

    @Input() public companies: CompanyItem[];
    @Input() public query: string;
    @Input() public isNoCompanies: boolean;
    @Input() public isLoadingSearch: boolean;
    @Input() public isNoMoreCompanies: boolean;

    @Output() public showMore: EventEmitter<any> = new EventEmitter<any>();

    constructor() { }

    ngOnInit() { }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.isNoCompanies) this.companies = [];
    }

    trackByFn(index: number, el: any) {
        return el.id;
    }
}
