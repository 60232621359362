import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TicketNote, AddTicketNoteBody } from 'src/app/models/ticket.model';
import { AppState } from 'src/app/redux/app.state';
import { TicketService } from 'src/app/shared/services/ticket.service';
import { PersonModel } from 'src/app/models/person.model';

@Component({
    selector: 'app-ticket-notes',
    templateUrl: './ticket-notes.component.html',
    styleUrls: ['./ticket-notes.component.scss']
})
export class TicketNotesComponent implements OnInit {

    @Input() ticketId: number;

    notes: TicketNote[] = [];
    editorData = '';
    sendingMessage = false;
    person: PersonModel;
    scrollTimeout: any;

    constructor(private ticketService: TicketService, private store: Store<AppState>) {
        this.store.pipe(select('person'))
            .subscribe((store) => {
                this.person = store;
            });
    }

    get sendButtonIsDisabled() {
        return this.sendingMessage || !this.editorData;
    }

    get sendButtonTooltipMessage() {
        return !this.editorData ? 'Please enter your note to send' : '';
    }

    ngOnInit() {
        this.getTicketNotes();
    }

    getTicketNotes() {
        this.ticketService.getTicketNotes(this.ticketId).toPromise().then(response => {
            if (response.ticket_admin_notes) {
                this.notes = response.ticket_admin_notes;
            }
        }).finally(() => {
            this.scrollToCurrentNote();
        });
    }

    sendMessage(id?: number) {
        if (this.editorData) {
            this.sendingMessage = true;
            const data: AddTicketNoteBody = {
                admin_id: this.person.userInfo.admin_id,
                message: this.editorData,
                ticket_id: this.ticketId
            };

            if (id !== undefined && id !== null) {
                data.parent_id = id;
            }
            this.ticketService.addTicketNote(data).toPromise().then(reponse => {
                this.getTicketNotes();
                this.editorData = '';
            }).finally(() => {
                this.sendingMessage = false;
            });
        }
    }
    scrollToCurrentNote() {
        clearTimeout(this.scrollTimeout);
        this.scrollTimeout = setTimeout(() => {
            const messageBox = document.getElementById('notes-wrapper');
            if (messageBox) {
                messageBox.scrollTop = messageBox.scrollHeight;
            }
        }, 100);
    }

}
