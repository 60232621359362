import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
    selector: 'app-reply-note-dialog',
    templateUrl: './reply-note-dialog.component.html',
    styleUrls: ['./reply-note-dialog.component.scss']
})
export class ReplyNoteDialogComponent implements OnInit {
    editorData = ''

    constructor(
        public dialogRef: MatDialogRef<ReplyNoteDialogComponent>,
    ) { }

    ngOnInit() {
    }

    get sendButtonTooltipMessage() {
        return !this.editorData ? 'Please enter your note to send' : ''
    }

    onNoClick() {
        this.dialogRef.close()
    }



}
