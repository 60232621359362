import { CompanyService } from "../../../shared/services/company.service";
import {
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges
} from "@angular/core";
import {
    FormControl,
    FormGroup,
    FormBuilder,
    Validators,
    AbstractControl
} from "@angular/forms";
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { PermissionsService } from "src/app/shared/services/permissions.service";

@Component({
    selector: "app-contractual-info-widget",
    templateUrl: "./contractual-info-widget.component.html",
    styleUrls: ["./contractual-info-widget.component.scss"]
})
export class ContractualInfoWidgetComponent implements OnInit, OnChanges {
    @Input() public company: any;
    @Input() public id: number;
    @Input() public isLoading: boolean;
    @Input() public companyContractualReferences: any;

    public form: FormGroup;

    public isShowInput: boolean;

    public editor = ClassicEditor;

    public config = {
        toolbar: [
            "bold",
            "italic",
            "link",
            "bulletedList",
            "numberedList",
            "blockQuote"
        ]
    };

    public _company: any;

    public initializeFormValue() {
        this.form.controls['percentage_of_rolling_reserve_amount'].setValue(this._company.contractual_info.percentage_of_rolling_reserve_amount);
        this.form.controls['days_for_releasing_the_rolling_reserve'].setValue(this._company.contractual_info.days_for_releasing_the_rolling_reserve);
        this.form.controls['commercials_offered_to_the_merchant'].setValue(this._company.contractual_info.commercials_offered_to_the_merchant);
        this.form.controls['settlement_timeline'].setValue(this._company.contractual_info.settlement_timeline);
        this.form.controls['agreement_type'].setValue(this._company.contractual_info.agreement_type);
        this.form.controls['notes'].setValue(this._company.contractual_info.notes);
        this.form.controls['security_deposit_amount'].setValue(this._company.contractual_info.security_deposit_amount);
        this.form.controls['days_on_hold'].setValue(this._company.contractual_info.days_on_hold);
        this.form.controls['limitation_of_liability_amount'].setValue(this._company.contractual_info.limitation_of_liability_amount);
        this.form.controls['payout_time_frame'].setValue(this._company.contractual_info.payout_time_frame);
    }

    constructor(
        private companyService: CompanyService,
        public ps: PermissionsService,
        private _fb: FormBuilder
    ) { }

    ngOnInit() {
        this._company = this.company
        const validateFieldByMerchantType = (control: AbstractControl) => {
            if (!control.value && this._company && this._company.type === 1) {
                return { invalidMerchantField: true }
            }
            return null
        }
        const validateFieldByPaymentType = (control: AbstractControl) => {
            if (!control.value && this._company && this._company.type === 4) {
                return { invalidPaymentField: true }
            }
            return null
        }
        this.form = this._fb.group({
            percentage_of_rolling_reserve_amount: new FormControl("", [
                validateFieldByMerchantType,
                Validators.pattern(`\\d+(\\.\\d{1,})?`)
            ]),
            days_for_releasing_the_rolling_reserve: new FormControl("", [
                validateFieldByMerchantType,
                Validators.pattern(`\\d+(\\.\\d{1,})?`)
            ]),
            commercials_offered_to_the_merchant: new FormControl("", [
                validateFieldByMerchantType
            ]),
            settlement_timeline: new FormControl(null, [validateFieldByMerchantType,
                Validators.pattern(`\\d+(\\.\\d{1,})?`)]),
            agreement_type: new FormControl(null, [validateFieldByMerchantType]),
            notes: new FormControl(""),
            security_deposit_amount: new FormControl("", [validateFieldByPaymentType]),
            days_on_hold: new FormControl("", [validateFieldByPaymentType]),
            limitation_of_liability_amount: new FormControl(""),
            payout_time_frame: new FormControl("")

        });
        this.initializeFormValue()
    }

    ngOnChanges(changes: SimpleChanges): void {
    }

    onCancel() {
        this._hideEditor();
        this.initializeFormValue();
    }

    onSubmit() {
        if (this.form.valid) {
            this._editContractualInfo();
            this._hideEditor();
        }
    }

    private _editContractualInfo() {
        this.isLoading = true;
        let payload = {}
        if (this._company.type === 1) {
            payload = {
                company_id: this.id,
                data: {
                    percentage_of_rolling_reserve_amount: this.form.value.percentage_of_rolling_reserve_amount,
                    days_for_releasing_the_rolling_reserve: this.form.value.days_for_releasing_the_rolling_reserve,
                    commercials_offered_to_the_merchant: this.form.value.commercials_offered_to_the_merchant,
                    settlement_timeline: this.form.value.settlement_timeline,
                    agreement_type: this.form.value.agreement_type,
                    notes: this.form.value.notes
                }
            }
        }
        if (this._company.type === 4) {
            payload = {
                company_id: this.id,
                data: {
                    security_deposit_amount: this.form.value.security_deposit_amount,
                    days_on_hold: this.form.value.days_on_hold,
                    limitation_of_liability_amount: this.form.value.limitation_of_liability_amount,
                    payout_time_frame: this.form.value.payout_time_frame
                }
            }
        }
        if (this._company.contractual_info.id) {
            this.companyService.editContractualInfo(payload, this._company.contractual_info.id).subscribe(() => {
                this._company.contractual_info = { ...this._company.contractual_info, ...this.form.value }
                this.isLoading = false;
            },
                () => {
                    this.isLoading = false;
                })
        }
        else {
            this.companyService.addContractualInfo(payload).subscribe(() => {
                this.companyService.getCompanyById(this.id).subscribe((res) => {
                    this._company.contractual_info.id = res.company.contractual_info.id
                    this.isLoading = false;
                })
            },
                () => {
                    this.isLoading = false;
                })
        }
    }

    private _hideEditor() {
        this.isShowInput = false;
    }

    public onSelect(value: any, field: string) {
        this.form.controls[field].setValue(value);
    }

    public getOfferedCommercialType(id: number) {
        const result = this.companyContractualReferences.commercials_offered_to_the_merchant.find(item => item.id === id)
        return result ? result.name : ''
    }

    public getAgreementType(id: number) {
        const result = this.companyContractualReferences.agreement_type.find(item => item.id === id)
        return result ? result.name : ''
    }
}
