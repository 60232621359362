import { PersonActions, PERSON_ACTION } from '../actions/person.action';

const initialState = {
    person: {},
    assigned_admins: [],
    admins: [],
    roles: [],
    userInfo: {}
};

export function personReducer(state = initialState, action: PersonActions) {
    switch (action.type) {
        case PERSON_ACTION.SUCCESS_LOAD_ADMINS_DATA:
            return {
                ...state,
                admins: action.payload
            };
        case PERSON_ACTION.SUCCESS_LOAD_ROLES_DATA:
            return {
                ...state,
                roles: action.payload
            };
        case PERSON_ACTION.SUCCESS_LOAD_PERSON:
            return {
                ...state,
                person: action.payload.person,
                assigned_admins: action.payload.assigned_admins,
            };

        case PERSON_ACTION.SUCCESS_LOAD_USER_INFO:
            return {
                ...state,
                userInfo: action.payload
            }
        default:
            return state;
    }
}
