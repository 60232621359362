import { CompanyService } from './../../../shared/services/company.service';
import { ReferenceModel } from '../../../models/reference.model';
import { DeletionPersonComponent } from './deletion-person/deletion-person.component';
import {
    Component, EventEmitter,
    Input, OnChanges,
    OnInit, Output, SimpleChanges, HostListener, ElementRef, ViewChild,
} from '@angular/core';
import { AssignedTeamModel } from '../../../models/assigned-team.model';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { AssignPersonComponent } from './assign-person/assign-person.component';
import { PermissionsService } from 'src/app/shared/services/permissions.service';

@Component({
    selector: 'app-team-widget',
    templateUrl: './team-widget.component.html',
    styleUrls: ['./team-widget.component.scss'],
})
export class TeamWidgetComponent implements OnInit, OnChanges {

    @Input() public assignedTeam: AssignedTeamModel[];
    @Input() public roles: ReferenceModel[];
    @Input() public companyId: number;
    @Input() public allAdmins: any[];
    @Input() public rootAvatar: string;

    @Input() isLoading: boolean;

    @Output() assignAccess: EventEmitter<any> = new EventEmitter<any>();

    public filteredRoles: any[];
    public filteredTeam: any[];

    public isShow: boolean;
    public isShowMember = false;

    public isDisableSaveButton = true;

    public team: any[] = [];

    public arr: any[] = [];
    public form: FormGroup;

    public isFoundUser = false;
    public isFoundRole = false;

    isAssignToCompany: any;

    constructor(
        private _fb: FormBuilder,
        private companyService: CompanyService,
        public dialog: MatDialog,
        public ps: PermissionsService,
    ) {
    }

    ngOnInit() {
        this.form = this._fb.group({
            teamRows: this._fb.array([this.initTeamRows('')], [Validators.minLength(3)]),
        });
        this._fb.group({
            roleRows: this._fb.array([this.initRoleRows('')], [Validators.minLength(3)]),
        });
    }

    get formTeamArr() {
        return this.form.get('teamRows') as FormArray;
    }

    get formRoleArr() {
        return this.form.get('roleRows') as FormArray;
    }

    initTeamRows(admin: any) {
        return this._fb.group({
            full_name: [admin]
        });
    }

    initRoleRows(role: any) {
        return this._fb.group({
            role: [role]
        });
    }

    addNewRow() {
        this.dialog.open(AssignPersonComponent, {
            width: '496px',
            height: 'auto',
            autoFocus: false,
            data: {
                admins: this.allAdmins,
                avatar: this.rootAvatar,
                roles: this.roles,
            },
            panelClass: 'assign-modal'
        }).afterClosed()
            .subscribe((data: any) => {
                if (data) {
                    this.isShow = true;
                    this.isDisableSaveButton = false;
                    this.formTeamArr.push(this.initTeamRows(data.admin));
                    this.formRoleArr.push(this.initRoleRows(data.role));
                }
            });
    }

    setRowsTeam(data): FormArray {
        const formArray = new FormArray([]);

        data.forEach(item => {
            formArray.push(this._fb.group({
                full_name: item.full_name,
                avatar: item.avatar,
            }));
        });

        return formArray;
    }

    setRowsRole(data): FormArray {
        const formArray = new FormArray([]);

        data.forEach(item => {
            formArray.push(
                this._fb.group({
                    role: item.role
                })
            );
        });

        return formArray;
    }

    removeControl(index, itemTeam) {

        this.dialog.open(DeletionPersonComponent, {
            width: '447px',
            height: 'auto',
            autoFocus: false,
            data: {
                index,
                itemTeam,
                formTeamArr: this.formTeamArr,
                formRoleArr: this.formRoleArr
            },
            panelClass: 'remove-assign-modal'
        });
    }

    ngOnChanges(changes: SimpleChanges): void {

        this.filterTeam();
        this.filterRoles();

        if (this.form) this.setControls();
    }

    setControls() {
        this.form.setControl('teamRows', this.setRowsTeam(this.assignedTeam));
        this.form.setControl('roleRows', this.setRowsRole(this.assignedTeam));
    }

    filterTeam(query?: string) {
        this.filteredTeam = query
            ? this.allAdmins.filter(team => team.full_name.toLowerCase().includes(query.toLowerCase()))
            : this.allAdmins;
    }

    filterRoles(query?: string) {
        this.filteredRoles = query ?
            this.roles.filter((role: any) => role.name
                .toLowerCase()
                .includes(
                    query.toLowerCase())) :
            this.roles;
    }

    edit() {
        this.form.value.teamRows.forEach(team => {
            this.allAdmins.forEach(admin => {
                if (team.full_name === admin.full_name) {
                    team.admin_id = admin.id;
                    team.avatar = admin.avatar;
                }
            });
        });

        this.form.value.roleRows.forEach(role => {
            this.roles.forEach((item: any) => {
                if (role.role === item.name) {
                    role.role_id = item.id;
                }
            });
        });

        this.arr = this.form.value.teamRows.map((teamRow, index) => ({
            ...teamRow,
            ...this.form.value.roleRows[index]
        }));

        this.isFoundUser = this._checkContains(this.allAdmins, this.arr, 'full_name');
        this.isFoundRole = this._checkContains(this.roles, this.arr, 'role', 'name');

        if (this.isFoundUser && this.isFoundRole) {
            this._editTeam(this.arr);
            this.isShow = false;
        }
    }

    changeStateOfSaveButton() {
        this.isDisableSaveButton = false;
    }

    hideEditableTeam(event?: any) {

        if (event && event.target.innerText === 'Cancel') {

            this.isShow = false;
            this.isDisableSaveButton = true;

            this.setControls();

            while (
                this.formTeamArr.length !== 0 &&
                this.formRoleArr.length !== 0 &&
                this.assignedTeam.length === 0
            ) {
                this.formTeamArr.removeAt(0);
                this.formRoleArr.removeAt(0);
            }
        }
    }

    private _editTeam(data) {
        this.isLoading = true;
        this.companyService.editTeam(this.companyId, data)
            .subscribe(
                () => {
                    this.isLoading = false;
                    this.isDisableSaveButton = true;
                    this.assignedTeam = data;
                },
                () => {
                    this.isLoading = false;
                }
            );
    }

    private _checkContains(arrData: any[], arr: any[], typeOfInput: string, roleName?: string) {
        return arr.every(item => {
            return arrData.some(item2 => item2[roleName ? roleName : typeOfInput] === item[typeOfInput]);
        });
    }
}
