import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ErrorService {

    isNotEmptyData = true;
    isLoadingCharts = true;

    isLoadingCompany = false;
    isLoadingGoals = false;
    isLoadingTeam = false;
    isLoadingContacts = false;
    isLoadingNotes = false;
    isLoadingProjects = false;
    isLoadingTasks = false;
    isLoadingAttachments = false;

    constructor() { }

}
