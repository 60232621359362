import { Component, Input, SimpleChanges, OnChanges } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { AddAttachmentComponent } from './add-attachment/add-attachment.component';
import { CountryItem } from 'src/app/models/reference.model';
import { AttachmentsModel } from 'src/app/models/attachments.model';
import { CompanyService } from 'src/app/shared/services/company.service';
import { switchMap, catchError, tap } from 'rxjs/operators';
import { GetAttachmentsRepsponse } from 'src/app/shared/responses/get-attachments.response';
import { of } from 'rxjs';

@Component({
    selector: 'app-atachments-widget',
    templateUrl: './atachments-widget.component.html',
    styleUrls: ['./atachments-widget.component.scss']
})
export class AtachmentsWidgetComponent {

    @Input() allAdmins: any[];
    @Input() countries: CountryItem[];
    @Input() attachments: AttachmentsModel;
    @Input() isLoading: boolean;
    @Input() id: any;

    @Input() documentTypes: any;
    @Input() documentStatuses: any;
    @Input() documentSignatureStatuses: any;
    @Input() entities: any[];

    constructor(
        private dialog: MatDialog,
        private companyService: CompanyService,
        private snackBar: MatSnackBar
    ) {
    }

    onOpen() {
        this.dialog.open(AddAttachmentComponent, {
            autoFocus: false,
            width: '496px',
            data: {
                admins: this.allAdmins,
                countries: this.countries,
                fields: {
                    types: this.documentTypes,
                    statuses: this.documentStatuses,
                    signatureStatuses: this.documentSignatureStatuses,
                    entities: this.entities
                },
            },
            panelClass: 'add-attachment-modal'
        }).afterClosed().subscribe(data => {
            if (data) {
                this.isLoading = true;
                this.companyService.addAttachment(this.id, data)
                    .pipe(
                        tap(
                            () => of(null),
                            () => {
                                this.snackBar.open('File wasn\'t uploaded', null, {
                                    duration: 2000
                                });
                            }
                        ),
                        switchMap(() => this.companyService.getAttachments(this.id)),
                    ).subscribe(
                        (res: AttachmentsModel) => {
                            this.attachments = res;
                            new GetAttachmentsRepsponse(this.attachments).serializeAttachments();
                            this.isLoading = false;
                            localStorage.removeItem('attachments');
                        },
                        () => {
                            this.isLoading = false;
                        }
                    );
            }
        });
    }

    // editAttachment(document, edit) {
    //     this.onOpen(document, edit);
    // }
}
