import { FormControl, FormBuilder, FormArray, Validators } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { Component, OnInit, Input, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
    selector: 'app-add-employee',
    templateUrl: './add-employee.component.html',
    styleUrls: ['./add-employee.component.scss']
})
export class AddEmployeeComponent implements OnInit {

    public fileName: any;
    public imgUrl: any;
    public icon: any;
    public isShowMore = false;

    public editor = ClassicEditor;

    public config = {
        toolbar: ['bold', 'italic', 'bulletedList', 'numberedList', 'blockQuote']
    };

    public form: FormGroup;

    constructor(
        @Inject(MAT_DIALOG_DATA) public id: any,
        public dialogRef: MatDialogRef<AddEmployeeComponent>,
        private _fb: FormBuilder,
    ) { }

    ngOnInit() {
        this.form = this._fb.group({
            name_person: new FormControl('', [Validators.required]),
            last_name_person: new FormControl('', [Validators.required]),
            title_person: new FormControl('', [Validators.required]),
            email_person: new FormControl(''),
            wechat_person: new FormControl(''),
            skype_person: new FormControl(''),
            facebook_person: new FormControl(''),
            linkedin_person: new FormControl(''),
            country_person: new FormControl(''),
            city_person: new FormControl(''),
            address_person: new FormControl(''),
            website_person: new FormControl(''),
            zip_code_person: new FormControl(''),
            fax_person: new FormControl(''),
            summary_person: new FormControl(''),
            phonePersonRows: this._fb.array([this.initPhonePersonRows('')]),
        });
    }

    initPhonePersonRows(phone: any) {
        return this._fb.group({
            phone: [phone]
        });
    }

    get formPhonePersonArr() {
        return this.form.get('phonePersonRows') as FormArray;
    }

    addNewPhonePersonRow() {
        this.formPhonePersonArr.push(this.initPhonePersonRows(''));
    }

    onSubmit(value: any) {
        this.dialogRef.close({ value, icon: this.icon });
    }

    onClose() {
        this.dialogRef.close();
    }

    onFileChange(event) {

        const reader = new FileReader();

        if (event.target.files && event.target.files.length) {

            this.fileName = event.target.files[0].name;

            const [file] = event.target.files;
            reader.readAsDataURL(file);

            reader.onload = () => {

                if (typeof reader.result === 'string') {

                    this.imgUrl = reader.result;

                    const result = reader.result.split(',')[1];

                    this.icon = {
                        name: this.fileName,
                        content: result
                    };
                }
            };
        }
    }
}
