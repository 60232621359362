import { filter } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ContactsModel } from '../../models/contacts.model';
import { CompanyModel } from '../../models/company.model';
import { PeopleModel } from '../../models/people.model';
import { Methods } from '../../helpers/methods/methods';
import { BaseApi } from '../../helpers/base-api/base-api';
import { CompaniesModel } from '../../models/companies.model';
import { AdminsModel } from '../../models/admins.model';
import { NotesModel } from '../../models/notes.model';
import * as moment from 'moment';

import {
    END_LAST_WEEK,
    REVENUE,
    USERS,
    VOLUME,
    SEVEN_DAYS_AGO,
    CURRENT_DATE,
    DEFAULT_DATE_FROM,
    DEFAULT_DATE_TO,
    START_WEEK,
    START_LAST_WEEK,
    START_LAST_MONTH,
    END_LAST_MONTH,
    START_MONTH,
    START_QUARTER,
    START_LAST_QUARTER,
    END_LAST_QUARTER,
    START_YEAR,
    START_LAST_YEAR,
    END_LAST_YEAR
} from '../const/constants.const';
import { ProjectsModel } from '../../models/projects.model';
import { Page } from 'src/app/models/datatable/page';
import { NoteItem } from 'src/app/models/reference.model';
import { AttachmentsModel } from 'src/app/models/attachments.model';

@Injectable({
    providedIn: 'root'
})
export class CompanyService extends BaseApi {
    public dataForEditTeam: any[] = [];

    public dataForEditMultiplyEmail: any[];
    public dataForEditMultiplySocial: any[];
    public dataForEditMultiplyFax: any[];
    public dataForEditMultiplyPhone: any[];
    public dataForEditMultiplyWebsite: any[];
    public dataForEditMultiplySkype: any[];
    public dataForEditMultiplyWechat: any[];

    public fasterPayArr: any[] = [];
    public t3Arr: any[] = [];

    public fromDate;
    public toDate;

    public intervalNumber: number;
    public intervalType: string;

    public adminsForCreate: any[];
    public contactsForCreateCompany: any[];
    public contactsForCreatePeople: any[];

    increment: number;

    constructor(public http: HttpClient) {
        super(http);
    }

    getCompanies(): Observable<any> {
        return this.get(`/companies`);
    }

    getCompanyById(id): Observable<CompanyModel | any> {
        return this.get(`/companies/${id}`);
    }

    getContactsByCompany(id): Observable<ContactsModel | any> {
        return this.get(
            `/contacts/?search=${Methods.encodeURL({
                ref_id: id,
                ref_type: 'company'
            })}`
        );
    }

    getPeopleByCompany(id): Observable<PeopleModel | any> {
        return this.get(
            `/people/?search=${Methods.encodeURL({
                company_id: id
            })}&page=1&per_page=50`
        );
    }

    getAdmins(): Observable<AdminsModel> {
        return this.get(`/admins?columns=["id", "name", "last_name", "full_name", "avatar", "login"]`);
    }

    getReference(): Observable<any> {
        return this.get(`/reference`);
    }

    getAllNotes(page: Page, value?, event?: any): Observable<NotesModel> {
        this.increment = page.pageNumber + 1;
        return this.get(`/notes?&search=${Methods.encodeURL({
            company_id: value && value.company ? value.company : null,
            person_id: value && value.person ? value.person : null,
            type: value ? value.type : null,
            admin_id: value && value.admin ? value.admin : null,
            date_from: value && value.date_from ? moment(value.date_from).format('L') : null,
            date_to: value && value.date_to ? moment(value.date_to).format('L') : null,
        })}&page=${this.increment}&per_page=${page.size}&with=attachments`);
    }

    getNotes(id, limit?, type?: any, admin?: any): Observable<NotesModel> {
        return this.get(`/notes?&search=${Methods.encodeURL({
            company_id: id,
            type: type ? type : '',
            admin_id: admin && admin === 'clear' ? null : admin,
        })}&page=1&per_page=${limit}&with=attachments`);
    }

    getProjects(id): Observable<ProjectsModel> {
        return this.get(`/applications?search=${Methods.encodeURL({
            company_id: id
        })}`);
    }

    getNoteById(id): Observable<NoteItem> {
        return this.get(`/notes/${id}`);
    }

    getQueue(id: number): Observable<any> {
        return this.get(`/queue/?search=${Methods.encodeURL({
            company_id: id,
            ticket_type: 'task',
        })}&page=1&per_page=50`);
    }

    getAttachments(id: number): Observable<AttachmentsModel> {
        return this.get(`/attachments/?search=${Methods.encodeURL({
            company_id: id
        })}`);
    }

    addNote(companyId: number, value): Observable<any> {
        return this.post('/notes', {
            data: {
                ref_type: value.application ? 'application' : 'company',
                ref_id: value.application ? value.application : companyId,
                type: value.note_type ? value.note_type : 'note',
                subject: value.note_subject ? value.note_subject : 'Initial note',
                body: value.note_body
            },
            extra_data: {
                files: value.files
            }
        });
    }

    addAttachment(companyId: any, data: any): Observable<{ attachment_id: string }> {

        const formData: FormData = new FormData();

        formData.append('data[ref_id]', companyId);
        formData.append('data[ref_type]', 'company');
        formData.append('data[type]', 'company');
        formData.append('data[doc_type_id]', data.value.document_type);
        formData.append('files_company[]', data.value.file_name);
        formData.append('data[name]', '');
        formData.append('id', '0');
        formData.append('data[doc_status]', data.value.document_status);
        formData.append('data[doc_signature_status]', data.value.signature_status);
        formData.append('data[admin_id_responsible]', data.value.responsible.toString());
        formData.append('data[dt_agreement_start]', data.value.effective_date ? moment(data.value.effective_date).format('L') : '');
        formData.append('data[dt_agreement_expiration]', data.value.expiration_date ? moment(data.value.expiration_date).format('L') : '');
        formData.append('data[business_entity_id]', data.value.entity.toString());
        formData.append('data[signer_name]', data.value.first_name);
        formData.append('data[signer_lastname]', data.value.last_name);
        formData.append('data[position]', data.value.signer_title);
        formData.append('data[company_name]', data.value.company);
        formData.append('data[co_id]', data.value.country.toString());
        formData.append('data[state_name]', data.value.state);
        formData.append('data[city_name]', data.value.city);
        formData.append('data[address]', data.value.street);
        formData.append('data[zip_code]', data.value.zip_code);
        formData.append('data[note]', data.value.note);

        return this.post('/attachments', formData);

    }

    addSource(name: string): Observable<any> {
        return this.post('/sources', {
            data: { name }
        });
    }

    addCompany(value: any, icon?: any): Observable<any> {

        this.adminsForCreate = [];
        this.contactsForCreateCompany = [];

        if (value.nameRows) {
            this.adminsForCreate = value.nameRows.map((nameRow, index) => ({
                ...nameRow,
                ...value.roleRows[index]
            }));
        }

        if (value.phoneCompanyRows) {
            this.contactsForCreateCompany = value.phoneCompanyRows.map(item => {
                return {
                    value: item.phone,
                    type: 'phone'
                };
            });
        }

        this.contactsForCreateCompany.push(
            { value: value.website, type: 'website' },
            { value: value.email, type: 'email' },
            { value: value.country, type: 'country' },
            { value: value.city, type: 'city' },
            { value: value.address, type: 'address' },
            { value: value.zip_code, type: 'zip_code' },
            { value: value.fax, type: 'fax' },
            { value: value.skype, type: 'skype' },
            { value: value.wechat, type: 'wechat' },
            { value: value.facebook, type: 'facebook' },
            { value: value.linkedin, type: 'linkedin' },
        );

        return this.post('/companies', {
            data: {
                name: value.name,
                type: value.type,
                stage: value.type === 1 ? value.stage : undefined,
                source: value.source,
                subtype: value.industry,
                needs: value.needs,
                goals: value.goals,
                description: value.summary,
                bounty: value.bounty,
                contacts: this.contactsForCreateCompany,
                assigned_admins: this.adminsForCreate,
                icon
            }
        });
    }

    addPeople(value: any, id?: any, icon?: any) {

        this.contactsForCreatePeople = [];
        this.adminsForCreate = [];

        if (value.nameRows) {
            this.adminsForCreate = value.nameRows.map((nameRow, index) => ({
                ...nameRow,
                ...value.roleRows[index]
            }));
        }

        if (value.phonePersonRows) {
            this.contactsForCreatePeople = value.phonePersonRows.map(item => {
                return {
                    value: item.phone,
                    type: 'phone'
                };
            });
        } else {
            this.contactsForCreatePeople.push({ value: value.phone_person, type: 'phone' });
        }

        this.contactsForCreatePeople.push(
            { value: value.website_person ? value.website_person : '', type: 'website' },
            { value: value.email_person ? value.email_person : '', type: 'email' },
            { value: value.country_person ? value.country_person : '', type: 'country' },
            { value: value.city_person ? value.city_person : '', type: 'city' },
            { value: value.address_person ? value.address_person : '', type: 'address' },
            { value: value.zip_code_person ? value.zip_code_person : '', type: 'zip_code' },
            { value: value.fax_person ? value.fax_person : '', type: 'fax' },
            { value: value.skype_person ? value.skype_person : '', type: 'skype' },
            { value: value.wechat_person ? value.wechat_person : '', type: 'wechat' },
            { value: value.facebook_person ? value.facebook_person : '', type: 'facebook' },
            { value: value.linkedin_person ? value.linkedin_person : '', type: 'linkedin' },
        );

        return this.post('/people', {
            data: {
                company_id: id ? id : null,
                type: value.type_person,
                name: value.name_person,
                last_name: value.last_name_person,
                title: value.title_person,
                summary: value.summary_person,
                subtype: value.status_person,
                contacts: this.contactsForCreatePeople,
                referral_admin_id: value.refferal_person,
                office_id: value.office_person,
                desire_compensation: value.compensation,
                desire_compensation_currency_id: value.currency,
                datetime_interviewed: value.datetime,
                relocation_term_options: {
                    insurance: {
                        on: value.insurance_terms ? 1 : 0,
                        text: value.insurance_terms
                    },
                    accommodation: {
                        on: value.accommodation_terms ? 1 : 0,
                        text: value.accommodation_terms
                    },
                    allowance: {
                        on: value.allowance_terms ? 1 : 0,
                        text: value.allowance_terms
                    },
                    fly: {
                        on: value.fly_terms ? 1 : 0,
                        text: value.fly_terms
                    }
                },
                desire_positions: value.positions_person,
                assigned_admins: this.adminsForCreate,
                icon
            }
        });

    }

    addContractualInfo(payload: any) {
        return this.post(`/company-contractual-info`, payload);
    }

    editContractualInfo(payload: any, id: number) {
        return this.put(`/company-contractual-info/${id}`, payload);
    }

    editStage(companyId: number, stageId: number): Observable<any> {
        return this.put(`/companies/${companyId}`, {
            data: {
                stage: stageId
            }
        });
    }

    editCompany(id, value: any): Observable<any> {

        if (value.fasterPayMids || value.terminal3Mids) {

            this.fasterPayArr = [];
            this.t3Arr = [];

            this.serializeMids(value.fasterPayMids, this.fasterPayArr);
            this.serializeMids(value.terminal3Mids, this.t3Arr);

        }

        return this.put(`/companies/${id}`,
            {
                data: {
                    name: value.company_name,
                    type: value.type,
                    stage: value.stage,
                    source: value.source,
                    reputation: value.reputation,
                    subtype: value.subtype,
                    english_name: value.english_name,
                    local_name: value.local_name,
                    description: value.summary,
                    goals: value.goals,
                    needs: value.needs,
                    bounty: value.bounty,
                    merchant_ids: {
                        fasterpay: this.fasterPayArr,
                        terminal3: this.t3Arr,
                    }
                }

            });
    }

    editStrategy(id, strategy: string): Observable<any> {
        return this.put(`/companies/${id}`,
            {
                data: { strategy }
            });
    }

    deleteContacts(id): Observable<any> {
        return this.delete(`/contacts/${id}`);
    }

    editContacts(id, value): Observable<any> {

        this.dataForEditMultiplyEmail = [];
        this.dataForEditMultiplyPhone = [];
        this.dataForEditMultiplyWebsite = [];
        this.dataForEditMultiplyFax = [];
        this.dataForEditMultiplySocial = [];
        this.dataForEditMultiplySkype = [];
        this.dataForEditMultiplyWechat = [];

        this.getPointsOfContacts(value, 'email_items', this.dataForEditMultiplyEmail, id, 'email');
        this.getPointsOfContacts(value, 'social_items', this.dataForEditMultiplySocial, id, 'social');
        this.getPointsOfContacts(value, 'fax_items', this.dataForEditMultiplyFax, id, 'fax');
        this.getPointsOfContacts(value, 'phone_items', this.dataForEditMultiplyPhone, id, 'phone');
        this.getPointsOfContacts(value, 'website_items', this.dataForEditMultiplyWebsite, id, 'website');
        this.getPointsOfContacts(value, 'skype_items', this.dataForEditMultiplySkype, id, 'skype');
        this.getPointsOfContacts(value, 'wechat_items', this.dataForEditMultiplyWechat, id, 'wechat');

        return this.put(`/contacts/${id}`,
            {
                data: {
                    email: this.dataForEditMultiplyEmail,
                    phone: this.dataForEditMultiplyPhone,
                    website: this.dataForEditMultiplyWebsite,
                    fax: this.dataForEditMultiplyFax,
                    social: this.dataForEditMultiplySocial,
                    address: [
                        {
                            id: value.address_items[0].id,
                            value: value.address_items[0].point,
                            red_id: id,
                            ref_type: 'company',
                            type: 'address',
                        }
                    ],
                    zip_code: [
                        {
                            id: value.zip_code_items[0].id,
                            value: value.zip_code_items[0].point,
                            red_id: id,
                            ref_type: 'company',
                            type: 'zip_code',
                        }
                    ],
                    po_box: [
                        {
                            id: value.post_box_items[0].id,
                            value: value.post_box_items[0].point,
                            red_id: id,
                            ref_type: 'company',
                            type: 'po_box',
                        }
                    ],
                    city: [
                        {
                            id: value.city_items[0].id,
                            value: value.city_items[0].point,
                            red_id: id,
                            ref_type: 'company',
                            type: 'city',
                        }
                    ],
                    country: [
                        {
                            id: value.country_items[0].id,
                            value: value.country_items[0].point,
                            ref_id: id,
                            ref_type: 'company',
                            type: 'country',
                        }
                    ],
                    skype: this.dataForEditMultiplySkype,
                    wechat: this.dataForEditMultiplyWechat,
                    facebook_link: [
                        {
                            id: value.facebook_items[0].id,
                            value: value.facebook_items[0].point,
                            ref_id: id,
                            ref_type: 'company',
                            type: 'facebook',
                        }
                    ],
                    linkedin_link: [
                        {
                            id: value.linkedin_items[0].id,
                            value: value.linkedin_items[0].point,
                            ref_id: id,
                            ref_type: 'company',
                            type: 'linkedin',
                        }
                    ],
                },
                ref_id: id,
                ref_type: 'company',
            });
    }

    editTeam(id, value: []): Observable<any> {

        this.dataForEditTeam = [];

        value.forEach((item: any) => {
            this.dataForEditTeam.push({
                id: 0,
                company_id: id,
                admin_id: item.admin_id,
                role_id: item.role_id
            });
        });

        return this.put(`/companies/${id}`, {
            data: {
                assigned_admins: this.dataForEditTeam
            }
        });
    }

    searchByCompany(value, counter: number = 1): Observable<CompaniesModel> {

        let obj: any;

        if (value.assigned_to) {
            obj = {
                assigned_to: value.assigned_to,
                per_page: 500,
                reputation: [0, 1]
            };
        } else {
            obj = {
                keyword: value,
                reputation: [0, 1]
            };
        }

        return this.get(`/companies?page=${counter}&search=${
            Methods.encodeURL(
                obj
            )}`
        );
    }

    filterByCompanies(counter?: number, value?: any, perPage?: number): Observable<any> {

        const obj = {
            keyword: value ? value.name : null,
            city: value ? value.city : null,
            source_ids: value && value.source !== '' && value.source !== null ? [value.source] : null,
            reputation: value && value.reputation !== '' && value.reputation != null ? value.reputation : [0, 1],
            email: value ? value.email : null,
            website: value ? value.website : null,
            assigned_to: value ? value.assigned_to : null,
            created_by: value ? value.created_by : null,
            stage: value ? value.stage : null,
            type: value ? value.type : null,
            subtype: value ? value.subtype : null,
            co_id: value ? value.country : null,
            priority: value ? value.priority : null,
            per_page: perPage,
            date_from: value && value.dateFrom !== null && value.dateFrom !== '' ? moment(value.dateFrom).format('L') : null,
            date_to: value && value.dateTo !== null && value.dateFrom !== '' ? moment(value.dateTo).format('L') : null,
        };

        for (const key in obj) {
            if (obj[key] === '' || obj[key] === null) {
                delete obj[key];
            }
        }

        return this.get(`/companies?page=${counter}&search=${
            Methods.encodeURL(value && value.per_page ? {
                per_page: value.per_page,
                reputation: [0, 1]
            } : obj)}`);
    }

    private getPointsOfContacts(value, items, array, id, type) {
        return value[items].forEach(item => {
            return array.push({
                id: item.id,
                ref_id: id,
                ref_type: 'company',
                type,
                value: item.point
            });
        });
    }

    private serializeMids(mid: any, array: any[]) {
        mid.forEach((item: any) => {
            array.push(item.id);
        });
    }
}
