import { ReferenceActions, REFERENCE_ACTION } from '../actions/reference.action';

const initialState = {
    reference: {
        reputations: [],
        stages: [],
        company_types: [],
        company_subtypes: [],
        countries: [],
        sources: [],
        note_types: [],
        admin_roles: [],
        logged_in_admin: {}
    },
};

export function referenceReducer(state = initialState, action: ReferenceActions) {
    switch (action.type) {
        case REFERENCE_ACTION.SUCCESS_LOAD_REFERENCE:
            return {
                ...state.reference,
                company_subtypes: action.payload.company_subtypes,
                company_types: action.payload.company_types,
                admin_roles: action.payload.admin_roles,
                reputations: action.payload.reputations,
                stages: action.payload.stages,
                sources: action.payload.sources,
                note_types: action.payload.note_types,
                countries: action.payload.countries,
                logged_in_admin: action.payload.logged_in_admin.id
            };
        default:
            return state;
    }
}
