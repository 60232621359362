import { Action } from '@ngrx/store';

export namespace REFERENCE_ACTION {
    export const SUCCESS_LOAD_REFERENCE = 'SUCCESS_LOAD_REFERENCE';
}

export class SuccessLoadReference implements Action {

    readonly type = REFERENCE_ACTION.SUCCESS_LOAD_REFERENCE;

    constructor(public payload: any) { }
}

export type ReferenceActions = SuccessLoadReference;
