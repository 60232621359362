export const environment = {
    production: true,
    BASE_DOMAIN: 'http://feature-armstrong.wallapi.bamboo.stuffio.com',
    // Make sure this branch same as name with current feature branch to prevent SameSite cookie blocked
    BASE_API_URL: '/devrise-api',
    BASE_ADMIN_LOGIN_URL: '/admin/auth/external-auth-token',
    BASE_URL: 'http://feature-armstrong.armstrong.s3.stuffio.com',
    CRM_API_URL: 'http://feature-armstrong.armstrong-tickets.bamboo.stuffio.com/api',
    COMPANIES_URL: '/admin/devrise',
    ACTIVITY_URL: '/admin/devrise/latest-correspondence',
    PEOPLE_URL: '/admin/devrise/people',
    PORTFOLIO_URL: '/admin/devrise/my-deals',
    AM_PORTFOLIO_URL: '/admin/devrise/am-portfolio',
    CUSTOM_LISTS: '/admin/devrise/lists',
    SOCIAL_WALL: '/admin/socialwall',
    QUEUE_MESSAGES: '/admin/devrise/queue-messages',
    QUEUE: '/admin/devrise/queue',
    MERGE: '/admin/devrise/merge-companies',
    MERCHANTS_FASTERPAY: 'https://admin.fasterpay.com/dashboard/merchants',
    MERCHANTS_PAYMENTWALL: '/admin/developers',
    MERCHANTS_TERMINAL3: 'https://admin.terminal3.com/',

    OLD_PAGE_URL: '/admin/devrise/company-view',
    OLD_PERSON_PAGE_URL: '/admin/devrise/person-view'
};
