import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { CRM_OAUTH_URL } from '../const/constants.const';

@Injectable({
  providedIn: 'root'
})
export class AuthTicketService {

  accessToken = new BehaviorSubject<string>(null);

  constructor(public http: HttpClient) {  }

  getAccessToken(authToken): Observable<any> {
    return this.http.post(CRM_OAUTH_URL, null, {
      headers: { 'X-Wallapi-Auth-Token': authToken }
    });
  }

  get jwtAccessToken() {
    return localStorage.getItem('access_token');
  }
}
