import { Component, OnInit, Input, ViewChild, TemplateRef } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { IUserInfo } from 'src/app/models/person.model';
import { AppState } from 'src/app/redux/app.state';
import { Ticket, TicketMessage } from '../../../../../../models/ticket.model';
import { AuthTicketService } from '../../../../../../shared/services/auth-ticket.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { TicketService } from '../../../../../../shared/services/ticket.service';
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-conversation-message',
    templateUrl: './conversation-message.component.html',
    styleUrls: ['./conversation-message.component.scss']
})
export class ConversationMessageComponent implements OnInit {
    @ViewChild('editMessageDialog', {
        static: false
    }) public editMessageDialogRef: TemplateRef<any>;

    @Input() message: TicketMessage;
    @Input() ticket: Ticket;

    authenticated: IUserInfo;

   public editMessage: string;

    constructor(
        private ticketService: TicketService,
        public modal: NzModalService,
        private store: Store<AppState>,
        public auth: AuthTicketService
    ) {
        this.store
            .pipe(select('person'))
            .subscribe(({ userInfo }) => this.authenticated = userInfo);
    }

    get outgoingMessage() {
        return this.message.sender_type === 'admin';
    }

    get senderName() {
        if (this.isSystemMessage) {
            return 'System';
        }

        if (this.isOwnOfMessage) {
            return 'You';
        }

        return this.message.sender_name;
    }

    get messageDeleted() {
        return this.message.delivery_status === 3;
    }

    get isMessageSent() {
        return [1, 2].includes(this.message.delivery_status);
    }

    get isOwnOfMessage() {
        return this.authenticated.admin_id === this.message.sender_id && this.message.sender_type === 'admin';
    }

    get isSystemMessage() {
        return this.message.sender_id === 0 && this.message.sender_type === 'admin';
    }

    get canPerformActions() {
        if (this.messageDeleted) {
            return false;
        }
        return this.isOwnOfMessage || this.isSystemMessage;
    }

    get getDisableDeleteMessageReason() {
        if (!this.isOctopusTicket) {
            return this.isMessageSent ? 'This message cannot be deleted as an email showing this message has been sent.' : '';
        }
    }

    get disableEditMessageReason() {
        if (!this.isOctopusTicket) {
            return 'This message cannot be edited. Consider deleting if that is still possible';
        }
    }

    get canEditMessage() {
        return Boolean(this.editMessage);
    }

    get isOctopusTicket() {
        return this.ticket.source === 'octopus';
    }

    editTicketMessage(): void {
        if (this.disableEditMessageReason) {
            return;
        }
        this.editMessage = this.message.message;
        this.modal.create({
            nzTitle: 'Edit ticket message',
            nzContent: this.editMessageDialogRef,
            nzOkText: 'Save Change',
            nzOkDisabled: !this.canEditMessage,
            nzOnOk: () => new Promise((resolve, reject) => {
                if (!this.editMessage) {
                    return reject();
                }
                this.ticketService.updateTicketMessage(this.message.message_id, {
                    message: this.editMessage
                }).pipe(
                    map(message => (this.message.message = message.message))
                ).subscribe(() => resolve(), () => reject());
            })
        });
    }

    deleteTicketMessage(): void {
        if (this.getDisableDeleteMessageReason) {
            return;
        }
        this.modal.confirm({
            nzTitle: 'Delete ticket message',
            nzContent: 'Are you sure you want to delete this message?',
            nzOnOk: () => new Promise((resolve, reject) => {
                this.ticketService.deleteTicketMessage(this.message.message_id).pipe(
                    map(message => this.message = message)
                ).subscribe(() => resolve(), () => reject());
            })
        });
    }

    ngOnInit() {}
}
