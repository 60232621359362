import { CompanyService } from './../../../shared/services/company.service';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { switchMap } from 'rxjs/operators';
import { GetCompanyByIdResponse } from 'src/app/shared/responses/get-company-by-id.response';
import { PermissionsService } from 'src/app/shared/services/permissions.service';

@Component({
    selector: 'app-goals-widget',
    templateUrl: './goals-widget.component.html',
    styleUrls: ['./goals-widget.component.scss']
})
export class GoalsWidgetComponent implements OnInit, OnChanges {

    @Input() public company: any;
    @Input() public id: number;
    @Input() public isLoading: boolean;

    public form: FormGroup;

    public isShowSummary = false;
    public isShowGoals = false;
    public isShowBounties = false;
    public isShowNeeds = false;
    public isShow: boolean;

    public isDisableSaveButton = true;

    public editor = ClassicEditor;

    public model = {
        summary: '',
        goals: '',
        bounty: '',
        needs: '',
    };

    public config = {
        toolbar: ['bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote']
    };

    constructor(
        private companyService: CompanyService,
        public ps: PermissionsService,
    ) {
    }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.setControls();
    }

    setControls() {
        this.model.summary = this.company ? this.company.description : '';
        this.model.goals = this.company ? this.company.goals : '';
        this.model.bounty = this.company ? this.company.bounty : '';
        this.model.needs = this.company ? this.company.needs : '';
    }

    changeStateOfSaveButton() {
        this.isDisableSaveButton = false;
    }

    onCancel(event?) {
        if (event.target.innerText === 'Cancel') {
            this._hideEditor();
            this.setControls();
            this.isDisableSaveButton = true;
        }
    }

    onSubmit() {
        this._hideEditor();
        this._editGoals(this.model);
    }

    private _editGoals(data) {
        this.isLoading = true;
        this.companyService.editCompany(this.id, data)
            .pipe(
                switchMap(() => this.companyService.getCompanyById(this.id))
            )
            .subscribe((res) => {
                this.company = new GetCompanyByIdResponse(res).getCompanyData();
                this.isLoading = false;
                this.isDisableSaveButton = true;
            });
    }

    private _hideEditor() {
        this.isShow = false;
        this.isShowGoals = false;
        this.isShowNeeds = false;
        this.isShowBounties = false;
        this.isShowSummary = false;
    }
}
