import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { BASE_ADMIN_LOGIN_URL, BASE_URL, CRM_OAUTH_URL } from './shared/const/constants.const';
import { AuthTicketService } from './shared/services/auth-ticket.service';
import { take, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthTicketGuard implements CanActivate {
  constructor(
    public router: Router,
    public auth: AuthTicketService
  ) {
      //
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (!!localStorage.getItem('access_token')) {
      return true;
    } else {
      return this.auth.accessToken.pipe(take(1), map(token => {
        console.log(`subject is ${token}`);
        if (!!token) {
          return this.router.createUrlTree(['/tickets']);
        } else {
          const query = new URLSearchParams({
            domain: 'armstrong',
            referer: `${BASE_URL}/`,
            refererRedirect: CRM_OAUTH_URL
          }).toString();

          console.log('guard activated');
          window.location.href = `${BASE_ADMIN_LOGIN_URL}/?${query}`;

          return false;
        }
      }));

    }
  }
}
