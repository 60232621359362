import { AttachmentsModel } from 'src/app/models/attachments.model';
import * as moment from 'moment';

export class GetAttachmentsRepsponse {
    readonly res: AttachmentsModel;

    constructor(res: AttachmentsModel) {
        this.res = res;
    }

    serializeAttachments(): any {
        this.res.attachments.list.forEach((item: any) => {

            item.dt_created = moment.unix(item.dt_created).format('L');

            const format = item.name.slice(-3);
            const formatJpeg = item.name.slice(-4);

            if (format === 'jpg' || format === 'png') {
                item.format = format;
            } else if (formatJpeg === 'jpeg') {
                item.format = item.name.slice(-4);
            } else {
                item.format = format;
            }

        });
    }
}
