import { findIndex } from 'lodash';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { NzModalService } from 'ng-zorro-antd/modal';
import { BootstrappedAdmin } from 'src/app/models/admins.model';
import { Ticket } from 'src/app/models/ticket.model';
import { ViewportScroller } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { CreateTaskDialogComponent } from './create-task-dialog/create-task-dialog.component';
import { TicketService } from '../../../../../shared/services/ticket.service';

@Component({
    selector: 'app-ticket-tasks',
    templateUrl: './ticket-tasks.component.html',
    styleUrls: ['./ticket-tasks.component.scss']
})
export class TicketTasksComponent implements OnInit {
    @Input() public assignees: BootstrappedAdmin[];
    @Input() public ticketId: any;

    public ticketTaskFragment: string = undefined;
    public fetchingTasks: boolean;
    public tasks: Ticket[] = [];
    // public activeTasks: Ticket[] = [];
    // public completedTask: Ticket[] = [];

    constructor(
        public route: ActivatedRoute,
        public viewport: ViewportScroller,
        public ticketService: TicketService,
        public dialog: MatDialog,
        private modal: NzModalService) {}

    ngOnInit() {
        this.route.fragment.subscribe((fragment: string) => {
            this.ticketTaskFragment = fragment;
        });
        this.fetchTasks();
    }

    get activeTasks() {
        return this.tasks.filter(task => task.status !== 'resolved');
    }

    get completeTasks() {
        return this.tasks.filter(task => task.status === 'resolved');
    }

    fetchTasks() {
        this.fetchingTasks = true;
        this.ticketService.getTickets({
            type: 'task',
            with: ['assignee'],
            per_page: 9999,
            transferred_from_id: this.ticketId,
        }).subscribe(response  => {
            this.tasks = response.data;
            this.fetchingTasks = false;
            setTimeout(() => {
                this.viewport.scrollToAnchor(this.ticketTaskFragment);
            }, 500);
        });
    }

    onTaskChanged(task: Ticket): void {
        const index = findIndex(this.tasks, { ticket_id: task.ticket_id });
        if (index >= 0) {
            this.tasks[index] = task;
        } else {
            this.tasks.push(task);
        }
    }

    onEditingTask(task: Ticket): void {
        this.modal.create({
            nzTitle: 'Update task',
            nzContent: CreateTaskDialogComponent,
            nzComponentParams: {
                ticket: task,
                assignees: this.assignees,
                ticketId: task.ticket_id
            },
            nzFooter: null
        }).afterClose.subscribe((response: { ticket?: Ticket }) => {
            if (response && response.ticket) {
                this.onTaskChanged(response.ticket);
            }
        });
    }

    openCreateTaskDialog() {
        this.modal.create({
            nzTitle: 'Create a task',
            nzContent: CreateTaskDialogComponent,
            nzComponentParams: {
                assignees: this.assignees,
                ticketId: this.ticketId
            },
            nzFooter: null
        }).afterClose.subscribe((response: { ticket?: Ticket }) => {
            if (response && response.ticket) {
                this.onTaskChanged(response.ticket);
            }
        });
    }
}
