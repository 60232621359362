import { ErrorService } from './../../../shared/services/error.service';
import { DEFAULT_DATE_TO, DEFAULT_DATE_FROM } from './../../../shared/const/constants.const';
import { ProjectsModel } from '../../../models/projects.model';
import { Validators } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Color, Label } from 'ng2-charts';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { CompanyService } from "../../../shared/services/company.service";
import { GetChartsDataResponse } from "../../../shared/responses/get-charts-data.response";
import { SEVEN_DAYS_AGO } from 'src/app/shared/const/constants.const';
import * as moment from 'moment';

import {
    CURRENT_DATE,
    START_WEEK,
    START_LAST_WEEK,
    END_LAST_WEEK,
    START_MONTH,
    START_LAST_MONTH,
    END_LAST_MONTH,
    START_QUARTER,
    START_LAST_QUARTER,
    END_LAST_QUARTER,
    START_YEAR,
    START_LAST_YEAR,
    END_LAST_YEAR
} from '../../../shared/const/constants.const';

enum Tabs {
    week = 'Week',
    month = 'Month',
    quarter = 'Quarter',
    year = 'Year',
    datepicker = 'Custom'
}

@Component({
    selector: 'app-charts-widget',
    templateUrl: './charts-widget.component.html',
    styleUrls: ['./charts-widget.component.scss']
})
export class ChartsWidgetComponent implements OnInit, OnChanges {
    @Input() disablePagination: boolean;
    @Input() revenue: any[];
    @Input() volume: any[];
    @Input() users: any[];
    @Input() labels: any[];

    @Input() isNotEmptyData: boolean;

    @Input() totalVolume: number;
    @Input() totalRevenue: number;
    @Input() totalUsers: number;

    @Input() projectsList: ProjectsModel;
    @Input() projectsDropDown: any;

    @Input() id;
    @Input() isLoading: boolean;

    public lineChartDataVolume: ChartDataSets[] = [
        { data: [], label: null },
    ];

    public lineChartDataRevenue: ChartDataSets[] = [
        { data: [], label: null },
    ];

    public lineChartDataUsers: ChartDataSets[] = [
        { data: [], label: null },
    ];

    public lineChartLabels: Label[] = [];

    public lineChartColorsVolume: Color[] = [
        {
            backgroundColor: 'rgba(0,123,255,0.1)',
            borderColor: '#007BFF',
            pointBackgroundColor: '#FFFFFF',
            pointHoverBackgroundColor: '#007BFF'
        }
    ];

    public lineChartColorsRevenue: Color[] = [
        {
            backgroundColor: 'rgba(255,65,105,0.05)',
            borderColor: '#FF210C',
            pointBackgroundColor: '#FFFFFF',
            pointHoverBackgroundColor: '#FF210C'
        }
    ];

    public lineChartOptions: ChartOptions = {
        responsive: true,
        legend: {
            display: false,
            labels: {
                fontFamily: '"Proxima Nova", serif',
                fontSize: 14
            }
        },
        tooltips: {
            titleFontFamily: '"Proxima Nova", serif',
            titleFontSize: 12
        }
    };

    public eventDates: string;
    public eventCustomDates: string;

    form: FormGroup;

    from: string;
    to: string;

    isShowShape = false;

    isShowWeek = false;
    isShowMonth = false;
    isShowQuarter = false;
    isShowYear = false;
    isShowDatepicker = false;

    sevenDaysAgo: string;

    startWeek: string;
    endWeek: string;

    startLastWeek: string;
    endLastWeek: string;

    startThisMonth: string;
    startThisYear: string;
    startThisQuarter: string;

    startLastMonth: string;
    endLastMonth: string;

    startLastQuarter: string;
    endLastQuarter: string;

    startLastYear: string;
    endLastYear: string;

    defaultWeekFrom: string;
    defaultWeekTo: string;

    currentDay: string;

    projectsArray: number[] = [];

    constructor(private _companyService: CompanyService, public errorService: ErrorService) { }

    showShape() {
        this.isShowShape = !this.isShowShape;
    }

    lastSevenDays(event) {
        this.from = SEVEN_DAYS_AGO;
        this.to = CURRENT_DATE;
        this.isShowShape = false;
        this.getChartsByDate(event);
    }

    thisWeek(event) {
        this.from = START_WEEK;
        this.to = CURRENT_DATE;
        this.isShowShape = false;
        this.getChartsByDate(event);
    }

    lastWeek(event) {
        this.from = START_LAST_WEEK;
        this.to = END_LAST_WEEK;
        this.isShowShape = false;
        this.getChartsByDate(event);
    }

    lastMonth(event) {
        this.from = START_LAST_MONTH;
        this.to = END_LAST_MONTH;
        this.isShowShape = false;
        this.getChartsByDate(event);
    }

    thisMonth(event) {
        this.from = START_MONTH;
        this.to = CURRENT_DATE;
        this.isShowShape = false;
        this.getChartsByDate(event);
    }

    thisQuarter(event) {
        this.from = START_QUARTER;
        this.to = CURRENT_DATE;
        this.isShowShape = false;
        this.getChartsByDate(event);
    }

    lastQuarter(event) {
        this.from = START_LAST_QUARTER;
        this.to = END_LAST_QUARTER;
        this.isShowShape = false;
        this.getChartsByDate(event);
    }

    thisYear(event) {
        this.from = START_YEAR;
        this.to = CURRENT_DATE;
        this.isShowShape = false;
        this.getChartsByDate(event);
    }

    lastYear(event) {
        this.from = START_LAST_YEAR;
        this.to = END_LAST_YEAR;
        this.isShowShape = false;
        this.getChartsByDate(event);
    }

    customDate(value, event) {

        let dateFrom = moment(value.dateFrom).format('L');
        let dateTo = moment(value.dateTo).format('L');

        this.from = dateFrom;
        this.to = dateTo;

        this.isShowShape = false;

        this.getChartsByDate(event, dateFrom, dateTo);
    }

    updateCharts(res) {
        try {
            this.volume = new GetChartsDataResponse(res).getVolumePoints();
            this.revenue = new GetChartsDataResponse(res).getRevenuePoints();
            this.users = new GetChartsDataResponse(res).getUsersPoints();

            this.labels = new GetChartsDataResponse(res).getLabels();

            this.totalVolume = new GetChartsDataResponse(res).getTotalVolume();
            this.totalRevenue = new GetChartsDataResponse(res).getTotalRevenue();
            this.totalUsers = new GetChartsDataResponse(res).getTotalUsers();

            this.lineChartLabels = this.labels;
            this.lineChartDataRevenue[0].data = this.revenue;
            this.lineChartDataVolume[0].data = this.volume;
            this.lineChartDataUsers[0].data = this.users;

            this.isNotEmptyData = true;
        } catch (error) {
            this.isNotEmptyData = false;
        }
    }

    getChartsByDate(event, dateFrom?, dateTo?) {
        this.eventDates = event.currentTarget.firstChild.data;
        this.eventCustomDates = event.type;
    }

    ngOnInit() {

        this.lineChartDataVolume[0].data = [1];
        this.lineChartDataRevenue[0].data = [1];
        this.lineChartDataUsers[0].data = [1];

        this.form = new FormGroup({
            dateFrom: new FormControl('', [
                Validators.required
            ]),
            dateTo: new FormControl('', [
                Validators.required
            ]),
        });

        this.sevenDaysAgo = SEVEN_DAYS_AGO;
        this.startLastWeek = START_LAST_WEEK;
        this.endLastWeek = END_LAST_WEEK;

        this.startLastMonth = START_LAST_MONTH;
        this.endLastMonth = END_LAST_MONTH;
        this.startThisMonth = START_MONTH;

        this.startThisQuarter = START_QUARTER;
        this.startLastQuarter = START_LAST_QUARTER;
        this.endLastQuarter = END_LAST_QUARTER;

        this.startThisYear = START_YEAR;
        this.startLastYear = START_LAST_YEAR;
        this.endLastYear = END_LAST_YEAR;

        this.startWeek = START_WEEK;
        this.currentDay = CURRENT_DATE;

        this.defaultWeekFrom = DEFAULT_DATE_FROM;
        this.defaultWeekTo = DEFAULT_DATE_TO;

    }

    ngOnChanges(changes: SimpleChanges): void {
        this.lineChartLabels = this.labels;
        this.lineChartDataRevenue[0].data = this.revenue;
        this.lineChartDataVolume[0].data = this.volume;
        this.lineChartDataUsers[0].data = this.users;
    }

    showTabs(event: any) {

        const tabText = event.target.innerText;

        switch (tabText) {
            case Tabs.week:
                this.changeState(tabText);
                break;
            case Tabs.month:
                this.changeState(tabText);
                break;
            case Tabs.quarter:
                this.changeState(tabText);
                break;
            case Tabs.year:
                this.changeState(tabText);
                break;
            case Tabs.datepicker:
                this.changeState(tabText);
                break;
        }
    }

    dataFromMultipleDropDown(data) {
        this.projectsArray = data;
    }

    private changeState(event?: any) {
        this.isShowWeek = (event === Tabs.week) ? true : false;
        this.isShowMonth = (event === Tabs.month) ? true : false;
        this.isShowQuarter = (event === Tabs.quarter) ? true : false;
        this.isShowYear = (event === Tabs.year) ? true : false;
        this.isShowDatepicker = (event === Tabs.datepicker) ? true : false;
    }
}
