import {Component, OnInit, Input, OnChanges, SimpleChanges} from '@angular/core';
import { BootstrappedAdmin } from 'src/app/models/admins.model';
import { FormBuilder, FormGroup } from '@angular/forms';
import {Ticket, TicketCategory, TicketUpdatePayload} from 'src/app/models/ticket.model';
import { tap } from 'rxjs/internal/operators/tap';
import { TicketService } from '../../../../shared/services/ticket.service';
import { NzMessageService } from 'ng-zorro-antd';
import { NzModalService } from 'ng-zorro-antd/modal';
import { map } from 'rxjs/operators';
import { pick } from 'lodash';

@Component({
    selector: 'app-ticket-details',
    templateUrl: './ticket-details.component.html',
    styleUrls: ['./ticket-details.component.scss']
})
export class TicketDetailsComponent implements OnInit, OnChanges {
    @Input() public ticket: Ticket;
    @Input() public categories: TicketCategory[];
    @Input() public assignees: BootstrappedAdmin[];

    form: FormGroup;
    constructor(
        private modal: NzModalService,
        private message: NzMessageService,
        private _fb: FormBuilder,
        private ticketService: TicketService
    ) {}

    ngOnInit() {
        this.form = this._fb.group(this.ticket);

        this.form.valueChanges.pipe(
            map((payload: Ticket) => pick(payload,   [
                'category_id', 'assignee_id', 'priority'
            ]))
        ).subscribe((payload: Partial<TicketUpdatePayload>) => {
            this.ticketService.updateTicketV2(this.ticket.ticket_id, payload).pipe(
                tap(ticket => {
                    this.ticket = ticket;
                    this.form.patchValue(ticket, { emitEvent: false });
                })
            ).subscribe(
                () => this.message.success('Ticket has been updated successfully.'),
                () => this.message.success('Failed to update ticket. Please try again.')
            );
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.form) {
            this.form.get('assignee_id').setValue(this.ticket.assignee_id);
        }
    }

    syncMerchantData() {
        this.modal.confirm({
            nzTitle: 'Sync Devrise/Merchant Profile',
            nzContent: 'Sync newest Merchant Data from FasterPay into Armstrong (devrise) including details of entity,platform, source, email etc.',
            nzOnOk: () => {
                const message = this.message.loading('Syncing ...', {
                    nzDuration: 0
                });
                this.ticketService.updateTicketV2(this.ticket.ticket_id, {
                    sync_devrise: true
                }).pipe(
                    map(() => {
                        this.message.remove(message.messageId);
                        this.message.success('Merchant profile has been synced successfully.');
                    })
                ).subscribe(() => {});
            }
        });
    }
}
