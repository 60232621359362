import { GetContactsResponse } from './../../../shared/responses/get-contacts.response';
import { switchMap } from 'rxjs/operators';
import { PeopleService } from './../../../shared/services/people.service';
import { FormGroup, FormBuilder, FormControl, FormArray } from '@angular/forms';
import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { MatDialog } from '@angular/material';
import { RemoveContactComponent } from 'src/app/shared/dialogs/remove-contact/remove-contact.component';
import { PermissionsService } from 'src/app/shared/services/permissions.service';

@Component({
    selector: 'app-contacts-person-widget',
    templateUrl: './contacts-person-widget.component.html',
    styleUrls: ['./contacts-person-widget.component.scss']
})
export class ContactsPersonWidgetComponent implements OnInit, OnChanges {

    @Input() isLoading: boolean;
    @Input() contacts: any;
    @Input() id: number;

    form: FormGroup;
    fb = new FormBuilder();

    isShowEmail = false;
    isShowWechat = false;
    isShowSkype = false;
    isShowFacebook = false;
    isShowLinkedin = false;
    isShowWebsite = false;
    isShowPhone = false;
    isShowFax = false;
    isShowAddress = false;
    isShowCountry = false;
    isShowCity = false;
    isShow = false;

    isDisableSaveButton = true;

    constructor(
        private peopleService: PeopleService,
        public ps: PermissionsService,
        public dialog: MatDialog,
    ) { }

    ngOnInit() {
        this.form = this.fb.group({
            email_items: this.fb.array([]),
            wechat_items: this.fb.array([]),
            skype_items: this.fb.array([]),
            facebook_items: this.fb.array([]),
            linkedin_items: this.fb.array([]),
            website_items: this.fb.array([]),
            phone_items: this.fb.array([]),
            address_items: this.fb.array([]),
            country_items: this.fb.array([]),
            city_items: this.fb.array([]),
            fax_items: this.fb.array([]),
        });
    }

    get emailItems() { return this.form.get('email_items') as FormArray; }
    get wechatItems() { return this.form.get('wechat_items') as FormArray; }
    get skypeItems() { return this.form.get('skype_items') as FormArray; }
    get facebookItems() { return this.form.get('facebook_items') as FormArray; }
    get linkedinItems() { return this.form.get('linkedin_items') as FormArray; }
    get websiteItems() { return this.form.get('website_items') as FormArray; }
    get phoneItems() { return this.form.get('phone_items') as FormArray; }
    get addressItems() { return this.form.get('address_items') as FormArray; }
    get countryItems() { return this.form.get('country_items') as FormArray; }
    get cityItems() { return this.form.get('city_items') as FormArray; }
    get faxItems() { return this.form.get('fax_items') as FormArray; }

    setContols() {
        this.form.setControl('email_items', this.setItems(this.contacts.email));
        this.form.setControl('wechat_items', this.setItems(this.contacts.wechat));
        this.form.setControl('skype_items', this.setItems(this.contacts.skype));
        this.form.setControl('facebook_items', this.setItems(this.contacts.facebook));
        this.form.setControl('linkedin_items', this.setItems(this.contacts.linkedin));
        this.form.setControl('website_items', this.setItems(this.contacts.website));
        this.form.setControl('phone_items', this.setItems(this.contacts.phone));
        this.form.setControl('address_items', this.setItems(this.contacts.address));
        this.form.setControl('country_items', this.setItems(this.contacts.country));
        this.form.setControl('city_items', this.setItems(this.contacts.city));
        this.form.setControl('fax_items', this.setItems(this.contacts.fax));
    }

    ngOnChanges() {
        if (this.form) { this.setContols(); }
    }

    setItems(items): FormArray {
        const formArray = new FormArray([]);

        items.forEach(item => {
            formArray.push(
                this.fb.group({
                    point: item.value,
                    id: item.id
                })
            );
        });

        return formArray;
    }

    addPoint(items) {
        return items.push(this.fb.group({ point: '' }));
    }

    removePoint(index: number, items: FormArray) {

        const item = items.value[index];

        if (item.id) {
            this.dialog.open(RemoveContactComponent, {
                autoFocus: false,
                width: '496px',
                data: item,
                panelClass: 'remove-contact-modal'
            }).afterClosed().subscribe(res => {
                if (res) {
                    this.isLoading = true;
                    this.peopleService.deleteContacts(res.data.id)
                        .pipe(
                            switchMap(() => this.peopleService.getContactsByPerson(this.id))
                        )
                        .subscribe((data) => {
                            this.contacts = new GetContactsResponse(data).getContacts();
                            this.isLoading = false;
                            items.removeAt(index);
                        });
                }
            });
        } else {
            return items.removeAt(index);
        }

    }

    onSubmit(data) {
        this.isLoading = true;
        this.peopleService.editContactsByPerson(this.id, data)
            .pipe(
                switchMap(() => this.peopleService.getContactsByPerson(this.id))
            )
            .subscribe((res) => {

                this.contacts = new GetContactsResponse(res).getContacts();

                this.setContols();
                this.hideEditMode();

                this.isDisableSaveButton = true;
                this.isLoading = false;
            });
    }

    onCancel() {
        this.hideEditMode();
        this.setContols();
        this.isDisableSaveButton = true;
    }

    changeStateOfSaveButton() {
        this.isDisableSaveButton = false;
    }

    hideEditMode() {
        this.isShowEmail = false;
        this.isShowWechat = false;
        this.isShowSkype = false;
        this.isShowFacebook = false;
        this.isShowLinkedin = false;
        this.isShowWebsite = false;
        this.isShowPhone = false;
        this.isShowFax = false;
        this.isShowAddress = false;
        this.isShowCountry = false;
        this.isShowCity = false;
        this.isShow = false;

    }
}
