import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
    selector: 'app-remove-contact',
    templateUrl: './remove-contact.component.html',
    styleUrls: ['./remove-contact.component.scss']
})
export class RemoveContactComponent implements OnInit {

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<RemoveContactComponent>,
    ) { }

    ngOnInit() {
    }

    onCancelClick() {
        this.dialogRef.close();
    }

    onSubmitClick() {
        this.dialogRef.close({ data: this.data })
    }

}
