import { NgModule } from '@angular/core';
import {
    MatBadgeModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSnackBarModule,
    MatTabsModule,
    MatToolbarModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatSidenavModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCheckbox,
    MatCheckboxModule,
    MatTableModule,
    MatPaginatorModule,
    MatTooltipModule,
    MatSlideToggleModule,
    MatCardModule,
    MatGridListModule,
} from '@angular/material';

@NgModule({
    imports: [
        MatToolbarModule,
        MatIconModule,
        MatBadgeModule,
        MatSelectModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatInputModule,
        MatTabsModule,
        MatButtonToggleModule,
        MatButtonModule,
        MatMenuModule,
        MatDialogModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatSnackBarModule,
        MatChipsModule,
        MatSidenavModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatCheckboxModule,
        MatTableModule,
        MatPaginatorModule,
        MatTooltipModule,
        MatSlideToggleModule,
        MatCardModule,
        MatGridListModule,
    ],
    exports: [
        MatToolbarModule,
        MatIconModule,
        MatBadgeModule,
        MatSelectModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatInputModule,
        MatTabsModule,
        MatButtonToggleModule,
        MatButtonModule,
        MatMenuModule,
        MatDialogModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatSnackBarModule,
        MatAutocompleteModule,
        MatChipsModule,
        MatSidenavModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatCheckboxModule,
        MatTableModule,
        MatPaginatorModule,
        MatTooltipModule,
        MatSlideToggleModule,
        MatCardModule,
        MatGridListModule
    ],
})
export class MaterialModule { }
