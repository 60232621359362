import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-avatar',
    templateUrl: './avatar.component.html',
    styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnInit {

    @Input() userName: string;
    @Input() userImage: string;
    @Input() messageDate: string;
    @Input() userType;

    constructor() { }

    ngOnInit() {
    }

}
