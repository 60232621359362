import { get } from 'lodash';
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { Ticket } from '../../../../../../models/ticket.model';
import { TicketService } from '../../../../../../shared/services/ticket.service';
import { NzMessageService, NzNotificationService } from 'ng-zorro-antd';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
    // tslint:disable-next-line:component-selector
    selector: '[app-ticket-task]',
    templateUrl: './ticket-task.component.html',
    styleUrls: ['./ticket-task.component.scss']
})
export class TicketTaskComponent implements OnInit {
    @ViewChild('description', {
        static: false
    }) public descriptionRef: TemplateRef<any>;
    @Input() public task: Ticket;
    @Output() taskChanged: EventEmitter<any> = new EventEmitter();
    @Output() editingTask: EventEmitter<any> = new EventEmitter();

    /**
     * The checkbox input value.
     */
    public completed: boolean;

    constructor(public ticketService: TicketService,
                public message: NzMessageService,
                public modal: NzModalService,
                public notification: NzNotificationService) {}

    ngOnInit(): void {
        this.completed = this.taskCompleted;
    }

    /**
     * Toggle ticket task status.
     */
    changeTaskStatus(): void {
        const message = this.message.loading('Please wait...', {
            nzDuration: 0
        });

        const status = this.task.status === 'resolved' ? 'in_progress' : 'resolved';
        this.ticketService.updateTicket({
            ticket_id: this.task.ticket_id,
            data: { status }
        }).subscribe((response: { ticket: Ticket }) => {
            this.message.remove(message.messageId);
            this.message.success('Task status changed');
            this.taskChanged.emit(response.ticket);
        }, () => {
            this.message.remove(message.messageId);
            this.completed = !this.completed;
            this.message.error('Failed to change task status');
        });
    }

    get taskCompleted(): boolean {
        return this.task.status === 'resolved';
    }

    get dueDate(): string {
        if (this.taskCompleted) {
            const completedUnix = parseInt(get(this.task, 'metadata.task_completed_date'), 0);
            return completedUnix ? new Date(completedUnix * 1000).toDateString() : '-';
        }

        const dueDateUnix = parseInt(get(this.task, 'metadata.task_due_date'), 0);
        return dueDateUnix ? new Date(dueDateUnix * 1000).toDateString() : '-';
    }

    showDescription(): void {
        this.modal.create({
            nzTitle: 'Task description',
            nzContent: this.descriptionRef,
            nzFooter: null
        });
    }

    editTask(): void {
        this.editingTask.emit(this.task);
    }
}
