// Ant Design Components
import {
    NzAvatarModule,
    NzBadgeModule,
    NzButtonModule,
    NzCheckboxModule,
    NzCommentModule,
    NzDatePickerModule,
    NzDropDownModule,
    NzEmptyModule,
    NzFormModule,
    NzIconModule,
    NzInputModule,
    NzMessageModule,
    NzModalModule,
    NzNotificationModule,
    NzRadioModule,
    NzSelectModule,
    NzSkeletonModule,
    NzTableModule,
    NzTabsModule,
    NzToolTipModule,
    NzTypographyModule
} from 'ng-zorro-antd';

// Ant Design Icon
import { IconDefinition } from '@ant-design/icons-angular';
import {
    UserOutline,
    PaperClipOutline,
    EllipsisOutline,
    QuestionCircleOutline
} from '@ant-design/icons-angular/icons';
const icons: IconDefinition[] = [
    UserOutline,
    PaperClipOutline,
    EllipsisOutline,
    QuestionCircleOutline
];

// Ant Design Localization
import { NZ_I18N, en_US } from 'ng-zorro-antd';
import { NZ_ICONS } from 'ng-zorro-antd/icon';

import { AddEmployeeComponent } from './content/company-page-content/contact-details-widget/add-employee/add-employee.component';
import { UnescapeHtml } from './shared/pipes/unescape-html.pipe';
import { BrowserModule, Title } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { MaterialModule } from './helpers/material/material.module';
import { ChartsModule } from 'ng2-charts';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgPipesModule } from 'ngx-pipes';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { Nl2BrPipeModule } from 'nl2br-pipe';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgxPermissionsModule } from 'ngx-permissions';
import { TooltipModule, TooltipOptions } from 'ng2-tooltip-directive';

import { AppComponent } from './app.component';
import { SearchPageLayoutComponent } from './layouts/search-page-layout/search-page-layout.component';
import { CompanyPageLayoutComponent } from './layouts/company-page-layout/company-page-layout.component';
import { CreateCompanyComponent } from './layouts/create-company-page-layout/create-company.component';

import { ToolbarComponent } from './layouts/toolbar/toolbar.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { DialogComponent } from './content/company-page-content/activity-widget/dialog/dialog.component';
import { HeaderComponent } from './layouts/header/header.component';
import { AccessModalComponent } from './shared/dialogs/access-modal/access-modal.component';
import { ReadMoreDirective } from './shared/directives/read-more.directive';
import { DeletionModalComponent } from './content/company-page-content/info-widget/deletion-modal/deletion-modal.component';
import { DeletionPersonComponent } from './content/company-page-content/team-widget/deletion-person/deletion-person.component';
import { AssignPersonComponent } from './content/company-page-content/team-widget/assign-person/assign-person.component';
import { ProjectsListComponent } from './content/company-page-content/charts-widget/projects-list/projects-list.component';
import { TicketsComponent } from './layouts/tickets/tickets-table/tickets-table.component';

import {
    InfoWidgetComponent,
    GoalsWidgetComponent,
    TasksWidgetComponent,
    AtachmentsWidgetComponent,
    RelatedCompaniesWidgetComponent,
    ChartsWidgetComponent,
    ActivityWidgetComponent,
    TeamWidgetComponent,
    ProjectsWidgetComponent,
    ContactDetailsWidgetComponent,
    ContractualInfoWidgetComponent,
    StrategyWidgetComponent,
} from './content/company-page-content';

import {
    SearchInputComponent,
    ContactsComponent,
    CompaniesComponent,
    AddButtonsComponent
} from './content/search-page-content';
import { FiltersComponent } from './content/company-page-content/activity-widget/filters/filters.component';
import { ContentComponent } from './content/company-page-content/activity-widget/content/content.component';
import { PersonPageLayoutComponent } from './layouts/person-page-layout/person-page-layout.component';
import { InfoPersonWidgetComponent } from './content/person-page-content/info-person-widget/info-person-widget.component';
import { ActivityPersonWidgetComponent } from './content/person-page-content/activity-person-widget/activity-person-widget.component';
import { ContactsPersonWidgetComponent } from './content/person-page-content/contacts-person-widget/contacts-person-widget.component';
import { FiltersPersonComponent } from './content/person-page-content/filters-person/filters-person.component';
import { SummaryComponent } from './content/person-page-content/summary/summary.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { AllCompaniesPageLayoutComponent } from './layouts/all-companies-page-layout/all-companies-page-layout.component';
import {Store, StoreModule} from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NavigationComponent } from './layouts/navigation/navigation.component';
import { AddTaskComponent } from './content/company-page-content/tasks-widget/add-task/add-task.component';

import { personReducer } from './redux/reducers/person.reducer';
import { referenceReducer } from './redux/reducers/reference.reducer';
import { adminsReducer } from './redux/reducers/admins.reducer';
import { ticketReducer } from './redux/reducers/ticket.reducer';

import { ThemeModule } from './theme/theme.module';
import { lightTheme } from './theme/light-theme';
import { darkTheme } from './theme/dark-theme';
import { ErrorInterceptor } from './helpers/interceptors/error.interceptor';
import { MyDealsPageLayoutComponent } from './layouts/my-deals-page-layout/my-deals-page-layout.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { LostModalComponent } from './layouts/my-deals-page-layout/lost-modal/lost-modal.component';
import { FullAccessRequestModalComponent } from './shared/dialogs/full-access-request-modal/full-access-request-modal.component';
import { RemoveContactComponent } from './shared/dialogs/remove-contact/remove-contact.component';
import { DefaultTooltipOptions } from './shared/tooltip/default-options';
import { AddAttachmentComponent } from './content/company-page-content/atachments-widget/add-attachment/add-attachment.component';
import { PeopleComponent } from './layouts/people/people.component';
import { LatestCorrespondenceComponent } from './layouts/latest-correspondence/latest-correspondence.component';
import { SingleNoteComponent } from './layouts/latest-correspondence/single-note/single-note.component';
import { Truncate } from './shared/pipes/truncate.pipe';
import { StripHtmlPipe } from './shared/pipes/strip-html.pipe';
import { StrTitlePipePipe } from './shared/pipes/StrTitlePipe.pipe';
import { NoteViewComponent } from './layouts/latest-correspondence/note-view/note-view.component';
import { CreatePersonPageLayoutComponent } from './layouts/create-person-page-layout/create-person-page-layout.component';
import { MomentPipe } from './shared/pipes/moment.pipe';
import { TeamPersonWidgetComponent } from './content/person-page-content/team-person-widget/team-person-widget.component';
// import { AuthguardService } from './shared/services/authguard.service';
import { TicketPageComponent } from './layouts/tickets/ticket-page/ticket-page.component';
import { TicketDetailsComponent } from './layouts/tickets/ticket-page/ticket-details/ticket-details.component';
import { TicketAttachmentsComponent } from './layouts/tickets/ticket-page/ticket-attachments/ticket-attachments.component';
import { TileComponent } from './layouts/tickets/basic/tile/tile.component';
import { CollapseComponent } from './layouts/tickets/basic/collapse/collapse.component';
import { TicketQaComponent } from './layouts/tickets/ticket-page/ticket-qa/ticket-qa.component';
import { TicketMerchantComponent } from './layouts/tickets/ticket-page/ticket-merchant/ticket-merchant.component';
import { TicketUserProfileComponent } from './layouts/tickets/ticket-page/ticket-user-profile/ticket-user-profile.component';
import { TicketConversationComponent } from './layouts/tickets/ticket-page/ticket-merchant/ticket-conversation/ticket-conversation.component';
import { ConversationMessageComponent } from './layouts/tickets/ticket-page/ticket-merchant/ticket-conversation/conversation-message/conversation-message.component';
import { AvatarComponent } from './layouts/tickets/basic/avatar/avatar.component';
import { MessageAttachmentComponent } from './layouts/tickets/basic/message-attachment/message-attachment.component';
import { DialogConfirmUnlockTicketComponent } from './layouts/tickets/ticket-page/ticket-merchant/ticket-conversation/dialog-confirm-unlock-ticket/dialog-confirm-unlock-ticket.component';
import { TicketNotesComponent } from './layouts/tickets/ticket-page/ticket-merchant/ticket-notes/ticket-notes.component';
import { TicketNoteComponent } from './layouts/tickets/ticket-page/ticket-merchant/ticket-notes/ticket-note/ticket-note.component';
import { ReplyNoteDialogComponent } from './layouts/tickets/ticket-page/ticket-merchant/ticket-notes/reply-note-dialog/reply-note-dialog.component';
import { TicketTasksComponent } from './layouts/tickets/ticket-page/ticket-merchant/ticket-tasks/ticket-tasks.component';
import { TicketTaskComponent } from './layouts/tickets/ticket-page/ticket-merchant/ticket-tasks/ticket-task/ticket-task.component';
import { CreateTaskDialogComponent } from './layouts/tickets/ticket-page/ticket-merchant/ticket-tasks/create-task-dialog/create-task-dialog.component';
import { ConfirmResolveTicketDialogComponent } from './layouts/tickets/ticket-page/confirm-resolve-ticket-dialog/confirm-resolve-ticket-dialog.component';
import { CreateNewTicketComponent } from './content/ticket-page-content/create-new-ticket/create-new-ticket.component';
import { AuthTicketService } from './shared/services/auth-ticket.service';
import { TicketSummaryComponent } from './layouts/tickets/ticket-page/ticket-summary/ticket-summary.component';
import {SuccessLoadUserInfo} from "./redux/actions/person.action";
import {AppState} from "./redux/app.state";


function initializeToken (auth:AuthTicketService, store:Store<AppState>) {
        return new Promise(async (resolve, reject)=>{
            const queryString = window.location.search || window.location.hash;
            const queryStringStartIdx = queryString.indexOf('?');
            const searchParams = new URLSearchParams(queryString.slice(queryStringStartIdx));
            const authToken = searchParams.get('auth_token');
            const userInfoLocalStorageData = localStorage.getItem('userInfo');
            try {
                if (authToken) {
                    await auth.getAccessToken(authToken).toPromise().then(resp => {
                        store.dispatch(new SuccessLoadUserInfo(resp.profile));
                        localStorage.setItem('userInfo', JSON.stringify(resp.profile));
                        localStorage.setItem('access_token', resp.access_token);
                    }).catch(error => {
                        alert(error);
                        window.location.href = window.location.href.split('#')[0]
                    });
                } else if (userInfoLocalStorageData) {
                    const personData = JSON.parse(userInfoLocalStorageData);
                    store.dispatch(new SuccessLoadUserInfo(personData));

            }
        } catch (error) {
            // ...
            console.log({ error });
        } finally {
            resolve()
        }
    })

}

const reducers = {
    reference: referenceReducer,
    admins: adminsReducer,
    person: personReducer,
    ticket: ticketReducer
};

@NgModule({
    declarations: [
        AppComponent,
        CompanyPageLayoutComponent,
        InfoWidgetComponent,
        GoalsWidgetComponent,
        TasksWidgetComponent,
        AtachmentsWidgetComponent,
        ContractualInfoWidgetComponent,
        StrategyWidgetComponent,
        RelatedCompaniesWidgetComponent,
        ChartsWidgetComponent,
        ActivityWidgetComponent,
        TeamWidgetComponent,
        ProjectsWidgetComponent,
        ContactDetailsWidgetComponent,
        ToolbarComponent,
        DialogComponent,
        SearchPageLayoutComponent,
        SearchInputComponent,
        ContactsComponent,
        CompaniesComponent,
        AddButtonsComponent,
        HeaderComponent,
        AccessModalComponent,
        ReadMoreDirective,
        DeletionModalComponent,
        DeletionPersonComponent,
        AssignPersonComponent,
        ProjectsListComponent,
        CreateCompanyComponent,
        UnescapeHtml,
        Truncate,
        StripHtmlPipe,
        StrTitlePipePipe,
        MomentPipe,
        AddEmployeeComponent,
        FiltersComponent,
        ContentComponent,
        PersonPageLayoutComponent,
        InfoPersonWidgetComponent,
        ActivityPersonWidgetComponent,
        ContactsPersonWidgetComponent,
        FiltersPersonComponent,
        SummaryComponent,
        AllCompaniesPageLayoutComponent,
        NavigationComponent,
        AddTaskComponent,
        MyDealsPageLayoutComponent,
        LostModalComponent,
        FullAccessRequestModalComponent,
        RemoveContactComponent,
        AddAttachmentComponent,
        PeopleComponent,
        LatestCorrespondenceComponent,
        SingleNoteComponent,
        NoteViewComponent,
        CreatePersonPageLayoutComponent,
        TeamPersonWidgetComponent,
        TicketsComponent,
        TicketPageComponent,
        TicketDetailsComponent,
        TicketAttachmentsComponent,
        TileComponent,
        CollapseComponent,
        TicketQaComponent,
        TicketMerchantComponent,
        TicketUserProfileComponent,
        TicketConversationComponent,
        ConversationMessageComponent,
        AvatarComponent,
        MessageAttachmentComponent,
        DialogConfirmUnlockTicketComponent,
        TicketNotesComponent,
        TicketNoteComponent,
        ReplyNoteDialogComponent,
        TicketTasksComponent,
        TicketTaskComponent,
        CreateTaskDialogComponent,
        ConfirmResolveTicketDialogComponent,
        CreateNewTicketComponent,
        TicketSummaryComponent
    ],
    entryComponents: [
        DialogComponent,
        AccessModalComponent,
        DeletionModalComponent,
        DeletionPersonComponent,
        AssignPersonComponent,
        AddEmployeeComponent,
        AddTaskComponent,
        LostModalComponent,
        RemoveContactComponent,
        AddAttachmentComponent,
        FullAccessRequestModalComponent,
        RemoveContactComponent,
        SingleNoteComponent,
        DialogConfirmUnlockTicketComponent,
        ReplyNoteDialogComponent,
        CreateTaskDialogComponent,
        ConfirmResolveTicketDialogComponent,
        CreateNewTicketComponent
    ],
    imports: [
        // Ant Design Components
        NzButtonModule,
        NzInputModule,
        NzSelectModule,
        NzTabsModule,
        NzIconModule,
        NzModalModule,
        NzTypographyModule,
        NzAvatarModule,
        NzFormModule,
        NzDatePickerModule,
        NzSkeletonModule,
        NzTableModule,
        NzCheckboxModule,
        NzNotificationModule,
        NzMessageModule,
        NzBadgeModule,
        NzEmptyModule,
        NzToolTipModule,
        NzCommentModule,
        NzDropDownModule,
        NzRadioModule,
        // End Ant Design Components
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        MaterialModule,
        ChartsModule,
        HttpClientModule,
        NgxSpinnerModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        NgPipesModule,
        CKEditorModule,
        Nl2BrPipeModule,
        NgxDatatableModule,
        NgxMatSelectSearchModule,
        DragDropModule,
        TooltipModule.forRoot(DefaultTooltipOptions as TooltipOptions),
        // ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
        StoreModule.forRoot(reducers, {
            runtimeChecks: {
                strictStateImmutability: true,
                strictStateSerializability: true
            }
        }),
        !environment.production ? StoreDevtoolsModule.instrument() : [],
        ThemeModule.forRoot({
            themes: [lightTheme, darkTheme],
            active: 'light'
        }),
        NgxPermissionsModule.forRoot()
    ],
    providers: [
        Title,
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: NZ_I18N, useValue: en_US },
        { provide: NZ_ICONS, useValue: icons },
        {
            provide: APP_INITIALIZER,
            useFactory: (auth:AuthTicketService,store:Store<AppState>)=>()=>initializeToken(auth,store),
            deps: [AuthTicketService,Store],
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
