import { AdminsModel } from '../../models/admins.model';
import * as moment from 'moment';
import { NoteItem, NotesModel } from '../../models/notes.model';

export class GetNotesResponse {

    readonly res: any;
    readonly admins: any;
    readonly noteTypes: any[];

    constructor(res: any, admins?: any, noteTypes?: any[]) {
        this.res = res;
        this.admins = admins;
        this.noteTypes = noteTypes;
    }

    getNotes() {

        return this.res.notes.list.map((item: any) => {

            const convertTimestampDate = moment.unix(item.date);
            const relativeTime = moment(convertTimestampDate, 'll').fromNow();

            this.getAttachmentFormat(item);

            this.noteTypes.forEach(note => {
                if (item.type === note.id) {
                    item.name = note.name;
                }
            });

            return {
                id: item.id,
                type: item.type,
                name: item.name,
                ref_type: item.ref_type,
                ref_id: item.ref_id,
                subject: item.subject,
                body: item.body,
                body_short: item.body_short,
                date: convertTimestampDate,
                relativeDate: relativeTime,
                note_url: item.note_url,
                data: item.data,
                attachments: item.attachments ? item.attachments.data : null,
                recipients: item.recipients ? item.recipients : null,
                author_name: item.author_name,
                author_icon: item.author_icon,
                author_link: item.author_link,
                admin_id: item.admin_id,
                interview: item.interview,
                original_author_admin_name: item.original_author_admin_id && this.admins ?
                    this.admins.find(admin => item.original_author_admin_id === admin.id).full_name
                    : null,
                original_author_admin_id: item.original_author_admin_id,
            };
        });
    }

    getSignleNote() {

        const convertTimestampDate = moment.unix(this.res.date);
        const relativeTime = moment(convertTimestampDate, 'll').fromNow();

        this.getAttachmentFormat(this.res);

        return {
            id: this.res.id,
            type: this.res.type,
            name: this.noteTypes.find(note => note.id === this.res.type).name,
            ref_type: this.res.ref_type,
            ref_id: this.res.ref_id,
            subject: this.res.subject,
            body: this.res.body,
            body_short: this.res.body_short,
            date: convertTimestampDate,
            relativeDate: relativeTime,
            note_url: this.res.note_url,
            data: this.res.data ? JSON.parse(this.res.data) : null,
            attachments: this.res.attachments ? this.res.attachments.data : null,
            recipients: this.res.recipients ? this.res.recipients : null,
            author_name: this.res.author_name,
            author_icon: this.res.author_icon,
            author_link: this.res.author_link,
            admin_id: this.res.admin_id,
            admin_name: this.res.admin_id ?
                this.admins.admins.find(admin => this.res.admin_id === admin.id).full_name
                : null,
            original_author_admin_name: this.res.original_author_admin_id ?
                this.admins.admins.find(admin => this.res.original_author_admin_id === admin.id).full_name
                : null,
            original_author_admin_id: this.res.original_author_admin_id,
            sent_status: this.res.sent_status,
            interview: this.res.interview,
        };
    }

    private getAttachmentFormat(res: any) {
        if (res.attachments) {
            res.attachments.data.forEach((item: any) => {

                const format = item.name.slice(-3);
                const formatJpeg = item.name.slice(-4);

                if (format === 'jpg' || format === 'png') {
                    item.format = format;
                } else if (formatJpeg === 'jpeg') {
                    item.format = item.name.slice(-4);
                } else {
                    item.format = format;
                }

            });
        }
    }
}
