import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
    name: 'unescapeHtml'
})

export class UnescapeHtml implements PipeTransform {

    transform(value: string) {
        return value.replace(/&amp;/g, '&').replace(/&lt;/g, '<').replace(/&gt;/g, '>');
    }
}

