import { List } from '../../models/people.model';

export class GetPeopleResponse {
    readonly res: any;

    constructor(res) {
        this.res = res;
    }

    getPeople() {
        return this.res.people.list.map((item: List) => {
            return {
                name: item.name,
                last_name: item.last_name,
                title: item.title,
                icon: item.icon,
                url: item.url,
                id: item.id
            };
        });
    }
}
