import { HttpClient, HttpParams } from '@angular/common/http';
import { CRM_API_URL } from '../../shared/const/constants.const';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
// import { UpdateAccessToken } from '../../redux/actions/ticket.action';
import * as TicketActions from '../../redux/actions/ticket.action';

export class CrmApi {
    // accessToken$: Observable<{ticket_access_token: string}>;
    // token: string;

    constructor(
        public http: HttpClient // private store: Store<{ ticket: {ticket_access_token: string} }>
    ) {
        // this.accessToken$ = this.store.select('ticket');
        // this.accessToken$.subscribe(
        //   value => {
        //     console.log(`access token is ${value.ticket_access_token}`);
        //     this.token = value.ticket_access_token;
        //     this.store.dispatch(new TicketActions.UpdateAccessToken('hello!'));
        //   });
    }

    private static getUrl(url: string = ''): string {
        return `${CRM_API_URL}${url}`;
    }

    public get<T = any>(url: string = '', params?: Record<string, any>): Observable<T> {
        return this.http.get<T>(CrmApi.getUrl(url), {
            // withCredentials: true,
            params,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.getItem('access_token')}`
            }
        });
    }

    public put(url: string = '', body: {}): Observable<any> {
        return this.http.put(CrmApi.getUrl(url), body, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`
            }
        });
    }

    public delete(url: string): Observable<any> {
        return this.http.delete(CrmApi.getUrl(url), {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`
            }
        });
    }

    public post(url: string = '', body: {}): Observable<any> {
        return this.http.post(CrmApi.getUrl(url), body, {
            withCredentials: true
        });
    }

    public postFormData(url: string, body: FormData): Observable<any> {
        return this.http.post(CrmApi.getUrl(url), body, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`
            }
        });
    }

    public putFormUrlEncoded(url: string = '', body: URLSearchParams): Observable<any> {
        console.log(`body.toString()`, body.toString())
        return this.http.put(CrmApi.getUrl(url), body.toString(), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                Authorization: `Bearer ${localStorage.getItem('access_token')}`
            }
        });
    }
}
