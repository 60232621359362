import { PersonModel } from './../../../models/person.model';
import { CompanyTypeItem, CandidateSubtypeItem, CurrencyItem } from './../../../models/reference.model';
import { AppState } from 'src/app/redux/app.state';
import { CompanyService } from 'src/app/shared/services/company.service';
import { PeopleService } from './../../../shared/services/people.service';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { Component, OnInit, Input, OnChanges, DoCheck, Output, EventEmitter, ViewChild, OnDestroy } from '@angular/core';
import { tap, mergeMap, debounceTime, switchMap, takeUntil } from 'rxjs/operators';
import { ReplaySubject, Subject, Observable, of } from 'rxjs';
import { GetReferenceResponse } from 'src/app/shared/responses/get-reference.response';
import { MatSelect, MatAutocompleteTrigger } from '@angular/material';
import { Store, select } from '@ngrx/store';
import { PermissionsService } from 'src/app/shared/services/permissions.service';
import { AdminItem } from 'src/app/models/admins.model';
import { GetPeopleByIdResponse } from 'src/app/shared/responses/get-people-by-id.response';
import * as moment from 'moment';
import { OfficesModel, OfficeItem } from 'src/app/models/offices.model';

@Component({
    selector: 'app-info-person-widget',
    templateUrl: './info-person-widget.component.html',
    styleUrls: ['./info-person-widget.component.scss']
})
export class InfoPersonWidgetComponent implements OnInit, OnChanges, OnDestroy {

    @ViewChild('sourceSelect', { static: false }) sourceSelect: MatSelect;
    @ViewChild(MatAutocompleteTrigger, { static: false }) autocomplete: MatAutocompleteTrigger;

    // @Input() person: PersonModel;
    @Input() sources: any[];
    @Input() source: any;
    @Input() types: CompanyTypeItem[];
    @Input() type: CompanyTypeItem;
    @Input() subtype: CandidateSubtypeItem;
    @Input() subtypes: CandidateSubtypeItem[];
    @Input() referral: any;
    @Input() company: any;
    @Input() isLoading: boolean;
    @Input() id: number;
    @Input() admins: AdminItem[];
    @Input() offices: OfficesModel;
    @Input() office: OfficeItem;
    @Input() currencies: CurrencyItem[];

    @Output() refreshPerson: EventEmitter<any> = new EventEmitter<any>();

    person: PersonModel;

    isLoadingSpinner = false;

    form: FormGroup;
    fb = new FormBuilder();

    filteredCompanies: any[] = [];

    isShowCandidateState = false;

    isShowName = false;
    isShowJobTittle = false;
    isShowCompanyName = false;
    isShowType = false;
    isShowSubtype = false;
    isShowFlight = false;
    isShowAllowance = false;
    isShowAccommodation = false;
    isShowInsurance = false;
    isShowCompenstation = false;
    isShowSource = false;
    isShowRefferal = false;
    isShowOffice = false;
    isShowDate = false;
    isShowPositions = false;
    isShow = false;

    isDisableSaveButton = true;

    isShowSpinner = false;

    isCompanyExist = true;

    imgUrlPerson: any;
    personIcon: any;

    findedCompanies: any[] = [];

    filteredPositions: OfficeItem | any;

    sourceFilterCtrl: FormControl = new FormControl();
    filteredSource: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

    sourceId: string;

    protected _onDestroy = new Subject<void>();

    constructor(
        private peopleService: PeopleService,
        private companyService: CompanyService,
        private store: Store<AppState>,
        public ps: PermissionsService,
    ) {
        this.store.pipe(select('person'))
            .subscribe((store) => {
                this.person = store;
                this.changeCandidateState();
            });
    }

    ngOnInit() {

        this.form = this.fb.group({
            name: new FormControl(''),
            job_title: new FormControl(''),
            company: new FormControl(''),
            type: new FormControl(''),
            source: new FormControl(''),
            subtype: new FormControl(''),
            fly_term: new FormControl(''),
            insurance_term: new FormControl(''),
            allowance_term: new FormControl(''),
            accommodation_term: new FormControl(''),
            compensation: new FormControl(''),
            compensation_currency: new FormControl(''),
            referral: new FormControl(''),
            office: new FormControl(''),
            datetime: new FormControl(''),
            positions: new FormControl([]),
        });

        this.form.controls.company.valueChanges.pipe(
            debounceTime(1000),
            tap(value => {
                if (value && typeof value !== 'number') {
                    this.isLoadingSpinner = true;
                    this.companyService.searchByCompany(value)
                        .subscribe((res: any) => {
                            if (res.length !== 0) {
                                this.findedCompanies = [];
                                res.companies.forEach((item: any) => {
                                    this.findedCompanies.push(item);
                                });

                                this.isLoadingSpinner = false;
                                this.isCompanyExist = true;

                                if (this.ps.canEditPersonInformation) {
                                    this.isDisableSaveButton = false;
                                    this.isShow = true;
                                }

                            } else {
                                this.findedCompanies = [];
                                this.isDisableSaveButton = true;
                                this.isLoadingSpinner = false;
                                this.isCompanyExist = false;
                                this.autocomplete.closePanel();
                            }
                        });
                } else if (value === '') {
                    this.findedCompanies = [];

                    if (this.ps.canEditPersonInformation) {
                        this.isDisableSaveButton = false;
                    }

                    this.isCompanyExist = true;
                    this.autocomplete.closePanel();
                }
            })
        ).subscribe();
    }

    ngOnChanges() {

        if (this.office) { this.changePositionsState(this.office.id); }

        if (Object.keys(this.company).length === 0 || !this.company.name) {
            this.isShowCompanyName = true;
        } else {
            this.isShowCompanyName = false;
        }

        if (this.person.person.title) {
            this.isShowJobTittle = false;
        } else {
            this.isShowJobTittle = true;
        }

        this.filteredSource.next(this.sources);

        this.sourceFilterCtrl.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => {
                this.filterSources();
            });

        if (this.form) { this.setControls(); }
    }

    setControls() {
        const name = `${this.person.person.name} ${this.person.person.last_name}`;

        // tslint:disable-next-line: one-variable-per-declaration
        let flyTerm: string, allowanceTerm: string, insuranceTerm: string, accommodationTerm: string;

        if (this.person.person.relocation_term_options) {
            flyTerm = this.person.person.relocation_term_options.fly.text;
            allowanceTerm = this.person.person.relocation_term_options.allowance.text;
            insuranceTerm = this.person.person.relocation_term_options.insurance.text;
            accommodationTerm = this.person.person.relocation_term_options.accommodation.text;
        }

        this.form.patchValue({
            name: this.person ? name : null,
            job_title: this.person ? this.person.person.title : null,
            company: this.company ? this.company.id : null,
            type: this.type ? this.type.id : null,
            subtype: this.subtype ? this.subtype.id : null,
            source: this.source ? this.source.id : null,
            fly_term: flyTerm ? flyTerm : null,
            allowance_term: allowanceTerm ? allowanceTerm : null,
            accommodation_term: accommodationTerm ? accommodationTerm : null,
            insurance_term: insuranceTerm ? insuranceTerm : null,
            compensation: this.person.person.desire_compensation !== 0 ? this.person.person.desire_compensation : '',
            compensation_currency: parseInt(this.person.person.desire_compensation_currency_id, 10),
            referral: parseInt(this.person.person.referral_admin_id, 10),
            office: this.person.person.office_id,
            datetime: this.person.person.datetime_interviewed !== 0
                ? moment.unix(this.person.person.datetime_interviewed).toISOString()
                : '',
        });

        if (this.person.person.desire_positions) {

            const positions = [];

            for (let i = 0; i < this.person.person.desire_positions.length; i++) {
                if (this.office.jobs[i].id) {
                    positions.push(this.office.jobs[i].id);
                }
            }

            this.form.controls.positions.setValue(positions);
        }
    }

    ngOnDestroy() {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    onAddSource(name: string) {
        this.isShowSpinner = true;
        this.companyService.addSource(name).pipe(
            tap((res) => {
                this.sourceId = res.source_id;
            }),
            switchMap(() => this.companyService.getReference())
        ).subscribe(res => {
            this.sources = new GetReferenceResponse(res).getSources();
            this.form.patchValue({ source: parseInt(this.sourceId, 10) });
            this.isShowSpinner = false;
            this.isDisableSaveButton = false;
            this.sourceSelect.close();
        });
    }

    onSubmit(data) {

        this.isLoading = true;

        this.peopleService.editPerson(
            this.id,
            data,
            this.personIcon,
            this.person.person.company_id)
            .pipe(
                tap((res) => {

                    const people = new GetPeopleByIdResponse();

                    !res.person.title ? this.isShowJobTittle = true : this.isShowJobTittle = false;

                    this.person = res;

                    if (this.person.person.company_id !== 0) {
                        this.isShowType = false;
                        this.isShowCandidateState = false;
                    } else if (this.person.person.company_id === 0 && this.person.person.type !== 6) {
                        this.isShowType = true;
                        this.isShowCandidateState = false;
                    } else {
                        this.isShowType = true;
                        this.isShowCandidateState = true;
                    }

                    this.type = people.serializePerson(this.types, this.person.person.type);
                    this.source = people.serializePerson(this.sources, this.person.person.source);
                    this.subtype = people.serializePerson(this.subtypes, this.person.person.subtype);
                    this.referral = people.serializePerson(this.admins, parseInt(this.person.person.referral_admin_id, 10));
                    this.office = people.serializeOffice(this.offices, this.person.person.office_id);

                    this.refreshPerson.emit(this.person);

                }),
                switchMap(() => this.person.person.company_id !== 0
                    ? this.companyService.getCompanyById(this.person.person.company_id)
                    : of(this.company = {})),
            ).subscribe(res => {
                if (Object.keys(res).length !== 0) {
                    this.findedCompanies = [];
                    this.company.name = res.company.name;
                    this.company.id = res.company.id;
                    this.isLoading = false;
                    this.isDisableSaveButton = true;
                    this.hideEditMode();
                } else {
                    this.isLoading = false;
                    this.isDisableSaveButton = true;
                    this.hideEditMode();
                    this.isShowCompanyName = true;
                    this.isCompanyExist = true;
                }
            });
    }

    onCancel() {

        if (this.person.person.type !== 6) {
            this.isShowCandidateState = false;
        } else if (this.type.id !== 6) {
            this.peopleService.editPerson(this.id, this.form.value)
                .subscribe(() => {
                    this.isShowCandidateState = false;
                });
        } else {
            this.isShowCandidateState = true;
        }

        this.hideEditMode();

        if (this.person.person.company_id === 0) {
            this.isShowCompanyName = true;
        }

        this.setControls();

        this.findedCompanies = [];
        this.isDisableSaveButton = true;
        this.isCompanyExist = true;
    }

    changeStateOfSaveButton() {
        if (this.ps.canEditPersonInformation) {
            this.isDisableSaveButton = false;
            this.isShow = true;
        }
    }

    changePositionsState(officeId: any) {

        this.filteredPositions = this.offices.offices.find(office => {
            return officeId === office.id;
        });

        if (this.filteredPositions.jobs.length === 0) {
            this.form.controls.positions.setValue([]);
        }
    }

    changeCandidateState(typeId?: number, event?) {
        if (this.person.person.type === 6 && !event) {
            this.isShowCandidateState = true;
        } else if (typeId === 6 && event) {

            this.peopleService.editPerson(this.id, this.form.value)
                .subscribe(res => {

                    this.person = res;

                    // tslint:disable-next-line: one-variable-per-declaration
                    let flyTerm: string, allowanceTerm: string, insuranceTerm: string, accommodationTerm: string;

                    if (this.person.person.relocation_term_options) {
                        flyTerm = this.person.person.relocation_term_options.fly.text;
                        allowanceTerm = this.person.person.relocation_term_options.allowance.text;
                        insuranceTerm = this.person.person.relocation_term_options.insurance.text;
                        accommodationTerm = this.person.person.relocation_term_options.accommodation.text;
                    }

                    this.form.patchValue({
                        subtype: this.subtype ? this.subtype.id : null,
                        source: this.source ? this.source.id : null,
                        fly_term: flyTerm ? flyTerm : null,
                        allowance_term: allowanceTerm ? allowanceTerm : null,
                        accommodation_term: accommodationTerm ? accommodationTerm : null,
                        insurance_term: insuranceTerm ? insuranceTerm : null,
                        compensation: this.person.person.desire_compensation !== 0 ? this.person.person.desire_compensation : '',
                        compensation_currency: parseInt(this.person.person.desire_compensation_currency_id, 10),
                        referral: parseInt(this.person.person.referral_admin_id, 10),
                        office: this.person.person.office_id,
                        datetime: this.person.person.datetime_interviewed !== 0
                            ? moment.unix(this.person.person.datetime_interviewed).toISOString()
                            : ''
                    });

                    this.isShowRefferal = true;
                    this.isShowCompenstation = true;
                    this.isShowSubtype = true;
                    this.isShowOffice = true;
                    this.isShowDate = true;
                    this.isShowAccommodation = true;
                    this.isShowAllowance = true;
                    this.isShowFlight = true;
                    this.isShowInsurance = true;
                    this.isShowPositions = true;
                    this.isShowCandidateState = true;
                });

        } else {
            this.isShowCandidateState = false;
        }
    }

    displayFnCompanies = (value?: number) => {
        if (value && this.findedCompanies.length !== 0 && value !== this.company.id) {
            return this.findedCompanies.find(item => item.company.id === value).company.name;
        } else if (value === this.company.id) {
            return this.company.name;
        }
    }

    uploadPersonIcon(event: any) {
        this.isShow = true;
        this.onFileChange(event);
    }

    hideEditMode() {
        this.isShowName = false;
        this.isShowType = false;
        this.isShowSubtype = false;
        this.isShowSource = false;
        this.isShow = false;
        this.isShowCompanyName = false;
        this.isShowJobTittle = false;
        this.isShowFlight = false;
        this.isShowAllowance = false;
        this.isShowAccommodation = false;
        this.isShowInsurance = false;
        this.isShowCompenstation = false;
        this.isShowRefferal = false;
        this.isShowOffice = false;
        this.isShowDate = false;
        this.isShowPositions = false;
    }

    protected filterSources() {
        if (!this.sources) {
            return;
        }
        let search = this.sourceFilterCtrl.value;
        if (!search) {
            this.filteredSource.next(this.sources.slice());
            return;
        } else {
            search = search.toLowerCase();
        }
        this.filteredSource.next(
            this.sources.filter(source => source.name.toLowerCase().indexOf(search) > -1)
        );
    }

    private onFileChange(event: any) {

        const reader = new FileReader();

        if (event.target.files && event.target.files.length) {

            const fileName = event.target.files[0].name;

            const [file] = event.target.files;

            reader.readAsDataURL(file);

            reader.onload = () => {

                if (typeof reader.result === 'string') {

                    const result = reader.result.split(',')[1];

                    this.imgUrlPerson = reader.result;

                    this.personIcon = {
                        name: fileName,
                        content: result
                    };
                }
            };
        }
    }
}
