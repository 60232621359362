import { ThemeService } from '../../../theme/theme.service';
import { ErrorService } from '../../../shared/services/error.service';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { CompanyService } from 'src/app/shared/services/company.service';
import { TicketService } from 'src/app/shared/services/ticket.service';
import {
    Component,
    ViewChild,
    OnInit,
    ElementRef
} from '@angular/core';
import { Store} from '@ngrx/store';
import { AppState } from 'src/app/redux/app.state';
import { DatatableComponent} from '@swimlane/ngx-datatable';
import { startWith, map } from 'rxjs/operators';
import { Observable, ReplaySubject} from 'rxjs';
import { MatDialog, MatSelectChange } from '@angular/material';
import { TicketCategory, TicketProblem } from 'src/app/models/ticket.model';
import { BootstrappingService } from 'src/app/shared/services/bootstrapping.service';
import { BootstrappedAdmin } from 'src/app/models/admins.model';
import { Client } from 'src/app/models/services.model';
import { CreateNewTicketComponent } from '../../../content/ticket-page-content/create-new-ticket/create-new-ticket.component';
import { ActivatedRoute } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzTabChangeEvent } from 'ng-zorro-antd';

@Component({
    selector: 'app-tickets',
    templateUrl: './tickets-table.component.html',
    styleUrls: ['./tickets-table.component.scss']
})
export class TicketsComponent implements OnInit {
    @ViewChild('table', { static: false }) table: DatatableComponent;

    // Default select the second tab
    public selectedTabIndex = 1;

    readonly headerHeight = 50;

    perPages = [20, 50, 100, 500];
    perPageValue = 20;
    priorities = [1, 2, 3, 4, 5];
    form: FormGroup;

    counter = 0;
    filtersCounter = 0;

    rows: any[] = [];

    filteredAssignedMember: any[] = [];
    filteredCreatedMember: any[] = [];

    filteredSubtypes: any[] = [];

    referenceState: any;

    reputations: any[];

    isTyping: any;
    isFilterValue: any;
    isNoMoreTickets: boolean;

    isShowFilters = false;

    isLoading = false;

    isClearReputationFilter = false;

    totalTickets = 0;

    sourceFilterCtrl: FormControl = new FormControl();

    filteredSource: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

    selected: string[] = [];

    selectError = false;

    active: boolean;

    assignees: BootstrappedAdmin[] = [];

    ticketCategories: TicketCategory[] = [];

    ticketProblems: TicketProblem[] = [];

    ticketStatuses: string[] = [];

    creatingTicket = false;

    selectedStatuses: string[] = ['new', 'in_progress'];

    clients: Client[] = [];

    filteredClients: Observable<Client[]>;

    constructor(
        private modal: NzModalService,
        private companyService: CompanyService,
        private ticketService: TicketService,
        private bootstrappingService: BootstrappingService,
        private el: ElementRef,
        private store: Store<AppState>,
        private _fb: FormBuilder,
        public dialog: MatDialog,
        private errorService: ErrorService,
        private themeService: ThemeService,
        private activeRouter: ActivatedRoute
    ) {
        this.themeService.getThemeFromLocalStorage();
        this.form = this._fb.group({
            ticket_id: new FormControl(''),
            transaction_id: new FormControl(''),
            status: new FormControl(this.selectedStatuses),
            type: new FormControl([]),
            user_email: new FormControl(''),
            assignee_id: new FormControl(null),
            category_id: new FormControl(''),
            created_after: new FormControl(''),
            created_before: new FormControl(''),
            is_headless: new FormControl(''),
            service_id: new FormControl(''),
            priority: new FormControl(''),
            author_id: new FormControl(null),
            merchant_platform: new FormControl(''),
            merchant_source: new FormControl(''),
            merchant_status: new FormControl(''),
            merchant_kyc_status: new FormControl(''),
            merchant_bm_status: new FormControl(''),
            merchant_country: new FormControl(''),
            status_decision: new FormControl(''),
        });
    }

    ngOnInit() {
        this.getBootstrappedData();
        this.isFilterValue = this.form.value;

        const emailRef: string = this.activeRouter.snapshot.queryParamMap.get('email');
        if (emailRef) {
            this.form.get('user_email').setValue(emailRef);
        }

        this.filteredClients = this.form.get('service_id').valueChanges.pipe(
            startWith(''),
            map(value => typeof value === 'string' ? value : (value.display_name)),
            map(name => name ? this._filterClient(name) : this.clients.slice())
        );
        this.onFilter(this.form.value);
    }

    getPageValue(event: MatSelectChange) {
        this.perPageValue = event.value ? event.value : this.perPageValue;
        this.onFilter(this.form.value);
    }

    getBootstrappedData() {
        const include = ['admins', 'ticket_problems', 'ticket_categories', 'clients'];
        this.bootstrappingService.bootstrappingData(include).subscribe(response => {
            this.assignees = response.data.admins || [];
            this.ticketCategories = response.data.ticket_categories || [];
            this.ticketProblems = response.data.ticket_problems || [];
            this.ticketStatuses = response.data.ticket_statuses;
            this.clients = response.data.clients || [];
        });
    }

    onScroll(offsetY: number) {
        const viewHeight =
            this.el.nativeElement.getBoundingClientRect().height -
            this.headerHeight;

        if (
            !this.isLoading &&
            !this.isTyping &&
            !this.isNoMoreTickets &&
            offsetY + viewHeight >= this.rows.length * this.rowHeight
        ) {
            this.loadPage();
        }
    }

    onFilter(value, sorter?) {
        const ticketTypeMaps = [[], ['merchant'], ['task']];
        value.type = ticketTypeMaps[this.selectedTabIndex];
        this.counter = 1;
        this.filtersCounter = 0;

        this.isFilterValue = value;
        this.isLoading = true;
        this.isNoMoreTickets = false;
        this.isShowFilters = false;

        Object.values(value).forEach((item: any) => {
            if (item && !item.per_page) {
                this.filtersCounter += 1;
            }
        });

        this.ticketService
            .filterByTickets(this.counter, {
                ...value, ...(sorter || {
                    'sort[field]': 'ticket_id',
                    'sort[direction]': 'desc'
                })
            }, this.perPageValue, 'service')
            .subscribe(data => {
                if (data.data) {
                    this.rows = [...data.data];
                } else {
                    this.rows = [];
                }
                this.totalTickets = data.total;
                this.isLoading = false;
            });
    }

    onSort(event, value) {
        const sort = {
            'sort[field]': event.column.prop,
            'sort[direction]': event.newValue
        };

        this.onFilter(value, sort);
    }

    clearFilter(target) {
        const field = this.form.controls[target];
        if (field) { field.reset(); }
    }

    displayFnForAssigned = (value?: number) => {
        return value
            ? this.assignees.find(item => item.admin_id === value)
                .fullname
            : undefined;
    }

    displayFnForClient = (value?: number) => {
        return value
            ? this.clients.find(item => item.service_id === value)
                .display_name
            : undefined;
    }

    showFiltersWindow() {
        this.isShowFilters = !this.isShowFilters;
    }

    activeBurger(event) {
        this.active = event;
    }

    getStatusCellClass({ value }): any {
        return ` status--ticket status--${value}`;
    }

    getHeadlessCellClass({ value }: { value: boolean }): string {
        return value ? 'is-headless' : '';
    }

    private loadPage() {
        this.isLoading = true;
        this.counter += 1;
        this.filtersCounter = 0;
        Object.values(this.isFilterValue).forEach((item: any) => {
            if (item && !item.per_page) {
                this.filtersCounter += 1;
            }
        });

        const ticketTypeMaps = [[], ['merchant'], ['task']];
        this.isFilterValue.type = ticketTypeMaps[this.selectedTabIndex];
        this.ticketService
            .filterByTickets(
                this.counter,
                { ...this.isFilterValue, 'sort[field]': 'ticket_id', 'sort[direction]': 'desc' },
                this.perPageValue,
                'service'
            )
            .subscribe(data => {
                if (data.data.length > 0) {
                    this.rows = [...this.rows, ...data.data];
                    if (!data.next_page_url) {
                        this.isNoMoreTickets = true;
                    }
                } else {
                    this.isNoMoreTickets = true;
                }
                this.totalTickets = data.total;
                this.isLoading = false;
            });
    }

    private _filterClient(value: string) {
        const filterValue = value.toLowerCase();
        return this.clients.filter(item => {
            return item.display_name.toLowerCase().includes(filterValue) || item.name.toLowerCase().includes(filterValue);
        });
    }

    getCompanyLink(company) {
        return `/company/${company.id}`;
    }

    get rowHeight() {
        return 63;
    }

    onChangeTab(tab: NzTabChangeEvent) {
        this.selectedTabIndex = tab.index;
        this.onFilter(this.form.value);
    }

    showCreateTicketDialog() {
        this.modal.create({
            nzTitle: 'Create a new ticket',
            nzContent: CreateNewTicketComponent,
            nzComponentParams: {
                categories: this.ticketCategories
            },
            nzFooter: null
        });
    }
}
