import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-add-buttons',
    templateUrl: './add-buttons.component.html',
    styleUrls: ['./add-buttons.component.scss']
})
export class AddButtonsComponent implements OnInit {

    @Input() public query: string;
    @Input() public isNoCompanies: boolean;

    constructor() {}

    ngOnInit() {}
}
