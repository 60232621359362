import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

interface DialogData {
    adminName: string
}

@Component({
    selector: 'app-dialog-confirm-unlock-ticket',
    templateUrl: './dialog-confirm-unlock-ticket.component.html',
    styleUrls: ['./dialog-confirm-unlock-ticket.component.scss']
})
export class DialogConfirmUnlockTicketComponent implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<DialogConfirmUnlockTicketComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) { }

    ngOnInit() {
    }

    onNoClick() {
        this.dialogRef.close()
    }

}
