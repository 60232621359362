import { ProjectsModel } from '../../../models/projects.model';
import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, Label } from 'ng2-charts';

@Component({
    selector: 'app-projects-widget',
    templateUrl: './projects-widget.component.html',
    styleUrls: ['./projects-widget.component.scss']
})
export class ProjectsWidgetComponent implements OnInit {

    @Input() projectsList: any;
    @Input() isLoading: boolean;
    @Input() company: any;

    constructor() { }

    ngOnInit() {
    }
}
