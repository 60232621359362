export { ActivityWidgetComponent } from './activity-widget/activity-widget.component';
export { AtachmentsWidgetComponent } from './atachments-widget/atachments-widget.component';
export { ChartsWidgetComponent } from './charts-widget/charts-widget.component';
export { ContactDetailsWidgetComponent } from './contact-details-widget/contact-details-widget.component';
export { GoalsWidgetComponent } from './goals-widget/goals-widget.component';
export { InfoWidgetComponent } from './info-widget/info-widget.component';
export { ProjectsWidgetComponent } from './projects-widget/projects-widget.component';
export { TasksWidgetComponent } from './tasks-widget/tasks-widget.component';
export { TeamWidgetComponent } from './team-widget/team-widget.component';
export { RelatedCompaniesWidgetComponent } from './related-companies-widget/related-companies-widget.component';
export { ContractualInfoWidgetComponent } from './contractual-info-widget/contractual-info-widget.component';
export { StrategyWidgetComponent } from './strategy-widget/strategy-widget.component';