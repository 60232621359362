import { ThemeService } from './../../theme/theme.service';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ElementRef } from '@angular/core';
import { CompanyService } from '../../shared/services/company.service';
import { Router } from '@angular/router';
import {
    ACTIVITY_URL,
    COMPANIES_URL,
    PEOPLE_URL,
    PORTFOLIO_URL,
    CUSTOM_LISTS,
    SOCIAL_WALL,
    AM_PORTFOLIO_URL,
    MERCHANTS_FASTERPAY,
    MERCHANTS_PAYMENTWALL,
    MERCHANTS_TERMINAL3
} from '../../shared/const/constants.const';

@Component({
    selector: 'app-toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {
    @Input() query: string;

    @Output() typing: EventEmitter<string> = new EventEmitter<string>();
    @Output() activeBurger: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild('query', { static: false }) inputEl: ElementRef;

    active = false;

    isShow = false;

    companiesUrl = COMPANIES_URL;
    activityUrl = ACTIVITY_URL;
    peopleUrl = PEOPLE_URL;
    amPortfolioUrl = AM_PORTFOLIO_URL;
    customLists = CUSTOM_LISTS;
    socialwallUrl = SOCIAL_WALL;
    merchantsFasterPay = MERCHANTS_FASTERPAY;
    merchantsPaymentWall = MERCHANTS_PAYMENTWALL;
    merchantsTetminal3 = MERCHANTS_TERMINAL3;

    constructor(
        private companyService: CompanyService,
        private router: Router,
        public themeService: ThemeService
    ) { }

    ngOnInit() {
        this.active = false;
    }

    showInput() {
        this.isShow = !this.isShow;
        setTimeout(() => {
            if (this.inputEl) {
                this.inputEl.nativeElement.focus();
            }
        }, 0);
    }

    dataFromInput(event: any) {
        if (event.keyCode === 13) {
            this.router.navigate([`company-search/${event.target.value}`]);
        }
    }

    onBurgerClicked() {
        this.active = !this.active;
        this.activeBurger.emit(this.active);
    }

    toggle() {
        const active = this.themeService.getActiveTheme();
        if (active.name === 'light') {
            this.themeService.setTheme('dark');
            localStorage.setItem('mode', JSON.stringify({ mode: 'dark' }));
        } else {
            this.themeService.setTheme('light');
            localStorage.setItem('mode', JSON.stringify({ mode: 'light' }));
        }
    }
}
