import { Component, OnInit } from '@angular/core';
import { CompanyService } from 'src/app/shared/services/company.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { tap, switchMap, mergeMap } from 'rxjs/operators';
import { NoteItem } from 'src/app/models/notes.model';
import { GetNotesResponse } from 'src/app/shared/responses/get-notes.response';
import { ReferenceModel } from 'src/app/models/reference.model';
import { BASE_DOMAIN } from 'src/app/shared/const/constants.const';
import { AdminsModel } from 'src/app/models/admins.model';

@Component({
  selector: 'app-note-view',
  templateUrl: './note-view.component.html',
  styleUrls: ['./note-view.component.scss']
})
export class NoteViewComponent implements OnInit {

  active: boolean;
  note: any;
  noteTypes: any;
  admins: AdminsModel;
  isLoading = false;
  baseDomain = BASE_DOMAIN;

  constructor(
    private companyService: CompanyService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.isLoading = true;
    this.route.params.pipe(
      tap((params: Params) => {
        this.companyService.getReference()
          .pipe(
            tap((res: ReferenceModel) => {
              this.noteTypes = res.note_types;
            }),
            mergeMap(() => this.companyService.getAdmins()
              .pipe(
                tap((res: AdminsModel) => {
                  this.admins = res;
                })
              )
            ),
            mergeMap(() => this.companyService.getNoteById(params.id))
          ).subscribe(
            (res: any) => {
              if (res) {
                this.note = new GetNotesResponse(res, this.admins, this.noteTypes).getSignleNote();
                this.isLoading = false;
              } else {
                this.router.navigate(['/latest-correspondence']);
                this.isLoading = false;
              }
            },
            (err) => this.router.navigate(['/latest-correspondence']));
      })
    ).subscribe();
  }

  checkObj(o) {
    return typeof (o) !== 'object';
  }

  activeBurger(event) {
    this.active = event;
  }

}
