import { CompanyService } from "../../../shared/services/company.service";
import {
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges
} from "@angular/core";
import {
    FormControl,
    FormGroup,
    FormBuilder,
} from "@angular/forms";
import { switchMap, tap } from "rxjs/operators";
import { PermissionsService } from "src/app/shared/services/permissions.service";
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";

@Component({
    selector: "app-strategy-widget",
    templateUrl: "./strategy-widget.component.html",
    styleUrls: ["./strategy-widget.component.scss"]
})
export class StrategyWidgetComponent implements OnInit, OnChanges {
    @Input() public company: any;
    @Input() public id: number;
    @Input() public isLoading: boolean;

    public form: FormGroup;

    public isShowInput: boolean;

    public editor = ClassicEditor;

    public config = {
        toolbar: [
            "bold",
            "italic",
            "link",
            "bulletedList",
            "numberedList",
            "blockQuote"
        ]
    };

    public _company: any;

    public initializeFormValue() {
        this.form.controls['strategy'].setValue(this._company.strategy);

    }

    constructor(
        private companyService: CompanyService,
        public ps: PermissionsService,
        private _fb: FormBuilder
    ) { }

    ngOnInit() {
        this._company = this.company;
        this.form = this._fb.group({
            strategy: new FormControl("")
        });
        this.initializeFormValue();
    }

    ngOnChanges(changes: SimpleChanges): void {
    }

    onCancel() {
        this._hideEditor();
        this.initializeFormValue();
    }

    onSubmit() {
        if (this.form.valid) {
            this._editCompanyInfo();
            this._hideEditor();
        }
    }

    private _editCompanyInfo() {
        this.isLoading = true;
        this.companyService.editStrategy(this.id, this.form.value.strategy)
        .pipe(
            switchMap(() => this.companyService.getCompanyById(this.id)),
            tap(
                (res) => {
                    this._company.strategy = res.company.strategy;
                }),
        )
        .subscribe(
            () => {
                this.isLoading = false;
            }
        );
    }

    private _hideEditor() {
        this.isShowInput = false;
    }
}
