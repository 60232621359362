import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';

@Component({
    selector: 'app-filters-person',
    templateUrl: './filters-person.component.html',
    styleUrls: ['./filters-person.component.scss']
})
export class FiltersPersonComponent implements OnInit, OnChanges {

    @Input() noteTypes: any[];
    @Input() filterTypes: any[];
    @Input() allAdmins: any[];
    @Input() rootAvatar: string;

    @Output() filter: EventEmitter<any> = new EventEmitter<any>();

    isShowFilterActivity = false;
    isShowUsersFilter = false;

    types: string[] = [];

    userCounter = 0;

    filteredAdmin: any[] = [];

    adminInput: '';

    constructor() { }

    ngOnInit() {
    }

    ngOnChanges() {
        this.filteringAdmin();
        this.types = this.filterTypes;
        // if (this.noteTypes) {
        //     this.noteTypes.forEach(note => {
        //         note.checked = false;
        //     });
        // }
    }

    showFilter() {
        this.isShowFilterActivity = !this.isShowFilterActivity;
    }

    showUserFilter() {
        this.isShowUsersFilter = !this.isShowUsersFilter;
    }

    filteringAdmin(query?: string) {
        this.filteredAdmin = query
            ? this.allAdmins.filter(admin => admin.full_name.toLowerCase().includes(query.toLowerCase()))
            : this.allAdmins;
    }

    getTypes(value: string, event) {

        const eventChecked = event.checked;
        const index = this.types.indexOf(value);

        this.noteTypes.forEach(note => {
            if (note.id === value) {
                note.checked = eventChecked;
            } else if (note.id === value && note.checked && !eventChecked) {
                note.checked = eventChecked;
            }
        });

        if (this.types.length === 0) {
            this.types.push(value);
        } else if (this.types.length !== 0 && index >= 0) {
            this.types.splice(index, 1);
        } else {
            this.types.push(value);
        }

    }

    filterNotesByTypes() {
        this.filter.emit({ types: this.types });
        this.isShowFilterActivity = false;
    }


    filterNotesByUser(adminId: number) {
        this.userCounter = 0;
        this.userCounter += 1;
        this.adminInput = this.filteredAdmin.find(admin => admin.id === adminId).full_name;
        this.filteringAdmin(this.adminInput);
        this.filter.emit({ admin_id: adminId });
        this.isShowUsersFilter = false;
    }

    trackByFn(index: number, el: any) {
        return el.id;
    }

    clearFilters() {
        this.noteTypes.forEach(note => note.checked = false);
        this.types = [];
        this.userCounter = 0;
        this.adminInput = '';
        this.filteringAdmin();
        this.filter.emit({ types: this.types, admin_id: 'clear' });
    }
}
