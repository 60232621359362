export class GetContactsResponse {
    readonly res: any;

    constructor(res) {
        this.res = res;
    }

    getContacts() {
        return {
            phone: this.res.contacts.phone ? this.getArrayContact('phone') : [{ point: '', id: 0 }],
            website: this.res.contacts.website ? this.getArrayContact('website') : [{ point: '', id: 0 }],
            address: this.res.contacts.address ? this.getArrayContact('address') : [{ point: '', id: 0 }],
            fax: this.res.contacts.fax ? this.getArrayContact('fax') : [{ id: 0 }],
            social: this.res.contacts.social ? this.getArrayContact('social') : [{ point: '', id: 0 }],
            email: this.res.contacts.email ? this.getArrayContact('email') : [{ point: '', id: 0 }],
            zip_code: this.res.contacts.zip_code ? this.getArrayContact('zip_code') : [{ point: '', id: 0 }],
            po_box: this.res.contacts.po_box ? this.getArrayContact('po_box') : [{ point: '', id: 0 }],
            country: this.res.contacts.country ? this.getArrayContact('country') : [{ point: '', id: 0 }],
            city: this.res.contacts.city ? this.getArrayContact('city') : [{ point: '', id: 0 }],
            skype: this.res.contacts.skype ? this.getArrayContact('skype') : [{ point: '', id: 0 }],
            wechat: this.res.contacts.wechat ? this.getArrayContact('wechat') : [{ point: '', id: 0 }],
            facebook: this.res.contacts.facebook ? this.getArrayContact('facebook') : [{ point: '', id: 0 }],
            linkedin: this.res.contacts.linkedin ? this.getArrayContact('linkedin') : [{ point: '', id: 0 }],
        };
    }

    getArrayContact(contact) {
        return this.res.contacts[contact].map(item => {
            return {
                value: item.value,
                id: item.id
            };
        });
    }
}
