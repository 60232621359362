import { RemoveContactComponent } from './../../../shared/dialogs/remove-contact/remove-contact.component';
import { GetPeopleResponse } from './../../../shared/responses/get-people.response';
import { CompanyService } from 'src/app/shared/services/company.service';
import { AddEmployeeComponent } from './add-employee/add-employee.component';
import { MatDialog } from '@angular/material';
import {
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { List } from '../../../models/people.model';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { switchMap, map } from 'rxjs/operators';
import { GetContactsResponse } from 'src/app/shared/responses/get-contacts.response';
import { PermissionsService } from 'src/app/shared/services/permissions.service';

@Component({
    selector: 'app-contact-details-widget',
    templateUrl: './contact-details-widget.component.html',
    styleUrls: ['./contact-details-widget.component.scss']
})
export class ContactDetailsWidgetComponent implements OnInit, OnChanges {

    @Input() public contacts: any;
    @Input() public people: List[];
    @Input() public isLoading: boolean;
    @Input() public id: any;

    public form: FormGroup;
    public fb = new FormBuilder();

    public isShow: boolean;

    public isShowEmails = false;
    public isShowWebsites = false;
    public isShowPhones = false;
    public isShowIMs = false;
    public isShowFaxes = false;
    public isShowAddress = false;
    public isShowZipCode = false;
    public isShowPostBox = false;
    public isShowCountry = false;
    public isShowSkype = false;
    public isShowWechat = false;
    public isShowFacebook = false;
    public isShowLinkedin = false;
    public isShowCity = false;

    public isDisableEditBtn = true;

    constructor(
        public dialog: MatDialog,
        public companyService: CompanyService,
        public ps: PermissionsService,
    ) {
    }

    ngOnInit() {
        this.form = this.fb.group({
            email_items: this.fb.array([]),
            wechat_items: this.fb.array([]),
            skype_items: this.fb.array([]),
            facebook_items: this.fb.array([]),
            linkedin_items: this.fb.array([]),
            website_items: this.fb.array([]),
            phone_items: this.fb.array([]),
            address_items: this.fb.array([]),
            country_items: this.fb.array([]),
            city_items: this.fb.array([]),
            social_items: this.fb.array([]),
            fax_items: this.fb.array([]),
            zip_code_items: this.fb.array([]),
            post_box_items: this.fb.array([])
        });
    }

    get emailItems() { return this.form.get('email_items') as FormArray; }
    get socialItems() { return this.form.get('social_items') as FormArray; }
    get faxItems() { return this.form.get('fax_items') as FormArray; }
    get phoneItems() { return this.form.get('phone_items') as FormArray; }
    get websiteItems() { return this.form.get('website_items') as FormArray; }
    get cityItems() { return this.form.get('city_items') as FormArray; }
    get countryItems() { return this.form.get('country_items') as FormArray; }
    get postBoxItems() { return this.form.get('post_box_items') as FormArray; }
    get zipCodeItems() { return this.form.get('zip_code_items') as FormArray; }
    get addressItems() { return this.form.get('address_items') as FormArray; }
    get linkedinItems() { return this.form.get('linkedin_items') as FormArray; }
    get facebookItems() { return this.form.get('facebook_items') as FormArray; }
    get wechatItems() { return this.form.get('wechat_items') as FormArray; }
    get skypeItems() { return this.form.get('skype_items') as FormArray; }

    addPoint(items) {
        return items.push(this.fb.group({ point: '' }));
    }

    removePoint(index: number, items: FormArray) {

        const item = items.value[index];

        if (item.id) {
            this.dialog.open(RemoveContactComponent, {
                autoFocus: false,
                width: '496px',
                data: item,
                panelClass: 'remove-contact-modal'
            }).afterClosed().subscribe(res => {
                if (res) {
                    this.isLoading = true;
                    this.companyService.deleteContacts(res.data.id)
                        .pipe(
                            switchMap(() => this.companyService.getContactsByCompany(this.id))
                        )
                        .subscribe((data) => {
                            this.contacts = new GetContactsResponse(data).getContacts();
                            // this.setContols();
                            this.isLoading = false;
                            items.removeAt(index);
                        });
                }
            });
        } else {
            return items.removeAt(index);
        }

    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.form) { this.setContols(); }
    }

    setContols() {
        this.form.setControl('email_items', this.setItems(this.contacts.email));
        this.form.setControl('website_items', this.setItems(this.contacts.website));
        this.form.setControl('phone_items', this.setItems(this.contacts.phone));
        this.form.setControl('fax_items', this.setItems(this.contacts.fax));
        this.form.setControl('social_items', this.setItems(this.contacts.social));
        this.form.setControl('city_items', this.setItems(this.contacts.city));
        this.form.setControl('country_items', this.setItems(this.contacts.country));
        this.form.setControl('post_box_items', this.setItems(this.contacts.po_box));
        this.form.setControl('zip_code_items', this.setItems(this.contacts.zip_code));
        this.form.setControl('address_items', this.setItems(this.contacts.address));
        this.form.setControl('linkedin_items', this.setItems(this.contacts.linkedin));
        this.form.setControl('facebook_items', this.setItems(this.contacts.facebook));
        this.form.setControl('wechat_items', this.setItems(this.contacts.wechat));
        this.form.setControl('skype_items', this.setItems(this.contacts.skype));
    }

    setItems(items): FormArray {
        const formArray = new FormArray([]);

        items.forEach(item => {
            formArray.push(
                this.fb.group({
                    point: item.value,
                    id: item.id
                })
            );
        });

        return formArray;
    }

    changeStateOfEdit() {
        this.isDisableEditBtn = false;
    }

    onCancel(event?) {
        if (event && event.target.innerText === 'Cancel') {
            this._hideForm();
            this.setContols();
            this.isDisableEditBtn = true;
        }
    }

    onSubmit(data) {
        this._editContacts(data);
        this._hideForm();
    }

    onOpen() {
        this.dialog.open(AddEmployeeComponent, {
            autoFocus: false,
            width: '496px',
            panelClass: 'add-employee-modal'
        }).afterClosed()
            .subscribe((res) => {
                if (res) {
                    this.isLoading = true;
                    this.companyService.addPeople(res.value, this.id, res.icon)
                        .pipe(
                            switchMap(() => this.companyService.getPeopleByCompany(this.id))
                        )
                        .subscribe(data => {
                            this.people = new GetPeopleResponse(data).getPeople();
                            this.isLoading = false;
                        });
                }
            });
    }

    private _editContacts(data) {
        this.isLoading = true;
        this.companyService
            .editContacts(this.id, data)
            .pipe(switchMap(() => this.companyService.getContactsByCompany(this.id)))
            .subscribe(res => {
                this.contacts = new GetContactsResponse(res).getContacts();
                this.setContols();
                this.isLoading = false;
                this.isDisableEditBtn = true;
            });
    }

    private _hideForm() {
        this.isShowEmails = false;
        this.isShowWebsites = false;
        this.isShowPhones = false;
        this.isShowIMs = false;
        this.isShowFaxes = false;
        this.isShowAddress = false;
        this.isShowZipCode = false;
        this.isShowPostBox = false;
        this.isShowCountry = false;
        this.isShowCity = false;
        this.isShowSkype = false;
        this.isShowWechat = false;
        this.isShowFacebook = false;
        this.isShowLinkedin = false;
        this.isShow = false;
    }
}
