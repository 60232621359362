import { ProjectsModel, ApplicationItem } from '../../models/projects.model';

export class GetProjectsResponse {

    readonly res: ProjectsModel;
    readonly mids: any;

    constructor(res: ProjectsModel, mids?: any) {
        this.res = res;
        this.mids = mids;
    }

    getProjectsList() {

        let newArray = [];

        newArray = this.res.applications
            .map(item => item.mid)
            .filter((value, index, self) => {
                return self.indexOf(value) === index;
            })
            .map(item => {
                return {
                    mid: item,
                    projects: []
                };
            });

        newArray = this.res.applications.reduce((res, current) => {

            enum StatusColors {
                banned = '#ff210c',
                pending = '#ff7942',
                live = '#0fbd05',
                goLive = '#fd7e14',
                setupIsNotFinished = '#fd7e14',
                paused = '#e850e8',
            }

            enum StatusIcons {
                banned = '../../../assets/icons/stage-icons/dead.svg',
                pending = '../../../assets/icons/stage-icons/under_review.svg',
                live = '../../../assets/icons/stage-icons/live.svg',
                goLive = '../../../assets/icons/rocket.svg',
                setupIsNotFinished = '../../../assets/icons/stage-icons/integration_prepare_proposal.svg',
                paused = '../../../assets/icons/stage-icons/paused.svg'
            }

            switch (current.status) {
                case 'Banned':
                    current.color = StatusColors.banned;
                    current.status_icon = StatusIcons.banned;
                    break;
                case 'Pending':
                    current.color = StatusColors.pending;
                    current.status_icon = StatusIcons.pending;
                    break;
                case 'Live':
                    current.color = StatusColors.live;
                    current.status_icon = StatusIcons.live;
                    break;
                case 'Submit for Review':
                    current.color = StatusColors.goLive;
                    current.status_icon = StatusIcons.goLive;
                    break;
                case 'Set up is not finished':
                    current.color = StatusColors.setupIsNotFinished;
                    current.status_icon = StatusIcons.setupIsNotFinished;
                    break;
                case 'Processing Paused':
                    current.color = StatusColors.paused;
                    current.status_icon = StatusIcons.paused;
                    break;
            }

            const index = newArray.map(item => item.mid).indexOf(current.mid);

            res[index].projects.push({
                id: current.id,
                link: current.link,
                name: current.name,
                status: current.status,
                color: current.color,
                status_icon: current.status_icon
            });

            this.mids.forEach(item => {
                if (current.mid === item.id) {
                    res[index].url = item.url;
                }
            });

            return res;

        }, newArray);

        return newArray;

    }

    getProjectsListForDropDown() {
        return this.res.applications.map(item => {
            return {
                id: item.id,
                name: item.name,
                link: item.link
            };
        });
    }
}
