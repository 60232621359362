import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import {
    MAT_DIALOG_DATA,
    MatAutocomplete,
    MatAutocompleteSelectedEvent,
    MatChipInputEvent,
    MatDialog, MatDialogRef
} from '@angular/material';
import { Observable } from 'rxjs';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { startWith } from 'rxjs/internal/operators/startWith';
import { map } from 'rxjs/operators';
import { AssignedTeamModel } from '../../../../models/assigned-team.model';
import { CompanyService } from '../../../../shared/services/company.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
    selector: 'app-dialog',
    templateUrl: './dialog.component.html',
    styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent implements OnInit, OnDestroy {

    fileName;
    fileList: any[] = [];
    fileSize: number;
    form: FormGroup;

    isShowDraftMessage = false;
    isMonitoringNote = false;
    isInterview = false;

    config = {
        toolbar: ['bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote'],
    };

    editor = ClassicEditor;

    hireData = [
        { id: 1, title: 'Yes' },
        { id: 0, title: 'No' },
    ];

    // public selectable = true;
    // public removable = true;
    // public addOnBlur = true;
    //
    // public separatorKeysCodes: number[] = [ENTER, COMMA];

    // public teamCtrl = new FormControl();

    // public filteredTeam: Observable<string[]>;
    // public team: string[] = [];

    // @ViewChild('teamInput', {static: false}) teamInput: ElementRef<HTMLInputElement>;
    // @ViewChild('auto', {static: false}) matAutocomplete: MatAutocomplete;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private companyService: CompanyService,
        private fb: FormBuilder,
        public dialogRef: MatDialogRef<DialogComponent>,
    ) {
        // this.filteredTeam = this.teamCtrl.valueChanges.pipe(
        //     startWith(null),
        //     map((team: string | null) => team ? this._filter(team) : this.team.slice()));
    }

    //
    // add(event: MatChipInputEvent): void {
    //     if (!this.matAutocomplete.isOpen) {
    //
    //         const input = event.input;
    //         const value = event.value;
    //
    //         if ((value || '').trim()) {
    //             this.team.push(value.trim());
    //         }
    //
    //         if (input) {
    //             input.value = '';
    //         }
    //
    //         this.teamCtrl.setValue(null);
    //     }
    // }
    //
    // remove(team: string): void {
    //     const index = this.team.indexOf(team);
    //
    //     if (index >= 0) {
    //         this.team.splice(index, 1);
    //     }
    // }
    //
    // selected(event: MatAutocompleteSelectedEvent): void {
    //     this.team.push(event.option.viewValue);
    //     this.teamInput.nativeElement.value = '';
    //     this.teamCtrl.setValue(null);
    // }
    //
    // private _filter(value: string): string[] {
    //     const filterValue = value.toLowerCase();
    //
    //     return this.team.filter(team => team.toLowerCase().indexOf(filterValue) === 0);
    // }

    ngOnInit() {
        // this.team = this.data.map((item: AssignedTeamModel) => {
        //     return item.full_name;
        // });

        let noteType: string;

        switch (this.data.buttonName) {
            case 'Log Email':
                noteType = 'email';
                break;
            case 'Log Note':
                noteType = 'note';
                break;
            case 'Log Call':
                noteType = 'call';
                break;
            case 'Log Meeting':
                noteType = 'meeting';
                break;
            default:
                noteType = '';
        }

        this.form = new FormGroup({
            note_type: new FormControl(noteType, [Validators.required]),
            note_subject: new FormControl('', [Validators.required]),
            note_body: new FormControl('', [Validators.required]),
            application: new FormControl(''),
            score: new FormControl('', Validators.pattern(/^-?\d*(\.\d+)?$/)),
            hire: new FormControl(''),
        });

        const LOCALSTORAGE_DATA = JSON.parse(localStorage.getItem('draft'));

        if (LOCALSTORAGE_DATA && LOCALSTORAGE_DATA.company_id === this.data.company_id) {

            this.isShowDraftMessage = true;

            switch (LOCALSTORAGE_DATA.note_type) {
                case 'monitoring':
                    this.isMonitoringNote = true;
                    break;
                case 'interview_feedback':
                    this.isInterview = true;
                    break;
                default:
                    this.isMonitoringNote = false;
                    this.isInterview = false;
            }

            this.form.patchValue({
                note_type: LOCALSTORAGE_DATA.note_type,
                note_subject: LOCALSTORAGE_DATA.note_subject,
                note_body: LOCALSTORAGE_DATA.note_body,
                score: LOCALSTORAGE_DATA.score,
                hire: LOCALSTORAGE_DATA.hire,
                application: LOCALSTORAGE_DATA.application
            });

            if (LOCALSTORAGE_DATA.files) {
                this.fileList = LOCALSTORAGE_DATA.files;
            }
        }
    }

    onFileChange(event) {

        if (event.target.files && event.target.files[0]) {

            const filesAmount = event.target.files.length;

            for (let i = 0; i < filesAmount; i++) {

                const name = event.target.files[i].name;
                const size = this._formatBytes(event.target.files[i].size);

                const reader = new FileReader();

                reader.onload = (readerEvent: any) => {

                    if (typeof readerEvent.target.result === 'string') {
                        const result = readerEvent.target.result.split(',')[1];

                        this.fileList.push({
                            name,
                            size,
                            cleanSize: event.target.files[i].size,
                            content: result
                        });
                    }
                };
                reader.readAsDataURL(event.target.files[i]);
            }
        }
    }

    trackByFn(index: number, el: any) {
        return el.id;
    }

    showAdditionalFields(value: string) {
        (value === 'Monitoring Entry') ? this.isMonitoringNote = true : this.isMonitoringNote = false;
        (value === 'Interview feedback') ? this.isInterview = true : this.isInterview = false;
    }

    onRemoveFile(name: string) {
        this.fileList = this.fileList.filter(item => item.name !== name);
    }

    onCloseWindow() {
        this.dialogRef.close();
    }

    onSubmit(value) {

        const sum = this._sumOfSize(this.fileList);

        if (sum >= 26214400) {
            return;
        } else {
            value.files = this.fileList;
            this.dialogRef.close(value);
        }

    }

    private _formatBytes(bytes: number, decimals = 2) {

        if (bytes === 0) { return '0 Bytes'; }

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    ngOnDestroy(): void {
        const value = this.form.value;

        const sum = this._sumOfSize(this.fileList);

        value.company_id = this.data.company_id;

        if (sum <= 4194304) {
            value.files = this.fileList;
            if (this.form.dirty) { localStorage.setItem('draft', JSON.stringify(value)); }
        }

    }

    private _sumOfSize(arr: any) {
        return arr.reduce((a, b) => {
            return a + b.cleanSize;
        }, 0);
    }
}
