import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
    selector: 'app-access-modal',
    templateUrl: './access-modal.component.html',
    styleUrls: ['./access-modal.component.scss']
})
export class AccessModalComponent implements OnInit {

    constructor(
        @Inject(MAT_DIALOG_DATA) public error: any,
        public dialogRef: MatDialogRef<AccessModalComponent>,
    ) { }

    ngOnInit() {
        setTimeout(() => {
            this.dialogRef.close();
        }, 3000)
    }

    onCancelClick() {
        this.dialogRef.close();
    }
}
