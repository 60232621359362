import { AclModel } from './../../models/acl.model';
import { ReferenceModel, CompanySubtypeItem, CandidateSubtypeItem, CurrencyItem } from './../../models/reference.model';
import { PersonModel } from './../../models/person.model';
import { SuccessLoadReference } from 'src/app/redux/actions/reference.action';
import { AppState } from 'src/app/redux/app.state';
import { Store, select } from '@ngrx/store';
import { ErrorService } from './../../shared/services/error.service';
import { CompanyService } from 'src/app/shared/services/company.service';
import { Subscription, of } from 'rxjs';
import { PeopleService } from './../../shared/services/people.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { List } from 'src/app/models/people.model';
import { tap, switchMap, mergeMap } from 'rxjs/operators';
import { AdminsModel, AdminItem } from 'src/app/models/admins.model';
import { GetNotesResponse } from 'src/app/shared/responses/get-notes.response';
import { GetReferenceResponse } from 'src/app/shared/responses/get-reference.response';
import { GetContactsResponse } from 'src/app/shared/responses/get-contacts.response';
import { OLD_PERSON_PAGE, BASE_URL } from 'src/app/shared/const/constants.const';
import { SuccessLoadPerson, SuccessLoadRolesData, SuccessLoadAdminsData } from 'src/app/redux/actions/person.action';
import { ThemeService } from 'src/app/theme/theme.service';
import { MatDialog } from '@angular/material';
import { FullAccessRequestModalComponent } from 'src/app/shared/dialogs/full-access-request-modal/full-access-request-modal.component';
import { PermissionsService } from 'src/app/shared/services/permissions.service';
import { GetPeopleByIdResponse } from 'src/app/shared/responses/get-people-by-id.response';
import { OfficesModel, OfficeItem } from 'src/app/models/offices.model';

@Component({
    selector: 'app-person-page-layout',
    templateUrl: './person-page-layout.component.html',
    styleUrls: ['./person-page-layout.component.scss']
})
export class PersonPageLayoutComponent implements OnInit, OnDestroy {

    id: number;
    companyId: number;
    person: PersonModel;
    company: any;
    type: any;
    types: any[];
    subtypes: CandidateSubtypeItem[];
    subtype: any;
    referral: any;
    sources: any[];
    source: any;
    offices: OfficesModel;
    office: OfficeItem;
    summary: string;
    contacts: any;
    loggedAdmin: number;
    currencies: CurrencyItem[];

    allAdmins: AdminItem[] = [];
    rootAvatar: string;

    noteTypes: any[];
    notes: any[] = [];

    isLoadingInfoWidget = false;
    isLoadingNotes = false;
    isLoadingContactsWidget = false;
    isLoadingAssignWidget = false;

    counterLimit = 5;

    oldPersonPageUrl: string;

    active: boolean;

    sub1: Subscription;

    constructor(
        private route: ActivatedRoute,
        private peopleService: PeopleService,
        private companyService: CompanyService,
        private store: Store<AppState>,
        private themeService: ThemeService,
        public ps: PermissionsService,
        public dialog: MatDialog,
    ) {
        this.themeService.getThemeFromLocalStorage();
    }

    ngOnInit() {

        this.sub1 = this.route.params.subscribe((params: Params) => {

            this.route.queryParams.subscribe((query: any) => {
                this.companyId = query.company_id;
            });

            this.id = params.id;
            this.oldPersonPageUrl = OLD_PERSON_PAGE;

            this.company = '';

            this._showLoaders();

            this.companyService.getReference()
                .pipe(
                    tap((data: ReferenceModel) => {

                        this.types = data.company_types;
                        this.subtypes = data.candidate_subtypes;
                        this.sources = data.sources;
                        this.noteTypes = data.note_types;
                        this.currencies = data.job_currencies;
                        this.loggedAdmin = data.logged_in_admin.id;

                        this.store.dispatch(new SuccessLoadRolesData(data.admin_roles));

                    }),
                    mergeMap(() => this.peopleService.getOffices()
                        .pipe(
                            tap((res: OfficesModel) => {
                                this.offices = res;
                            })
                        )
                    ),
                    mergeMap(() => this.ps.getPersonPermissions(this.loggedAdmin, this.id)
                        .pipe(
                            tap((res: AclModel) => {
                                this.ps.checkPersonPagePermissions(res, this.companyId);
                            })
                        )
                    ),
                    mergeMap(() => this.peopleService.getPeopleById(this.id)
                        .pipe(
                            tap((data: PersonModel) => {

                                const people = new GetPeopleByIdResponse();

                                this.person = data;

                                this.store.dispatch(new SuccessLoadPerson(data));

                                this.type = people.serializePerson(this.types, this.person.person.type);
                                this.subtype = people.serializePerson(this.subtypes, this.person.person.subtype);
                                this.source = people.serializePerson(this.sources, this.person.person.source);
                                this.office = people.serializeOffice(this.offices, this.person.person.office_id);
                                this.isLoadingAssignWidget = false;
                            })
                        )),
                    mergeMap(() => this.person.person.company_id !== 0
                        ? this.companyService.getCompanyById(this.person.person.company_id)
                            .pipe(
                                tap((data: any) => {
                                    this.company = data.company;
                                    this.isLoadingInfoWidget = false;
                                })
                            )
                        : of(this.company = {})
                    ),
                    mergeMap(() => this.companyService.getAdmins()
                        .pipe(
                            tap((data: any) => {

                                this.store.dispatch(new SuccessLoadAdminsData(data));

                                if (this.person.person.company_id === 0) {
                                    this.isLoadingInfoWidget = false;
                                }

                                const people = new GetPeopleByIdResponse();

                                this.allAdmins = data.admins;
                                this.referral = people.serializePerson(data.admins, parseInt(this.person.person.referral_admin_id, 10));
                                this.rootAvatar = data.extra_data.root_avatar_url;
                            })
                        )
                    ),
                    mergeMap(() => this.ps.accessPersonState ? this.peopleService.getNotesByPerson(this.id, this.counterLimit)
                        .pipe(
                            tap((data: any) => {
                                this.notes = new GetNotesResponse(data, this.allAdmins, this.noteTypes).getNotes();
                                this.isLoadingNotes = false;
                            })
                        ) : of(null, this.isLoadingNotes = false)
                    ),
                    mergeMap(() => this.ps.accessPersonState ? this.peopleService.getContactsByPerson(this.id)
                        .pipe(
                            tap((data: any) => {
                                this.contacts = new GetContactsResponse(data).getContacts();
                                this.isLoadingContactsWidget = false;
                            })
                        ) : of(null, this.isLoadingContactsWidget = false)
                    )
                )
                .subscribe(
                    () => of(null));
        });
    }

    private _showLoaders() {
        this.isLoadingInfoWidget = true;
        this.isLoadingNotes = true;
        this.isLoadingContactsWidget = true;
        this.isLoadingAssignWidget = true;
    }

    refresh(data) {
        this.person = data;
    }

    activeBurger(event) {
        this.active = event;
    }

    onOpenAccessModal() {

        const PERSON_URL = `${BASE_URL}/#/person/${this.id}`;

        this.dialog.open(FullAccessRequestModalComponent, {
            data: {
                url: PERSON_URL,
            },
            width: '496px',
            panelClass: 'full-access-dialog',
            autoFocus: false
        });
    }

    ngOnDestroy() {
        this.sub1.unsubscribe();
    }
}
