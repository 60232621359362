import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
    name: 'truncate'
})

export class Truncate implements PipeTransform {

    transform(value: any, limit: number = 100, trail: string = '...'): string {
        if (value === null) {
            return '';
        }
        return value.length > limit ? value.substring(0, limit) + trail : value;
    }
}
