import { SuccessLoadReference } from 'src/app/redux/actions/reference.action';
import { ReferenceModel } from './models/reference.model';
import { CompanyService } from './shared/services/company.service';
import { AuthTicketService } from './shared/services/auth-ticket.service';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { AppState } from './redux/app.state';
import { Store } from '@ngrx/store';
import { SuccessLoadUserInfo } from './redux/actions/person.action';
// import * as TicketActions from './redux/actions/ticket.action';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    authenticating = false;

    constructor(
        private titleService: Title,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private companyService: CompanyService,
        private store: Store<AppState>,
        private auth: AuthTicketService
    ) {
    }

    async ngOnInit() {
        this._titleFunc();
    }

    private _titleFunc() {
        const appTitle = this.titleService.getTitle();

        this.router
            .events.pipe(
                filter(event => event instanceof NavigationEnd),
                map(() => {
                    const child = this.activatedRoute.firstChild;
                    if (child.snapshot.data.title) {
                        return child.snapshot.data.title;
                    }
                    return appTitle;
                })
            ).subscribe((ttl: string) => {
                this.titleService.setTitle(ttl);
            });
    }

    private _referenceData() {
        this.companyService.getReference()
            .subscribe((res: ReferenceModel) => {
                this.store.dispatch(new SuccessLoadReference(res));
            });
    }
}
