import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CrmApi } from 'src/app/helpers/crm-api/crm-api';
import { BootstrappedAdmin } from 'src/app/models/admins.model';
import { Client } from 'src/app/models/services.model';
import { TicketCategory, TicketProblem } from 'src/app/models/ticket.model';

interface BootstrapData {
    ticket_statuses: string[];
    ticket_log_actions: string[];
    ticket_problems?: TicketProblem[];
    ticket_categories?: TicketCategory[];
    admins?: BootstrappedAdmin[];
    clients?: Client[];
}

@Injectable({
    providedIn: 'root'
})
export class BootstrappingService extends CrmApi {
    constructor(public http: HttpClient) {
        super(http);
    }

    bootstrappingData(include?: string[]): Observable<{ data: BootstrapData }> {
        return this.get(`/bootstrapping`, { include: include.join() });
    }
}
