import { AddTaskComponent } from './add-task/add-task.component';
import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { QUEUE_MESSAGES, QUEUE } from 'src/app/shared/const/constants.const';
import * as moment from 'moment';
import { MatDialog } from '@angular/material';

@Component({
    selector: 'app-tasks-widget',
    templateUrl: './tasks-widget.component.html',
    styleUrls: ['./tasks-widget.component.scss']
})
export class TasksWidgetComponent implements OnInit, OnChanges {

    @Input() tasks: any[];
    @Input() id: number;
    @Input() isLoading: boolean;
    @Input() allAdmins: any[];
    @Input() rootAvatar: string;

    isNoTasks: boolean;
    isPastDate: boolean = false;
    queueMessages = QUEUE_MESSAGES;
    allQueue = QUEUE;

    constructor(
        private dialog: MatDialog,
    ) {
    }

    ngOnInit() {
    }

    ngOnChanges(): void {
        if (this.tasks) {
            this.isNoTasks = false;
            this.allAdmins.forEach(admin => {
                this.tasks.forEach(task => {

                    if (task.assigned_to === admin.id) {
                        task.assigned_name = admin.full_name;
                    }

                    const date = moment(new Date(task.due_dt));
                    const oneDayAgo = moment().subtract(1, 'days').format('ll');

                    if (
                        moment().diff(date, 'days') >= 1 ||
                        moment(new Date(task.due_dt)).format('ll') === oneDayAgo
                    ) {
                        task.past_date = true;
                    }
                })
            })
        } else {
            this.tasks = [];
            this.isNoTasks = true;
        }
    }

    onOpen() {
        this.dialog.open(AddTaskComponent, {
            width: '496px',
            height: 'auto',
            autoFocus: false,
            panelClass: 'add-task-modal',
            data: {
                admins: this.allAdmins,
                avatar: this.rootAvatar,
            }
        });
    }
}
