import { environment } from '../../../environments/environment';
import * as moment from 'moment';

/**
 * BASE CONST
 */
export const BASE_API_URL = `${environment.BASE_DOMAIN}${environment.BASE_API_URL}`;
export const BASE_ADMIN_LOGIN_URL = `${environment.BASE_DOMAIN}${environment.BASE_ADMIN_LOGIN_URL}`;
export const BASE_URL = environment.BASE_URL;
export const BASE_DOMAIN = environment.BASE_DOMAIN;
export const CRM_API_URL = environment.CRM_API_URL;
export const CRM_OAUTH_URL = `${CRM_API_URL}/auth/login`;

/**
 * NAVIGATION TOOLBAR CONST
 */
export const COMPANIES_URL = `${environment.BASE_DOMAIN}${environment.COMPANIES_URL}`;
export const ACTIVITY_URL = `${environment.BASE_DOMAIN}${environment.ACTIVITY_URL}`;
export const PEOPLE_URL = `${environment.BASE_DOMAIN}${environment.PEOPLE_URL}`;
export const PORTFOLIO_URL = `${environment.BASE_DOMAIN}${environment.PORTFOLIO_URL}`;
export const AM_PORTFOLIO_URL = `${environment.BASE_DOMAIN}${environment.AM_PORTFOLIO_URL}`;
export const CUSTOM_LISTS = `${environment.BASE_DOMAIN}${environment.CUSTOM_LISTS}`;
export const SOCIAL_WALL = `${environment.BASE_DOMAIN}${environment.SOCIAL_WALL}`;
export const QUEUE_MESSAGES = `${environment.BASE_DOMAIN}${environment.QUEUE_MESSAGES}`;
export const QUEUE = `${environment.BASE_DOMAIN}${environment.QUEUE}`;
export const MERGE = `${environment.BASE_DOMAIN}${environment.MERGE}`;
export const MERCHANTS_FASTERPAY = `${environment.MERCHANTS_FASTERPAY}`;
export const MERCHANTS_PAYMENTWALL = `${environment.BASE_DOMAIN}${environment.MERCHANTS_PAYMENTWALL}`;
export const MERCHANTS_TERMINAL3 = `${environment.MERCHANTS_TERMINAL3}`;

/**
 * CHARTS CONST
 */
export const VOLUME = 'processing_volume';
export const REVENUE = 'revenue';
export const USERS = 'users';

export const SEVEN_DAYS_AGO = moment().subtract(7, 'days').format('L');

export const START_LAST_MONTH = moment().subtract(1, 'months').startOf('months').format('L');
export const END_LAST_MONTH = moment().subtract(1, 'months').endOf('months').format('L');

export const START_WEEK = moment().startOf('isoWeek').format('L');

export const START_LAST_WEEK = moment().subtract(1, 'weeks').startOf('isoWeek').format('L');
export const END_LAST_WEEK = moment().subtract(1, 'weeks').endOf('isoWeek').format('L');

export const START_MONTH = moment().startOf('months').format('L');

export const START_QUARTER = moment().startOf('quarter').format('L');

export const START_LAST_QUARTER = moment().subtract(1, 'Q').startOf('quarter').format('L');
export const END_LAST_QUARTER = moment().subtract(1, 'Q').endOf('quarter').format('L');

export const START_YEAR = moment().startOf('years').format('L');

export const START_LAST_YEAR = moment().subtract(1, 'years').startOf('years').format('L');
export const END_LAST_YEAR = moment().subtract(1, 'years').endOf('years').format('L');

export const CURRENT_DATE = moment().format('L');

export const DEFAULT_DATE_FROM = moment().subtract(7, 'days').format('L');
export const DEFAULT_DATE_TO = moment().format('L');

export const OLD_PAGE = `${environment.BASE_DOMAIN}${environment.OLD_PAGE_URL}`;
export const OLD_PERSON_PAGE = `${environment.BASE_DOMAIN}${environment.OLD_PERSON_PAGE_URL}`;

export const COMPANY_STAGE_REQUIRE_CONTRACTUAL_INFO = [5, 14, 15]
