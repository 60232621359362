import { Component, OnInit, Input } from '@angular/core';
import {
    PEOPLE_URL,
    COMPANIES_URL,
    ACTIVITY_URL,
    PORTFOLIO_URL,
    CUSTOM_LISTS,
    SOCIAL_WALL,
    AM_PORTFOLIO_URL,
    MERCHANTS_FASTERPAY,
    MERCHANTS_PAYMENTWALL,
    MERCHANTS_TERMINAL3
} from 'src/app/shared/const/constants.const';

@Component({
    selector: 'app-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

    @Input() active: boolean;

    staticRoutes = [
        { name: 'Custom lists', url: CUSTOM_LISTS },
        { name: 'Merchants - FasterPay', url: MERCHANTS_FASTERPAY },
        { name: 'Merchants - Paymentwall', url: MERCHANTS_PAYMENTWALL },
        { name: 'Merchants - Terminal3', url: MERCHANTS_TERMINAL3 },
        { name: 'Socialwall', url: SOCIAL_WALL },
    ];

    appRoutes = [
        { name: 'Add company', url: '/company-create' },
        { name: 'All companies', url: '/all-companies' },
        { name: 'My Deals', url: '/my-deals' },
        { name: 'People', url: '/people' },
        { name: 'Latest Correspondence', url: '/latest-correspondence' },
        { name: 'Tickets', url: '/tickets' },
    ];

    constructor() { }

    ngOnInit() {
    }

}
