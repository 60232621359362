import { AppState } from 'src/app/redux/app.state';
import { PersonModel } from './../../../models/person.model';
import { switchMap } from 'rxjs/operators';
import { PeopleService } from './../../../shared/services/people.service';
import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Store, select } from '@ngrx/store';
import { PermissionsService } from 'src/app/shared/services/permissions.service';

@Component({
    selector: 'app-summary',
    templateUrl: './summary.component.html',
    styleUrls: ['./summary.component.scss']
})
export class SummaryComponent implements OnInit, OnChanges {

    @Input() isLoading: boolean;
    @Input() id: number;

    isShowSummary = false;
    isShow = false;

    isDisableSaveButton = true;

    person: PersonModel;

    editor = ClassicEditor;

    model = { summary: '' }

    config = {
        toolbar: ['bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote']
    }

    constructor(
        public peopleService: PeopleService,
        public ps: PermissionsService,
        private store: Store<AppState>
    ) {
        this.store.pipe(select('person')).subscribe(store => this.person = store);
    }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.model.summary = this.person ? this.person.person.summary : '';
    }

    hideEditMode() {
        this.isShowSummary = false;
        this.isShow = false;
    }

    changeStateOfSaveButton() {
        this.isDisableSaveButton = false;
    }

    onCancel() {
        this.hideEditMode();
        this.isDisableSaveButton = true;
        this.model.summary = this.person ? this.person.person.summary : '';
    }

    onSubmit() {
        this.isLoading = true;
        this.peopleService.editPerson(this.id, this.model)
            .pipe(
                switchMap(() => this.peopleService.getPeopleById(this.id)
                )
            )
            .subscribe(res => {
                this.person = res;
                this.hideEditMode();
                this.isLoading = false;
                this.isDisableSaveButton = true;
            });
    }

}
