import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-deletion-person',
  templateUrl: './deletion-person.component.html',
  styleUrls: ['./deletion-person.component.scss']
})
export class DeletionPersonComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DeletionPersonComponent>,
  ) { }

  ngOnInit() {
  }

  onCancelClick() {
    this.dialogRef.close();
  }

  onDeleteAdmin() {
    this.data.formTeamArr.removeAt(this.data.index);
    this.data.formRoleArr.removeAt(this.data.index);
    this.dialogRef.close();
  }

}
