import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MERGE } from 'src/app/shared/const/constants.const';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

    @Input() public company: any;
    @Input() public id: any;

    mergeUrl: string;

    constructor() {
        this.mergeUrl = MERGE;
    }

    ngOnInit() {
    }
}
