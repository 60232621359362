import { Observable, Observer } from 'rxjs';
import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatTabChangeEvent, MatTabGroup } from '@angular/material';
import { DialogComponent } from './dialog/dialog.component';
import { GetNotesResponse } from 'src/app/shared/responses/get-notes.response';
import { CompanyService } from 'src/app/shared/services/company.service';
import { NotesModel } from 'src/app/models/notes.model';
import { AssignedTeamModel } from '../../../models/assigned-team.model';
import { switchMap } from 'rxjs/operators';
import { NgxPermissionsService } from 'ngx-permissions';
import { PermissionsService } from 'src/app/shared/services/permissions.service';

@Component({
    selector: 'app-activity-widget',
    templateUrl: './activity-widget.component.html',
    styleUrls: ['./activity-widget.component.scss'],
})
export class ActivityWidgetComponent implements OnInit, OnChanges {

    @ViewChild(MatTabGroup, { static: false }) tabGroup: MatTabGroup;

    @Input() public notes: any[];
    @Input() public counter: number;
    @Input() public id: number;
    @Input() public isLoading: boolean;
    @Input() public assignedTeam: AssignedTeamModel[] = [];
    @Input() public allAdmins: [];
    @Input() public noteTypes: any[];
    @Input() public projects: any[];
    @Input() public isAssignAccess: any;
    @Input() public loggedUser: number;
    @Input() public rootAvatar: any;

    public filterTypes: string[];
    public filterAdmin: number;
    public type: any;
    public admin: any;

    public totalCount: number;

    public asyncTabs: Observable<any[]>;

    public initialLoadNotes = false;

    constructor(
        public dialog: MatDialog,
        public ps: PermissionsService,
        private companyService: CompanyService,
    ) {
        this.asyncTabs = new Observable((observer: Observer<any[]>) => {
            setTimeout(() => {
                observer.next([
                    { label: 'All Activity', buttonName: 'Log Activity', isShowFilter: true },
                    { label: 'Notes', buttonName: 'Log Note', isShowFilter: false },
                    { label: 'Emails', buttonName: 'Log Email', isShowFilter: false },
                    { label: 'Calls', buttonName: 'Log Call', isShowFilter: false },
                    { label: 'Meetings', buttonName: 'Log Meeting', isShowFilter: false },
                ]);
            }, 1000);
        });
    }

    ngOnInit() {
    }

    ngOnChanges() {
        this.filterTypes = [];
        if (this.tabGroup) { this.tabGroup.selectedIndex = 0; }
    }

    showMoreNotes() {
        this._onGetNotes(5);
    }

    filterByTab(event: MatTabChangeEvent) {
        switch (event.tab.textLabel) {
            case 'All Activity':
                this.type = this.filterTypes;
                this.admin = this.filterAdmin;
                break;
            case 'Notes':
                this.type = 'note';
                this.admin = null;
                this.initialLoadNotes = true;
                break;
            case 'Emails':
                this.type = 'email';
                this.admin = null;
                this.initialLoadNotes = true;
                break;
            case 'Calls':
                this.type = 'call';
                this.admin = null;
                this.initialLoadNotes = true;
                break;
            case 'Meetings':
                this.type = 'meeting';
                this.admin = null;
                this.initialLoadNotes = true;
                break;
        }
        if (this.initialLoadNotes) { this._onGetNotes(); }
    }

    getFilterTypes(data) {

        if (data.admin_id) {
            this.admin = data.admin_id;
            this.filterAdmin = data.admin_id;
        } else {
            this.type = data.types;
            this.filterTypes = data.types;
        }

        if (data.types && data.types.length === 0) {
            this.type = [];
        }

        this._onGetNotes();
    }

    openDialog(buttonName: string): void {
        this.dialog.open(DialogComponent, {
            width: '520px',
            height: 'auto',
            data: {
                team: this.assignedTeam,
                note_types: this.noteTypes,
                projects: this.projects,
                company_id: this.id,
                buttonName,
                isCompanyPage: true
            },
            panelClass: 'activity-dialog',
        }).afterClosed()
            .subscribe(data => {
                if (data) {
                    this._addNote(data);
                }
            });
    }

    trackByFn(index: number, el: any) {
        return el.label;
    }

    private _addNote(data) {
        this.isLoading = true;
        this.companyService.addNote(this.id, data).pipe(
            switchMap(() => this.companyService.getNotes(this.id, this.counter)))
            .subscribe(res => {
                localStorage.clear();
                this.notes = new GetNotesResponse(res, this.allAdmins, this.noteTypes).getNotes();
                this.isLoading = false;
            });
    }

    private _onGetNotes(counter?: number) {
        this.isLoading = true;
        counter ? this.counter += counter : this.counter;
        this.companyService.getNotes(this.id, this.counter, this.type, this.admin)
            .subscribe((res: NotesModel) => {
                if (this.noteTypes) {
                    this.notes = [];
                    this.notes = new GetNotesResponse(res, this.allAdmins, this.noteTypes).getNotes();
                    this.totalCount = res.notes.total_count;
                    this.isLoading = false;
                }
            });
    }
}
