import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { DialogConfirmUnlockTicketComponent } from '../ticket-merchant/ticket-conversation/dialog-confirm-unlock-ticket/dialog-confirm-unlock-ticket.component';

@Component({
    selector: 'app-confirm-resolve-ticket-dialog',
    templateUrl: './confirm-resolve-ticket-dialog.component.html',
    styleUrls: ['./confirm-resolve-ticket-dialog.component.scss']
})
export class ConfirmResolveTicketDialogComponent implements OnInit {
    constructor(
        public dialogRef: MatDialogRef<DialogConfirmUnlockTicketComponent>,
    ) { }

    ngOnInit() {
    }

    close() {
        this.dialogRef.close()
    }
}
