import { AfterViewInit, ElementRef, Input, OnChanges, Directive } from '@angular/core';

@Directive({
    selector: '[read-more]'
})

export class ReadMoreDirective implements AfterViewInit, OnChanges {

    @Input('readMore-length') private maxLength: number;
    @Input('readMore-element') private elementChange: HTMLElement;

    private currentText: string;
    private text: string;

    private hideToggle = true;
    private isCollapsed = true;

    constructor(private el: ElementRef) {
    }

    public ngAfterViewInit() {

        this.text = this.elementChange.innerHTML;

        this.toggleView();

        if (!this.hideToggle) {
            this.el.nativeElement.classList.remove('hidden');
        } else {
            this.el.nativeElement.classList.add('hidden');
        }

        this.el.nativeElement.addEventListener('click', (event: MouseEvent) => {
            event.preventDefault();
            this.toggleView();
        });
    }

    public ngOnChanges() {
        if (this.text) {
            this.toggleView();
        }
    }

    private toggleView(): void {

        this.determineView();

        this.isCollapsed = !this.isCollapsed;

        if (this.isCollapsed && this.currentText.length >= this.maxLength) {
            this.el.nativeElement.querySelector('.more').style.display = 'none';
            this.el.nativeElement.querySelector('.less').style.display = 'inherit';
        } else if (this.text.length <= this.maxLength) {
            this.el.nativeElement.querySelector('.more').style.display = 'none';
            this.el.nativeElement.querySelector('.less').style.display = 'none';
        } else {
            this.el.nativeElement.querySelector('.more').style.display = 'inherit';
            this.el.nativeElement.querySelector('.less').style.display = 'none';
        }

    }

    private determineView(): void {

        const elementChange = document.getElementById(this.elementChange.id);

        if (elementChange) {
            if (this.text.length <= this.maxLength) {
                this.currentText = this.text;
                elementChange.innerHTML = this.currentText;
                this.isCollapsed = false;
                this.hideToggle = true;
                return;
            }

            this.hideToggle = false;

            if (this.isCollapsed === true) {
                this.currentText = this.text.substring(0, this.maxLength) + '...';
                elementChange.innerHTML = this.currentText;
            } else if (this.isCollapsed === false) {
                this.currentText = this.text;
                elementChange.innerHTML = this.currentText;
            }
        }
    }
}
