import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { TicketService } from '../../../../../shared/services/ticket.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { select, Store } from '@ngrx/store';
import { AppState } from 'src/app/redux/app.state';
import { PersonModel } from 'src/app/models/person.model';
import { interval, Subscription } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';
import { Ticket } from '../../../../../models/ticket.model';
@Component({
    selector: 'app-ticket-conversation',
    templateUrl: './ticket-conversation.component.html',
    styleUrls: ['./ticket-conversation.component.scss']
})
export class TicketConversationComponent implements OnInit, OnDestroy {

    @Input() public ticket: Ticket;
    @Input() public conversationType: string;
    @Input() public ticketIsNotLocked: boolean;
    @Output() reloadAttachments: EventEmitter<any> = new EventEmitter();

    fetchingConversation: boolean;

    conversationAgentMerchant: any[] = [];

    editorData = '';

    config = {
        toolbar: ['bold', 'italic', 'link'],
    };

    editor = ClassicEditor;

    person: PersonModel;

    attachments: File[] = [];

    selectedFiles: File[] = [];

    scrollTimeout: any;

    sendingMessage = false;

    intervalMessage: Subscription;

    constructor(
        private ticketService: TicketService,
        private store: Store<AppState>,

    ) {
        this.store.pipe(select('person'))
            .subscribe(person => this.person = person);
    }

    ngOnInit() {
        this.fetchingConversation = true;
        this.intervalMessage = interval(10000).pipe(
            startWith(0),
            switchMap(() => this.ticketService.getTicketConversation(this.ticket.ticket_id, this.conversationType))
        ).subscribe(
            data => {
                this.fetchingConversation = false;
                if (data.ticket_messages && data.ticket_messages.length !== this.conversationAgentMerchant.length) {
                    this.conversationAgentMerchant = data.ticket_messages || [];
                    this.scrollToCurrentMsg();
                }
            }
        );
    }

    ngOnDestroy() {
        this.intervalMessage.unsubscribe();
    }

    openSelectFiles(selectFileInput: HTMLInputElement) {
        selectFileInput.click();
    }

    setSelectedFiles(event: Event) {
        const target = event.target as HTMLInputElement;
        if (target.files.length) {
            const files = Array.from(target.files);
            files.forEach(file => {
                this.attachments.push(file);
            });
        }
    }

    get conversationMessages() {
        return this.conversationAgentMerchant.reduce((messages, message) => {
            if (!messages.find(m => m.message_id === message.message_id)) {
                messages.push(message);
            }
            return messages;
        }, []);
    }

    removeAttachments(fileIdx: number) {
        this.attachments.splice(fileIdx, 1);
    }

    async sendMessage() {
        if (this.editorData) {
            this.sendingMessage = true;
            this.ticketService.sendMessage({
                ticket_id: this.ticket.ticket_id,
                conversation_type: this.conversationType,
                message: this.editorData,
                sender_type: this.person.userInfo.type,
                attachments: this.attachments
            }).toPromise().then(response => {
                if (response.ticket_message) {
                    this.conversationAgentMerchant.push(response.ticket_message);
                    if (this.attachments.length) {
                        this.reloadAttachments.emit(null);
                    }
                    this.editorData = '';
                    this.attachments = [];
                    this.selectedFiles = [];
                    this.scrollToCurrentMsg();
                }
            }).finally(() => {
                this.sendingMessage = false;
            });
        }
    }

    scrollToCurrentMsg() {
        clearTimeout(this.scrollTimeout);
        this.scrollTimeout = setTimeout(() => {
            const messageBox = document.getElementById('messages_wrapper');
            if (messageBox) {
                messageBox.scrollTop = messageBox.scrollHeight;
            }
        }, 100);
    }

    get sendButtonIsDisabled() {
        return this.sendingMessage || !this.editorData;
    }

    get sendButtonTooltipMessage() {
        return !this.editorData ? 'Please enter your message to send' : '';
    }

}
