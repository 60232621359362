import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { PermissionsService } from 'src/app/shared/services/permissions.service';

@Component({
    selector: 'app-content',
    templateUrl: './content.component.html',
    styleUrls: ['./content.component.scss']
})
export class ContentComponent implements OnInit, OnChanges {

    @Input() public notes: any[];
    @Input() public projects: any[];
    @Input() public isLoading: boolean;
    @Input() public loggedUser: number;

    assetsPath = '../../../../../assets/icons/note-icons/';

    constructor(
        public ps: PermissionsService,
    ) { }

    ngOnInit() {

    }

    ngOnChanges() {
        this._addProjectsToNotes();
    }

    trackByFn(index: number, el: any) {
        return el.id;
    }

    private _addProjectsToNotes() {
        this.notes.forEach(note => {
            if (this.projects) {
                this.projects.forEach(project => {
                    if (note.ref_id === project.id) {
                        note.project_name = project.name;
                        note.project_link = project.link;
                    }
                });
            }
        });
    }
}
