import { startCase, camelCase } from 'lodash';
import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
    name: 'str_title'
})

export class StrTitlePipePipe implements PipeTransform {
    transform(value: any): string {
        return startCase(camelCase(value));
    }
}
