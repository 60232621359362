import { Action } from '@ngrx/store';
import {ArmstrongAdmin} from '../../models/ticket.model';

export const UPDATE_ACCESS_TOKEN = 'UPDATE_ACCESS_TOKEN';
export const ARMSTRONG_ADMIN_AUTHENTICATED = 'armstrong_admin_authenticated';

export class ArmstrongAdminAuthenticated implements Action {
    readonly type = ARMSTRONG_ADMIN_AUTHENTICATED;
    constructor(public payload: ArmstrongAdmin) {}
}

export type TicketActions = ArmstrongAdminAuthenticated;
