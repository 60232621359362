import { Action } from '@ngrx/store';

// tslint:disable-next-line: no-namespace
export namespace ADMINS_ACTION {
    export const SUCCESS_LOAD_ADMINS = 'SUCCESS_LOAD_ADMINS';
}

export class SuccessLoadAdmins implements Action {

    readonly type = ADMINS_ACTION.SUCCESS_LOAD_ADMINS;

    constructor(public payload: any) { }
}

export type AdminsActions = SuccessLoadAdmins;
