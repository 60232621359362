import { Theme } from "./symbols";

export const darkTheme: Theme = {
    name: "dark",
    properties: {
        "--background": "#1c1d22",
        "--background-checkbox": "#1c1d22",
        "--color": "#d4d3d3",
        "--filter": "invert(1)",
        "--main-background": "black",
        "--tab-background": "#d4d3d3",
        "--placeholder": "#585555",
        "--box-shadow": "none",
        "--attachments-background": "#1c1d22",
        "--disable-button": "#e0e0e0",
        "--last-contact-green": "invert(1) hue-rotate(217deg)",
        "--last-contact-orange": "invert(1) hue-rotate(198deg)",
        "--last-contact-red": "invert(1) hue-rotate(154deg)",
        "--ck-editor-on": "#1c1d22",
        "--ck-editor-hover": "#1c1d22",
        "--filter-button-background": "black",
        "--filters-background": "#1c1d22",
        "--boards-background": "#1c1d22",
        "--armstrong-primary": "#0064FF",
        "--armstrong-new": "#FC6565",
        "--armstrong-bg-new": "#FDE2E2",
        "--armstrong-in-progress": "#5966E0",
        "--armstrong-bg-in-progress": "#E5E8FF",
        "--armstrong-waiting": "#F28927",
        "--armstrong-bg-waiting": "#FFE8D4",
        "--armstrong-under-investig": "#EAB000",
        "--armstrong-bg-under-investig": "#FFF4D4",
        "--armstrong-on-hold": "#29ACF5",
        "--armstrong-bg-on-hold": "#D4EFFF",
        "--armstrong-resolved": "#00B900",
        "--armstrong-bg-resolved": "#DAFFD4",
        "--armstrong-closed": "#7E909A",
        "--armstrong-bg-closed": "#E5EAED",
        "--armstrong-bubble-1": "#EEF4FB",
        "--armstrong-bubble-2": "#0064FF30"
    }
};
