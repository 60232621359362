import { Component, OnDestroy, OnInit} from '@angular/core';
import { Location } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Params} from '@angular/router';
import { Subscription} from 'rxjs';
import { tap } from 'rxjs/internal/operators/tap';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/redux/app.state';
import { MatDialog} from '@angular/material';
import { CompanyService } from '../../../shared/services/company.service';
import { TicketService } from '../../../shared/services/ticket.service';
import { ThemeService } from '../../../theme/theme.service';
import { ErrorService } from '../../../shared/services/error.service';
import { PermissionsService } from 'src/app/shared/services/permissions.service';
import { BootstrappedAdmin } from '../../../models/admins.model';
import { BootstrappingService } from 'src/app/shared/services/bootstrapping.service';
import { PersonModel } from '../../../models/person.model';
import { DialogConfirmUnlockTicketComponent } from './ticket-merchant/ticket-conversation/dialog-confirm-unlock-ticket/dialog-confirm-unlock-ticket.component';
import { Ticket, TicketCategory } from 'src/app/models/ticket.model';
import { ConfirmResolveTicketDialogComponent } from './confirm-resolve-ticket-dialog/confirm-resolve-ticket-dialog.component';
import { NzMessageService } from 'ng-zorro-antd';

@Component({
    selector: 'app-ticket-page',
    templateUrl: './ticket-page.component.html',
    styleUrls: ['./ticket-page.component.scss']
})
export class TicketPageComponent implements OnInit, OnDestroy {
    fetchingTicket: boolean;
    routerSubscriber: Subscription;

    ticket: Ticket;
    ticketAttachments: any;
    id: any;

    active: boolean;
    ticketStatuses: string[] = [];
    person: PersonModel;
    assigningTicket = false;
    ticketStatus: string;
    updatingTicket = false;
    assignees: BootstrappedAdmin[] = [];
    ticketCategories: TicketCategory[] = [];
    bootstrappingData = false;
    categories: TicketCategory[];

    constructor(
        public message: NzMessageService,
        public dialog: MatDialog,
        public errorService: ErrorService,
        public location: Location,
        private companyService: CompanyService,
        private ticketService: TicketService,
        private route: ActivatedRoute,
        private titleService: Title,
        private themeService: ThemeService,
        private ps: PermissionsService,
        private store: Store<AppState>,
        private bootstrappingService: BootstrappingService,
    ) {
        this.themeService.getThemeFromLocalStorage();
        this.store.pipe(select('person'))
            .subscribe((store) => {
                this.person = store;
            });
    }

    ngOnInit() {
        this.getBootstrappedData();
        this.routerSubscriber = this.route.params.pipe(
            tap(_ => this.getBootstrappedData())
        ).subscribe((params: Params) => {
            this.fetchingTicket = true;
            this.id = params.id;
            const filters: object = {
                include: 'count_admin_notes'
            };
            this.ticketService.getTicketById(this.id, filters).pipe(
                tap(ticket => this.ticket = ticket),
                tap(ticket => this.ticketStatus = ticket.status),
                tap(ticket => {
                    this.ticketService.getTicketCategories({
                        service_id: ticket.service_id
                    }).pipe(
                        tap(categories => this.categories = categories)
                    ).subscribe(() => this.fetchingTicket = false);
                }
            )).subscribe();
            this.getTicketAttachments();
        });
    }

    getTicketAttachments() {
        this.ticketService.getTicketAttachments(this.id)
            .pipe(
                tap(data => {
                    this.ticketAttachments = data.ticket_attachments;
                })
            )
            .subscribe();
    }

    activeBurger(event) {
        this.active = event;
    }

    assignTicketToMe() {
        this.assigningTicket = true;
        this.ticketService.assignTicket({ ticket_id: this.id as number, assignee_id: this.person.userInfo.admin_id }).toPromise().then(
            response => {
                this.ticket = response.ticket;
                this.message.success(`This ticket has been assigned to you`);
            }
        ).finally(() => {
            this.assigningTicket = false;
        });
    }

    changeTicketStatus() {
        this.updatingTicket = true;
        if (this.ticketStatus === 'resolved') {
            this.dialog.open(ConfirmResolveTicketDialogComponent, { width: '450px' }).afterClosed().subscribe((data?: {
                status: 'resolved',
                decision?: 'approved' | 'rejected'
            }) => {
                if (data && data.status === 'resolved') {
                    this.onChangeTicketStatus(data);
                } else {
                    this.updatingTicket = false;
                    this.ticketStatus = this.ticket.status;
                }
            });
        } else {
            this.onChangeTicketStatus();
        }

    }

    onChangeTicketStatus(payload = {}) {
        this.updatingTicket = true;
        let successMessage = `Ticket status has been updated to ${this.getStatusName(this.ticketStatus)}`;
        const assignedToYouMessage = ' and the ticket has been assigned to you. This is to ensure that the waiting' +
            ' time for the ticket will be calculated correctly';

        this.ticketService.updateTicket({
            ticket_id: this.id as number,
            data: {
                ...payload,
                status: this.ticketStatus
            }
        }).toPromise().then(
            response => {
                if (response.ticket.assignee_id
                    && response.ticket.assignee_id !== this.ticket.assignee_id
                    && response.ticket.assignee_id === this.person.userInfo.admin_id) {

                    successMessage += assignedToYouMessage;
                }

                this.ticket = response.ticket;
                this.message.success(successMessage);
            }
        ).finally(() => {
            this.updatingTicket = false;
        });
    }

    lockTicket() {
        this.updatingTicket = true;
        this.ticketService.updateTicket({
            ticket_id: this.id as number,
            data: {
                lock_admin_id: this.person.userInfo.admin_id
            }
        }).toPromise().then(
            response => {
                this.ticket = response.ticket;
                this.message.success(`Ticket has been locked`);
            }
        ).finally(() => {
            this.updatingTicket = false;
        });
    }

    unlockTicket() {
        this.updatingTicket = true;
        this.ticketService.updateTicket({ ticket_id: this.id as number, data: { lock_admin_id: '' } }).toPromise().then(
            response => {
                this.ticket = response.ticket;
                this.message.success(`Ticket status has been unlocked`);
            }
        ).finally(() => {
            this.updatingTicket = false;
        });
    }

    getBootstrappedData() {
        this.bootstrappingData = true;
        const include = ['admins', 'ticket_categories'];
        this.bootstrappingService.bootstrappingData(include).subscribe(response => {
            this.ticketStatuses = response.data.ticket_statuses;
            this.assignees = response.data.admins || [];
            this.ticketCategories = response.data.ticket_categories || [];
            this.bootstrappingData = false;
        });
    }

    getStatusName(status: string) {
        return status.replace(/_/g, ' ');
    }

    private _showLoaders() {
        this.errorService.isLoadingCompany = true;
        this.errorService.isLoadingTeam = true;
        this.errorService.isLoadingContacts = true;
        this.errorService.isLoadingNotes = true;
        this.errorService.isLoadingGoals = true;
        this.errorService.isLoadingProjects = true;
        this.errorService.isLoadingCharts = true;
        this.errorService.isLoadingTasks = true;
        this.errorService.isLoadingAttachments = true;
    }

    ngOnDestroy(): void {
        this.routerSubscriber.unsubscribe();
    }

    get ticketIsNotLocked() {
        return Number(this.ticket.lock_admin_id) === Number(this.person.userInfo.admin_id) || !this.ticket.lock_admin_id;
    }

    openConfirmUnlockDialog() {
        const dialogRef = this.dialog.open(DialogConfirmUnlockTicketComponent, {
            width: '450px',
            data: { adminName: this.ticket.lock_admin_name || 'another Admin' }
        });

        dialogRef.afterClosed().subscribe(data => {
            if (data === 'ok') {
                this.unlockTicket();
            }
        });
    }

    onAssigneeChanged(assignee_id: number) {
        this.assigningTicket = true;
        this.ticketService.assignTicket({ ticket_id: this.id as number, assignee_id }).toPromise().then(
            response => {
                const assignee = this.assignees.find(a => a.admin_id == assignee_id);
                this.ticket = response.ticket;
                this.message.success(`This ticket has been assigned to ${assignee.fullname || assignee.email}`);
            }
        ).finally(() => {
            this.assigningTicket = false;
        });
    }

    onCategoryChanged(category_id: number) {
        this.updatingTicket = true;
        this.ticketService.updateTicket({
            ticket_id: this.id as number, data: {
                category_id,
            }
        }).toPromise().then(
            response => {
                this.ticket = response.ticket;
                this.message.success(`This ticket category has been changed`);
            }
        ).finally(() => {
            this.updatingTicket = false;
        });
    }

    get ticketStatusOptions() {
        return this.ticketStatuses;
    }
}
