export class GetCompanyByIdResponse {

    readonly res: any;

    constructor(res: any, ) {
        this.res = res;
    }

    getMerchantIds(typeOfMid: string) {
        if (this.res.company.merchant_ids[typeOfMid]) {
            return this.res.company.merchant_ids[typeOfMid].map((res) => {
                return {
                    id: res.id,
                    url: res.url,
                };
            });
        }
    }

    getCompanyData() {
        return {
            name: this.res.company.name,
            subtype: this.res.company.subtype,
            stage: this.res.company.stage,
            source: this.res.company.source,
            reputation: this.res.company.reputation,
            english_name: this.res.company.english_name,
            local_name: this.res.company.local_name,
            type: this.res.company.type,
            goals: this.res.company.goals,
            bounty: this.res.company.bounty,
            description: this.res.company.description,
            needs: this.res.company.needs,
            icon: this.res.company.icon,
            date_created: this.res.company.dt_created,
            admin_id_created: this.res.company.admin_id_created,
            last_contact_admin_id: this.res.company.last_contact_admin_id,
            last_contact_date: this.res.company.last_contact_date,
            last_contact_note_id: this.res.company.last_contact_note_id,
            contractual_info: this.res.company.contractual_info,
            strategy: this.res.company.strategy
        };
    }
}
