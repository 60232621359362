import { Component, OnInit, OnChanges, Input } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/redux/app.state';
import { FormBuilder, Validators, FormGroup, FormArray } from '@angular/forms';
import { RoleItem } from 'src/app/models/reference.model';
import { AdminItem, AdminsModel } from 'src/app/models/admins.model';
import { MatDialog } from '@angular/material';
import { AssignPersonComponent } from '../../company-page-content/team-widget/assign-person/assign-person.component';
import { CompanyService } from 'src/app/shared/services/company.service';
import { PeopleService } from 'src/app/shared/services/people.service';
import { PersonModel } from 'src/app/models/person.model';
import { DeletionPersonComponent } from '../../company-page-content/team-widget/deletion-person/deletion-person.component';

@Component({
  selector: 'app-team-person-widget',
  templateUrl: './team-person-widget.component.html',
  styleUrls: ['./team-person-widget.component.scss']
})
export class TeamPersonWidgetComponent implements OnInit, OnChanges {

  @Input() isLoading: boolean;

  assignedTeam: {
    role_id: number,
    admin_id: number,
    role: string,
    full_name: string,
    root_avatar: string,
    avatar: string
  }[] = [];

  form: FormGroup;

  isShow: boolean;

  rootAvatar: string;
  admins: AdminsModel;
  roles: RoleItem[];

  filteredTeam: AdminItem[];
  filteredRoles: RoleItem[];

  arr: any[] = [];

  id: number;

  person: PersonModel;

  isFoundUser = false;
  isFoundRole = false;

  constructor(
    private store: Store<AppState>,
    private _fb: FormBuilder,
    private peopleService: PeopleService,
    public dialog: MatDialog,
  ) {
  }

  ngOnChanges(): void {

  }

  ngOnInit() {

    this.form = this._fb.group({
      teamRows: this._fb.array([this.initTeamRows('')], [Validators.minLength(3)]),
    });
    this._fb.group({
      roleRows: this._fb.array([this.initRoleRows('')], [Validators.minLength(3)]),
    });

    this.store.pipe(select('person'))
      .subscribe((store: any) => {

        this.assignedTeam = [];

        this.filterRoles(null, store.roles);
        this.filterTeam(null, store.admins);

        this.person = store.person;

        if (store.admins.admins) {

          this.rootAvatar = store.admins.extra_data.root_avatar_url;
          this.admins = store.admins;
          this.roles = store.roles;
          this.id = store.person.id;

          store.admins.admins.forEach(admin => {
            store.assigned_admins.forEach(ids => {
              store.roles.forEach(role => {
                if (ids.admin_id === admin.id) {
                  if (ids.role_id === role.id) {
                    this.assignedTeam.push({
                      full_name: admin.full_name,
                      role: role.name,
                      admin_id: admin.id,
                      role_id: role.id,
                      avatar: admin.avatar,
                      root_avatar: this.rootAvatar
                    });
                  }
                }
              });
            });
          });
        }
        this.setControls();
      });
  }

  get formTeamArr() {
    return this.form.get('teamRows') as FormArray;
  }

  get formRoleArr() {
    return this.form.get('roleRows') as FormArray;
  }

  initTeamRows(admin: any) {
    return this._fb.group({
      full_name: [admin]
    });
  }

  initRoleRows(role: any) {
    return this._fb.group({
      role: [role]
    });
  }

  setRowsTeam(data): FormArray {
    const formArray = new FormArray([]);

    data.forEach(item => {
      formArray.push(this._fb.group({
        full_name: item.full_name,
        avatar: item.avatar,
      }));
    });

    return formArray;
  }

  setRowsRole(data): FormArray {
    const formArray = new FormArray([]);

    data.forEach(item => {
      formArray.push(
        this._fb.group({
          role: item.role
        })
      );
    });

    return formArray;
  }

  setControls() {
    this.form.setControl('teamRows', this.setRowsTeam(this.assignedTeam));
    this.form.setControl('roleRows', this.setRowsRole(this.assignedTeam));
  }

  filterTeam(query?: string, data?: AdminsModel) {
    this.filteredTeam = query
      ? data.admins.filter(team => team.full_name.toLowerCase().includes(query.toLowerCase()))
      : data.admins;
  }

  filterRoles(query?: string, data?: RoleItem[]) {
    this.filteredRoles = query
      ? data.filter(role => role.name.toLowerCase().includes(query.toLowerCase()))
      : data;
  }

  hideEditableTeam(event?: any) {

    if (event && event.target.innerText === 'Cancel') {

      this.isShow = false;

      this.setControls();

      while (
        this.formTeamArr.length !== 0 &&
        this.formRoleArr.length !== 0 &&
        this.assignedTeam.length === 0
      ) {
        this.formTeamArr.removeAt(0);
        this.formRoleArr.removeAt(0);
      }
    }
  }

  edit() {
    this.form.value.teamRows.forEach(team => {
      this.admins.admins.forEach(admin => {
        if (team.full_name === admin.full_name) {
          team.admin_id = admin.id;
          team.avatar = admin.avatar;
        }
      });
    });

    this.form.value.roleRows.forEach(role => {
      this.roles.forEach((item: any) => {
        if (role.role === item.name) {
          role.role_id = item.id;
        }
      });
    });

    this.arr = this.form.value.teamRows.map((teamRow, index) => ({
      ...teamRow,
      ...this.form.value.roleRows[index],
    }));

    this.isFoundUser = this._checkContains(this.admins.admins, this.arr, 'full_name');
    this.isFoundRole = this._checkContains(this.roles, this.arr, 'role', 'name');

    if (this.isFoundUser && this.isFoundRole) {
      this._editTeam(this.arr);
      this.isShow = false;
    }
  }

  addNewRow() {
    this.dialog.open(AssignPersonComponent, {
      width: '496px',
      height: 'auto',
      autoFocus: false,
      data: {
        admins: this.admins.admins,
        avatar: this.rootAvatar,
        roles: this.roles,
      },
      panelClass: 'assign-modal'
    }).afterClosed()
      .subscribe((data: any) => {
        if (data) {
          this.isShow = true;
          this.formTeamArr.push(this.initTeamRows(data.admin));
          this.formRoleArr.push(this.initRoleRows(data.role));
        }
      });
  }

  removeControl(index, itemTeam) {
    this.dialog.open(DeletionPersonComponent, {
      width: '447px',
      height: 'auto',
      autoFocus: false,
      data: {
        index,
        itemTeam,
        formTeamArr: this.formTeamArr,
        formRoleArr: this.formRoleArr
      },
      panelClass: 'remove-assign-modal'
    });
  }

  private _editTeam(data) {
    this.isLoading = true;
    this.peopleService.editAssignedAdmins(this.id, data)
      .subscribe(
        () => {
          this.isLoading = false;
          this.assignedTeam = data;
        },
        () => {
          this.isLoading = false;
        }
      );
  }

  private _checkContains(arrData: any[], arr: any[], typeOfInput: string, roleName?: string) {
    return arr.every(item => {
      return arrData.some(item2 => item2[roleName ? roleName : typeOfInput] === item[typeOfInput]);
    });
  }
}
