import { Component, OnInit, Input, OnChanges, Output, EventEmitter, ViewChild, ViewEncapsulation } from '@angular/core';
import { ProjectsModel } from 'src/app/models/projects.model';

@Component({
    selector: 'app-projects-list',
    templateUrl: './projects-list.component.html',
    styleUrls: ['./projects-list.component.scss'],
})
export class ProjectsListComponent {

    @Input() projectsDropDown: any;
    @Output() data: EventEmitter<number[]> = new EventEmitter<number[]>();

    @ViewChild('mySelect', { static: false }) mySelect;

    value: number[];

    selected(value) {
        this.value = value.value;
    }

    sendData() {
        this.data.emit(this.value);
        this.mySelect.close();
    }

    onClose() {
        this.mySelect.close();
    }
}
