import { PipeTransform, Pipe } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'momentPipe'
})

export class MomentPipe implements PipeTransform {

    transform(date, format) {
        return moment.unix(date).format(format);
    }
}
