import { IUserInfo, PersonModel } from './../../models/person.model';
import { Action } from '@ngrx/store';
import { AdminsModel } from 'src/app/models/admins.model';
import { RoleItem } from 'src/app/models/reference.model';

// tslint:disable-next-line: no-namespace
export namespace PERSON_ACTION {
    export const SUCCESS_LOAD_PERSON = 'SUCCESS_LOAD_PERSON';
    export const SUCCESS_LOAD_ADMINS_DATA = 'SUCCESS_LOAD_ADMINS_DATA';
    export const SUCCESS_LOAD_ROLES_DATA = 'SUCCESS_LOAD_ROLES_DATA';
    export const CLEAR_STATE = 'CLEAR_STATE';
    export const SUCCESS_LOAD_USER_INFO = 'SUCCESS_LOAD_USER_INFO'
}

export class SuccessLoadPerson implements Action {

    readonly type = PERSON_ACTION.SUCCESS_LOAD_PERSON;

    constructor(public payload: PersonModel) { }
}

export class SuccessLoadUserInfo implements Action {

    readonly type = PERSON_ACTION.SUCCESS_LOAD_USER_INFO;

    constructor(public payload: IUserInfo) { }
}

export class SuccessLoadAdminsData implements Action {

    readonly type = PERSON_ACTION.SUCCESS_LOAD_ADMINS_DATA;

    constructor(public payload: AdminsModel) { }
}

export class SuccessLoadRolesData implements Action {

    readonly type = PERSON_ACTION.SUCCESS_LOAD_ROLES_DATA;

    constructor(public payload: RoleItem[]) { }
}

export type PersonActions =
    SuccessLoadPerson |
    SuccessLoadAdminsData |
    SuccessLoadRolesData | SuccessLoadUserInfo;
