import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-deletion-modal',
  templateUrl: './deletion-modal.component.html',
  styleUrls: ['./deletion-modal.component.scss']
})
export class DeletionModalComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DeletionModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
  }

  onCancelClick() {
    this.dialogRef.close({ remove: false });
  }

  onDeleteMId() {
    const index = this.data.typeOfMid.value.indexOf(this.data.mid.id);
    this.data.typeOfMid.value.splice(index, 1);
    this.dialogRef.close({ remove: true });
  }
}
