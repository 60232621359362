import { Component, OnInit, Input } from '@angular/core';
import { AuthTicketService } from '../../../../shared/services/auth-ticket.service';

@Component({
    selector: 'app-ticket-attachments',
    templateUrl: './ticket-attachments.component.html',
    styleUrls: ['./ticket-attachments.component.scss']
})
export class TicketAttachmentsComponent implements OnInit {

    accessToken: string;

    @Input() public ticketAttachments: any[];

    constructor(
        private auth: AuthTicketService
    ) {
        this.accessToken = auth.jwtAccessToken;
    }

    ngOnInit() {

    }

    getFileExt(fileName: string) {
        return fileName.split('.').pop()
    }

    attachmentUrl(attachment) {
        return `${attachment.internal_url}?token=${this.accessToken}`;
    }

}
