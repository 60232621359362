import { MyDealsPageLayoutComponent } from './layouts/my-deals-page-layout/my-deals-page-layout.component';
import { AllCompaniesPageLayoutComponent } from './layouts/all-companies-page-layout/all-companies-page-layout.component';
import { PersonPageLayoutComponent } from './layouts/person-page-layout/person-page-layout.component';
import { CreateCompanyComponent } from './layouts/create-company-page-layout/create-company.component';
import { SearchPageLayoutComponent } from './layouts/search-page-layout/search-page-layout.component';
import { NgModule } from '@angular/core';
import { Routes, CanActivate, RouterModule } from '@angular/router';
import { CompanyPageLayoutComponent } from './layouts/company-page-layout/company-page-layout.component';
import { PeopleComponent } from './layouts/people/people.component';
import { LatestCorrespondenceComponent } from './layouts/latest-correspondence/latest-correspondence.component';
import { NoteViewComponent } from './layouts/latest-correspondence/note-view/note-view.component';
import { CreatePersonPageLayoutComponent } from './layouts/create-person-page-layout/create-person-page-layout.component';
import { TicketsComponent } from './layouts/tickets/tickets-table/tickets-table.component';
import { TicketPageComponent } from './layouts/tickets/ticket-page/ticket-page.component';
import { AuthTicketGuard } from './auth-ticket.guard';

const routes: Routes = [
    { path: '', redirectTo: 'all-companies', pathMatch: 'full' },
    // { path: '', redirectTo: 'all-companies', pathMatch: 'full' },
    { path: 'company/:id', redirectTo: `company/:id/${''}`, pathMatch: 'full' },
    { path: 'company/:id/:name', component: CompanyPageLayoutComponent },
    { path: 'company-search/:query', component: SearchPageLayoutComponent, data: { title: 'Armstrong' } },
    { path: 'company-create', component: CreateCompanyComponent, data: { title: 'Armstrong' } },
    { path: 'all-companies', component: AllCompaniesPageLayoutComponent, data: { title: 'Armstrong' } },
    { path: 'people', component: PeopleComponent, data: { title: 'Armstrong' } },
    { path: 'person-create', component: CreatePersonPageLayoutComponent, data: { title: 'Armstrong' } },
    { path: 'latest-correspondence', component: LatestCorrespondenceComponent, data: { title: 'Armstrong' } },
    { path: 'my-deals', component: MyDealsPageLayoutComponent, data: { title: 'Armstrong' } },
    { path: 'person/:id', component: PersonPageLayoutComponent, data: { title: 'Armstrong' } },
    { path: 'note/:id', component: NoteViewComponent, data: { title: 'Armstrong' } },
    { path: 'tickets', component: TicketsComponent, data: { title: 'Tickets' }, canActivate: [AuthTicketGuard] },
    { path: 'ticket/:id', component: TicketPageComponent, canActivate: [AuthTicketGuard] }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        useHash: true,
        anchorScrolling: 'enabled',
        scrollOffset: [0, 60]
    })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
