import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
    selector: 'app-assign-person',
    templateUrl: './assign-person.component.html',
    styleUrls: ['./assign-person.component.scss']
})
export class AssignPersonComponent implements OnInit {

    public form: FormGroup;
    public filteredTeam: any[];

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<AssignPersonComponent>,
    ) { }

    ngOnInit() {
        this.form = new FormGroup({
            admin: new FormControl(null),
            role: new FormControl(null),
        });
        this.filterTeam();
    }

    filterTeam(query?: string) {
        this.filteredTeam = query ?
            this.data.admins.filter(team => team.full_name
                .toLowerCase()
                .includes(
                    query.toLowerCase())) :
            this.data.admins;
    }

    onClose() {
        this.dialogRef.close();
    }

    onSubmit(value: any) {
        this.dialogRef.close(value);
    }

}
