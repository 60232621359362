import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material';
import { select, Store } from '@ngrx/store';
import { PersonModel } from 'src/app/models/person.model';
import { TicketNote } from 'src/app/models/ticket.model';
import { AppState } from 'src/app/redux/app.state';
import { TicketService } from 'src/app/shared/services/ticket.service';
import { ReplyNoteDialogComponent } from '../reply-note-dialog/reply-note-dialog.component';

@Component({
    selector: 'app-ticket-note',
    templateUrl: './ticket-note.component.html',
    styleUrls: ['./ticket-note.component.scss']
})
export class TicketNoteComponent implements OnInit {

    @Input() ticketNote: TicketNote
    @Output() reloadNotes: EventEmitter<any> = new EventEmitter()

    repliesIsShown = false
    person: PersonModel;
    constructor(public dialog: MatDialog, public ticketService: TicketService, private store: Store<AppState>) {
        this.store.pipe(select('person'))
            .subscribe((store) => {
                this.person = store;
            });
    }

    ngOnInit() {
    }

    get commentBtnLabel() {
        return `Comment${this.ticketNote.replies.length > 1 ? 's' : ''}`
    }

    emitReloadNotes() {
        this.reloadNotes.emit()
    }

    openReplyDialog(id: number, ticket_id: number) {
        const dialogRef = this.dialog.open(ReplyNoteDialogComponent, {
            width: '650px'
        })

        dialogRef.afterClosed().subscribe(data => {
            if (data) {
                this.ticketService.addTicketNote({
                    admin_id: this.person.userInfo.admin_id,
                    message: data,
                    parent_id: id,
                    ticket_id,
                }).subscribe(res => {
                    this.emitReloadNotes()
                })
            }
        })
    }
}
