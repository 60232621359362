import { Component, OnInit, Input } from '@angular/core';
import { AuthTicketService } from '../../../../shared/services/auth-ticket.service';

@Component({
  selector: 'app-message-attachment',
  templateUrl: './message-attachment.component.html',
  styleUrls: ['./message-attachment.component.scss']
})
export class MessageAttachmentComponent implements OnInit {

  @Input() attachment: any;
  accessToken: string;

  constructor(
    private auth: AuthTicketService
  ) {
    this.accessToken = auth.jwtAccessToken;
  }

  attachmentUrl(attachment) {
    return `${attachment.internal_url}?token=${this.accessToken}`;
  }

  ngOnInit() {
  }


}
